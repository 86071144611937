import React, { useState } from 'react';
import {
  Stack,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Paper,
  useMediaQuery,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar, OptionsObject } from 'notistack';
import { getErrorMessage } from '../../utilities/errorHelper';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { LicenseStatus } from './useLoadMailboxAndLicenses';
import Mailbox from '../../model/mailbox';
import { useLicenseManagerAPI } from './useLicenseManagerAPI';

const LICENSE_MAX = 10;

export type LicenseManagerProps = {
  licenseStatus: LicenseStatus;
  mailbox: Mailbox;
};

export default function LicenseManager(props: LicenseManagerProps) {
  const { licenseStatus, mailbox } = props;

  const [newEmail, setNewEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { licensedUsers, onDeleteUserFromLicense, onAddUserToLicense, onUnlinkAccount } =
    useLicenseManagerAPI(mailbox);

  function showErrorToast(e: unknown) {
    const snackbarOptions: OptionsObject = {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: false,
    };

    const errorMessage = getErrorMessage(e);
    enqueueSnackbar(errorMessage, snackbarOptions);
  }

  const handleDeleteLicense = async (email: string) => {
    try {
      await onDeleteUserFromLicense(email);
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleAddLicense = async () => {
    if (licensedUsers.length >= LICENSE_MAX) {
      showErrorToast(t('maxLicensesReached'));
      return;
    }

    if (!isValidEmail(newEmail)) {
      showErrorToast(t('enterValidEmail'));
      return;
    }

    try {
      await onAddUserToLicense(newEmail);
      setNewEmail('');
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleUnlinkAccount = async () => {
    try {
      await onUnlinkAccount();
    } catch (error) {
      showErrorToast(error);
    }
  };

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const sortedLicensedUsers = [...licensedUsers].sort((a, b) => {
    if (a.isOwner !== b.isOwner) return a.isOwner ? -1 : 1;
    return a.email.localeCompare(b.email);
  });

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack spacing={2} alignItems={'center'}>
      <Typography
        variant='h5'
        align='center'
        color='text.primary'
        component='p'
        gutterBottom
        fontSize={aboveMedium ? '1.2rem' : '1rem'}
      >
        {t(licenseStatus.ownsActiveStripeLicense ? 'addMoreAccounts' : 'linkedAccount')}
      </Typography>
      {!licenseStatus.ownsActiveStripeLicense && (
        <Button
          variant={'outlined'}
          color='error'
          size='large'
          sx={{
            fontWeight: 700,
          }}
          onClick={handleUnlinkAccount}
        >
          {t('unlinkAccount')}
        </Button>
      )}
      {licenseStatus.ownsActiveStripeLicense && (
        <>
          <List style={{ maxWidth: '500px', width: '100%', paddingBottom: 0 }}>
            {sortedLicensedUsers.map((licensedUser) => (
              <ListItem
                key={licensedUser.email}
                component={Paper}
                variant='outlined'
                sx={{
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant='body1' fontWeight='bold'>
                      {licensedUser.email}
                    </Typography>
                  }
                />
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleDeleteLicense(licensedUser.email)}
                  disabled={licensedUser.isOwner}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          {licensedUsers.length >= LICENSE_MAX ? (
            <Stack direction='column' spacing={2} alignItems='center'>
              <Typography color='error'>{t('maxLicensesReached')}</Typography>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  window.open('https://trimbox.io/support');
                }}
              >
                {t('contactSupport')}
              </Button>
            </Stack>
          ) : (
            <Paper
              variant='outlined'
              sx={{ p: 2 }}
              style={{ maxWidth: '500px', width: '-webkit-fill-available', marginTop: 0 }}
            >
              <Stack direction='row' spacing={2} alignItems='center'>
                <TextField
                  label={t('addEmail')}
                  variant='standard'
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  fullWidth
                />
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AddIcon />}
                  onClick={handleAddLicense}
                  disabled={licensedUsers.length >= LICENSE_MAX}
                >
                  {t('add')}
                </Button>
              </Stack>
            </Paper>
          )}{' '}
        </>
      )}
    </Stack>
  );
}
