import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';

export enum PermissionsInfoLineBadgeIcon {
  READ,
  SEND_AND_FILTER,
  DELETE,
  PRIVATE,
}

export type PermissionsInfoLineProps = {
  icon: PermissionsInfoLineBadgeIcon;
  title: string;
};

function PermissionsInfoLine(props: PermissionsInfoLineProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'start',
        margin: '0.5rem 0rem',
        alignItems: 'center',
      }}
    >
      {props.icon === PermissionsInfoLineBadgeIcon.READ ? (
        <PolicyIcon
          fontSize={'medium'}
          color='black'
          sx={{ marginRight: !isExtraSmall ? '1rem' : '0.5rem' }}
        />
      ) : props.icon === PermissionsInfoLineBadgeIcon.SEND_AND_FILTER ? (
        <BlockIcon
          fontSize={'medium'}
          color='black'
          sx={{ marginRight: !isExtraSmall ? '1rem' : '0.5rem' }}
        />
      ) : props.icon === PermissionsInfoLineBadgeIcon.DELETE ? (
        <DeleteIcon
          fontSize={'medium'}
          color='black'
          sx={{ marginRight: !isExtraSmall ? '1rem' : '0.5rem' }}
        />
      ) : (
        <LockIcon
          fontSize={'medium'}
          color='black'
          sx={{ marginRight: !isExtraSmall ? '1rem' : '0.5rem' }}
        />
      )}
      <Typography
        variant='body1'
        fontSize={isExtraSmall ? '0.85rem' : aboveSmall ? '1.25rem' : '1rem'}
      >
        {props.title}
      </Typography>
    </Box>
  );
}

export default PermissionsInfoLine;
