import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import uiReducer from '../features/ui/uiSlice';
import userReducer from '../features/user/userSlice';
import emailListsReducer from '../features/emailLists/emailListsSlice';
import emailBundlesReducer from '../features/emailBundles/emailBundlesSlice';
import offeringReducer from '../features/offering/offeringSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    ui: uiReducer,
    user: userReducer,
    emailLists: emailListsReducer,
    emailBundles: emailBundlesReducer,
    offering: offeringReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
