import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectUnsubscribeSettingsDialog,
  closeUnsubscribeSettingsDialog,
} from '../../features/ui/uiSlice';
import { useSnackbar } from 'notistack';
import Mailbox, { getCurrentMailbox } from '../../model/mailbox';
import {
  performUnsubscribeAction,
  selectSessionListCount,
} from '../../features/emailLists/emailListsSlice';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { useTranslation } from 'react-i18next';
import { SettingsCheckboxProps } from './SettingsCheckbox';
import SettingsPopup from './SettingsPopup';
import { usePaywall } from '../../providers/PaywallProvider';

function UnsubscribeSettingsPopup() {
  const unsubscribeSettingsDialog = useAppSelector(selectUnsubscribeSettingsDialog);

  const sessionListCount = useAppSelector(selectSessionListCount);

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { shouldShowPaywallWhenUnsubscribing } = usePaywall();

  const [moveToTrash, setMoveToTrash] = useState(unsubscribeSettingsDialog?.moveToTrash);
  const [doNotAskAgain, setDoNotAskAgain] = useState(unsubscribeSettingsDialog?.doNotAskAgain);
  const [useAsNewDefault, setUseAsNewDefault] = useState(
    unsubscribeSettingsDialog?.useAsNewDefault
  );

  const settingsCheckboxes: SettingsCheckboxProps[] = [
    {
      isChecked: !!moveToTrash,
      onChange: setMoveToTrash,
      labelString: 'moveExistingToTrash',
    },
  ];

  if (doNotAskAgain !== undefined) {
    settingsCheckboxes.push({
      isChecked: !!doNotAskAgain,
      onChange: setDoNotAskAgain,
      labelString: 'doNotAskAgain',
    });
  }

  if (useAsNewDefault !== undefined) {
    settingsCheckboxes.push({
      isChecked: !!useAsNewDefault,
      onChange: setUseAsNewDefault,
      labelString: 'useAsNewDefault',
    });
  }

  const handleClose = () => {
    dispatch(closeUnsubscribeSettingsDialog(undefined));
  };

  const handleConfirm = async () => {
    try {
      const mailbox = await getCurrentMailbox();
      if (!mailbox) {
        throw new Error('Current mailbox not found! b');
      }

      const mailboxParams: Partial<Pick<Mailbox, 'unsubscribe_trash' | 'unsubscribe_do_not_ask'>> =
        {};

      if (doNotAskAgain !== undefined) {
        mailboxParams.unsubscribe_do_not_ask = doNotAskAgain;
      }

      if (useAsNewDefault === undefined || useAsNewDefault == true) {
        mailboxParams.unsubscribe_trash = moveToTrash;
      }

      await mailbox.update(mailboxParams);

      const showErrorToast = (errorMessage: string) => {
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          preventDuplicate: true,
          persist: false,
        });
      };

      if (!unsubscribeSettingsDialog) {
        return;
      }

      // this is a high volume event, so we'll log it only once to save on Mixpanel costs
      if (!sessionListCount) {
        AnalyticsService.trackListEvent(
          EventName.CLICK_UNSUBSCRIBE,
          unsubscribeSettingsDialog.listInfo.listSender
        );
      }

      dispatch(
        performUnsubscribeAction(
          unsubscribeSettingsDialog.listInfo,
          showErrorToast,
          {
            alsoDelete: !!moveToTrash,
            isFromSettings: true,
          },
          shouldShowPaywallWhenUnsubscribing
        )
      );

      handleClose();
    } catch (e) {
      enqueueSnackbar(t('somethingWentWrong'), {
        variant: 'error',
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        persist: false,
      });
    }
  };

  return (
    <SettingsPopup
      isOpen={!!unsubscribeSettingsDialog}
      title={t('unsubscribeFromThisList')}
      description={t('unsubscribeDescription', {
        listName: unsubscribeSettingsDialog?.listInfo.listName || t('thisMailingList'),
      })}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      confirmText={t('unsubscribe')}
      settingsCheckboxes={settingsCheckboxes}
    ></SettingsPopup>
  );
}

export default UnsubscribeSettingsPopup;
