import { FilterOperation } from '../../filterOperationQueue';
import { runMigrations } from './filterMigrations';

export const TRIMBOX_SENTINTEL = 'sentinel@trimbox.io';
export const V4_TAG = 'v4@trimbox.io';
export const V5_TAG = 'v5@trimbox.io';
export const V6_TAG = 'v6@trimbox.io';

const ALL_TAGS: string[] = [TRIMBOX_SENTINTEL, V4_TAG, V5_TAG, V6_TAG];
export const CURRENT_TAG = V6_TAG;

export const UNSUBSCRIBE_TEMPLATE =
  '"unsubscribe" -"cannot unsubscribe" -"without the option to unsubscribe"';

type MakeRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type ExistingFilter = MakeRequired<gapi.client.gmail.Filter, 'id' | 'criteria'>;

export type FilterCommand = {
  existingFilter?: ExistingFilter;
  newFilterQuery: string;
  operations: FilterOperation[];
};

// Gmail max is 1000, but leave extra room for " -in:chats"
const MAX_FILTER_LENGTH = 1000 - 10;

export function generateFilterCommands(
  allFilters: gapi.client.gmail.Filter[],
  operations: FilterOperation[]
): FilterCommand[] {
  const existingFilters = getTrimboxFilters(allFilters);
  const filterCommands: FilterCommand[] = [];

  for (const operation of operations) {
    let sender = operation.subscription.email_address;
    if (includesSender(existingFilters, sender)) continue;

    if (sender.startsWith('-')) {
      sender = `"${sender}"`;
    }

    // If we have an existing filter command that can include this sender, use it
    const filterCommand = findEligibleFilterCommand(filterCommands, sender);
    if (filterCommand) {
      filterCommand.newFilterQuery = modifyExistingFilterQuery(
        filterCommand.newFilterQuery,
        sender
      );
      filterCommand.operations.push(operation);
      continue;
    }

    // Otherwise, check our existing Gmail filters to see if we can fit this sender
    const filter = findEligibleFilter(existingFilters, filterCommands, sender);
    if (filter) {
      if (!filter.criteria) {
        throw new Error('Filter has empty criteria!');
      }
      if (!filter.criteria.query) {
        throw new Error('Filter has empty query!');
      }
      if (!filter.id) {
        throw new Error('Filter is missing an id!');
      }
      const query = modifyExistingFilterQuery(filter.criteria.query, sender);
      filterCommands.push({
        existingFilter: filter,
        newFilterQuery: query,
        operations: [operation],
      });
      continue;
    }

    // Otherwise create a new filter command
    else {
      const query = createNewFilterQuery(sender);

      filterCommands.push({
        newFilterQuery: query,
        operations: [operation],
      });
    }
  }

  return filterCommands;
}

function createNewFilterQuery(sender: string) {
  const query = `{from:${CURRENT_TAG} from:${sender}}`;
  return query;
}

function modifyExistingFilterQuery(existingQuery: string, sender: string) {
  const migratedQuery = runMigrations(existingQuery);
  const updatedQuery = appendSenderToQuery(migratedQuery, sender);
  return updatedQuery;
}

function appendSenderToQuery(query: string, sender: string) {
  if (query.endsWith('}')) {
    query = query.slice(0, -1);
  }
  return query + ` from:${sender}}`;
}

function findEligibleFilter(
  filters: ExistingFilter[],
  filterCommands: FilterCommand[],
  sender: string
) {
  return filters.find((filter) => {
    let query = filter.criteria?.query;

    if (!query) {
      return false;
    }

    const matchingFilterCommand = filterCommands.find((filterCommand) => {
      return filterCommand.existingFilter?.id === filter.id;
    });

    // If we are already planning to modify this filter in a pending filter command, then we cannot use it
    if (matchingFilterCommand) {
      return false;
    }

    query = runMigrations(query);

    return appendSenderToQuery(query, sender).length <= MAX_FILTER_LENGTH;
  });
}

function findEligibleFilterCommand(filterCommands: FilterCommand[], sender: string) {
  return filterCommands.find((filterCommand) => {
    let query = filterCommand.newFilterQuery;

    if (!query) {
      return false;
    }

    query = runMigrations(query);

    return appendSenderToQuery(query, sender).length <= MAX_FILTER_LENGTH;
  });
}

function includesSender(filters: ExistingFilter[], sender: string) {
  return filters.some((filter) => {
    if (!filter?.criteria.query) return false;

    return filter.criteria.query.includes(sender);
  });
}

function getTrimboxFilters(filters: gapi.client.gmail.Filter[]): ExistingFilter[] {
  const mappedFilters: (ExistingFilter | null)[] = filters.map((filter) => {
    if (!filter?.criteria?.query) return null;
    if (!filter?.id) return null;
    const query = filter?.criteria?.query;

    const match = ALL_TAGS.find((tag) => {
      return query.includes(tag);
    });

    if (!match) return null;

    return {
      id: filter.id,
      criteria: filter.criteria,
      ...filter,
    };
  });
  return mappedFilters.filter((filter) => {
    if (filter == null) {
      return false;
    }
    return true;
  }) as ExistingFilter[];
}

export function hasDuplicateFilterId(filterCommands: FilterCommand[]): boolean {
  const filterIdSet = new Set<string>();

  for (const command of filterCommands) {
    const existingFilterId = command.existingFilter?.id;
    // Skip if filterId is undefined or empty
    if (!existingFilterId) {
      continue;
    }

    if (filterIdSet.has(existingFilterId)) {
      // A duplicate filterId is found
      return true;
    }
    filterIdSet.add(existingFilterId);
  }

  // No duplicates found
  return false;
}
