export default class Base64Url {
  static decode(str: string) {
    return atob(Base64Url.unescape(str));
  }

  static encode(str: string) {
    return Base64Url.escape(btoa(str));
  }

  static unescape(str: string) {
    return (str + '==='.slice((str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/');
  }

  static escape(str: string) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
}
