import { useEffect, useState } from 'react';

import Mailbox from '../model/mailbox';
import { PromoCode } from '../features/offering/offeringSlice.types';
import TrimboxServerClient from '../services/serverClient';
import { getFeedbackDiscountCode, useHasGivenFeedback } from './useGetChurnFeedback';
import { useSearchParams } from 'react-router-dom';

export function useGetAvailablePromo(mailbox: Mailbox | null | undefined) {
  const [searchParams, setSearchParams] = useSearchParams();

  const promoIdFromURL = searchParams.get('promo');
  const lifetimeOfferFromURL = !!searchParams.get('lifetime');

  const [promoCodeFromURL, setPromoCodeFromURL] = useState<PromoCode>();

  useEffect(() => {
    const fetchPromo = async () => {
      if (promoIdFromURL) {
        const promo = await new TrimboxServerClient().fetchPromoCode({
          id: promoIdFromURL,
        });
        if (!promo.active || !promo.coupon.valid) {
          return;
        }

        setPromoCodeFromURL(promo);
      }
    };

    fetchPromo();
  }, [promoIdFromURL]);

  // Get "thanks for giving feedback" promo
  const hasGivenFeedback = useHasGivenFeedback(mailbox);

  const [promoCodeFromFeedback, setPromoCodeFromFeedback] = useState<PromoCode>();
  const feedbackDiscount = getFeedbackDiscountCode();

  useEffect(() => {
    const fetchPromo = async () => {
      if (!hasGivenFeedback) {
        return;
      }

      if (!feedbackDiscount) {
        return;
      }

      const promo = await new TrimboxServerClient().fetchPromoCode({
        id: feedbackDiscount.id,
      });
      if (!promo.active || !promo.coupon.valid) {
        return;
      }

      setPromoCodeFromFeedback(promo);
    };

    fetchPromo();
  }, [hasGivenFeedback, feedbackDiscount]);

  let optimalPromo;
  let withLifetimeOffer;

  if (promoCodeFromFeedback && promoCodeFromURL) {
    if (promoCodeFromURL.coupon?.percent_off > promoCodeFromFeedback.coupon?.percent_off) {
      optimalPromo = promoCodeFromURL;
      withLifetimeOffer = lifetimeOfferFromURL;
    } else {
      optimalPromo = promoCodeFromFeedback;
      withLifetimeOffer = feedbackDiscount?.withLifetime;
    }
  } else if (promoCodeFromURL) {
    optimalPromo = promoCodeFromURL;
    withLifetimeOffer = lifetimeOfferFromURL;
  } else if (promoCodeFromFeedback) {
    optimalPromo = promoCodeFromFeedback;
    withLifetimeOffer = feedbackDiscount?.withLifetime;
  }

  const discountPercentage = (optimalPromo?.coupon?.percent_off || 0) / 100;

  return { promoCode: optimalPromo, discountPercentage, withLifetimeOffer };
}
