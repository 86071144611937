import { db } from '../db/db';
import Message from './message';
import Mailbox from './mailbox';
import UnsubscribeCommand from '../command/unsubscribeCommand';
import ModelBase from './base';
import { UnsubscribeInfo } from '../services/gmail/unsubscribeInfo/unsubscribeInfoAdapter';
import { SubscriptionParams, UnsubscribeStatus } from './subscription.types';
import { EmailProviderListMessage } from './emailProviderMessage';
import DeleteListCommand from '../command/deleteListCommand';
import { FilterOperationCallbacks } from '../services/filterOperationQueue';
import { BulkDeleteOperationCallbacks } from '../services/bulkDeleteOperationQueue';

export default class Subscription extends ModelBase {
  public static table = 'subscriptions';

  public id = -1;
  public email_address: string;
  public display_name: string;
  public message_count?: number;
  public newest_message_received_at?: number;
  public oldest_message_received_at?: number;
  public unsubscribe_status?: UnsubscribeStatus;
  public mailbox_id: string;
  public list_image_url?: string;
  public unsubscribe_info: UnsubscribeInfo;
  public last_nonce?: string;

  constructor(params: SubscriptionParams) {
    super();
    this.email_address = params.email_address;
    this.display_name = params.display_name;
    this.message_count = params?.message_count;
    this.newest_message_received_at = params?.newest_message_received_at;
    this.oldest_message_received_at = params?.oldest_message_received_at;
    this.unsubscribe_status = params?.unsubscribe_status;
    this.mailbox_id = params.mailbox_id;
    this.list_image_url = params.list_image_url;
    this.unsubscribe_info = params.unsubscribe_info;
    this.last_nonce = params.last_nonce;
  }

  async addMessage(message: EmailProviderListMessage): Promise<Message> {
    const mailboxId = this.mailbox_id;
    const id: number = this._id();
    const newestMessageReceivedAt = this.newest_message_received_at;
    const oldestMessageReceivedAt = this.oldest_message_received_at;
    const messageCount = this.message_count ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return db.transaction('rw', db.subscriptions, db.messages, async function () {
      const shouldUpdateNewest =
        !newestMessageReceivedAt || message.receivedAt > newestMessageReceivedAt;

      const shouldUpdateOldest =
        !oldestMessageReceivedAt || message.receivedAt < oldestMessageReceivedAt;

      await self.update({
        newest_message_received_at: shouldUpdateNewest
          ? message.receivedAt
          : newestMessageReceivedAt,
        oldest_message_received_at: shouldUpdateOldest
          ? message.receivedAt
          : oldestMessageReceivedAt,
        message_count: messageCount + 1,
      });

      return await Message.create<Message>({
        external_id: message.id,
        external_thread_id: message.threadId,
        received_at: message.receivedAt,
        mailbox_id: mailboxId,
        subscription_id: id,
      });
    });
  }

  async messages() {
    return await db.messages
      .where({
        subscription_id: this._id(),
        mailbox_id: this.mailbox_id,
      })
      .toArray();
  }

  async mailbox(): Promise<Mailbox> {
    const mailbox = await db.mailboxes.get(this.mailbox_id);
    if (!mailbox) {
      throw new Error(`[Sender] mailbox not found ${this.mailbox_id}`);
    }

    return mailbox;
  }

  async updateSubscriptionStatus(unsubscribeStatus: UnsubscribeStatus, nonce: string) {
    await this.update({ unsubscribe_status: unsubscribeStatus, last_nonce: nonce });
  }

  async unsubscribe(callbacks: FilterOperationCallbacks) {
    const command = UnsubscribeCommand.create(this);

    const onSuccess = async () => {
      const mailbox = await this.mailbox();
      await mailbox.updateUser({ unsubscribeCount: 1 });
      await callbacks.onSuccess();
    };

    command.run({ onSuccess, onError: callbacks.onError });
  }

  async delete(nonce: string, callbacks: BulkDeleteOperationCallbacks) {
    const command = DeleteListCommand.create(this);

    const onSuccess = async (number_of_messages_deleted: number) => {
      const mailbox = await this.mailbox();
      await mailbox.updateUser({
        deleteCount: number_of_messages_deleted,
      });
      await callbacks.onSuccess(number_of_messages_deleted);
    };

    command.run(nonce, { onSuccess, onError: callbacks.onError });
  }

  async messageIds() {
    return await db.messages
      .where({
        subscription_id: this._id(),
        mailbox_id: this.mailbox_id,
      })
      .primaryKeys();
  }

  private _id() {
    if (this.id < 0) {
      throw new Error(`Subscription with ${this.email_address} has not been saved`);
    }

    return this.id;
  }
}

export type SubscriptionProperties = Pick<
  Subscription,
  | 'email_address'
  | 'display_name'
  | 'message_count'
  | 'unsubscribe_status'
  | 'list_image_url'
  | 'newest_message_received_at'
>;

export function getSubscriptionProperties(subscription: Subscription): SubscriptionProperties {
  return {
    email_address: subscription.email_address,
    display_name: subscription.display_name,
    message_count: subscription.message_count,
    unsubscribe_status: subscription.unsubscribe_status,
    list_image_url: subscription.list_image_url,
    newest_message_received_at: subscription.newest_message_received_at,
  };
}
