import Dexie, { Table } from 'dexie';
import Mailbox from '../model/mailbox';
import Subscription from '../model/subscription';
import Message from '../model/message';
import Bundle from '../model/emailBundle';
import BundleMembership from '../model/bundleMembership';
import ABTest from '../model/abTest';
import CheckoutSession from '../model/checkoutSession';

export class MySubClassedDexie extends Dexie {
  mailboxes!: Table<Mailbox, string>;
  subscriptions!: Table<Subscription, string>;
  bundles!: Table<Bundle, string>;
  messages!: Table<Message, string>;
  bundleMemberships!: Table<BundleMembership, string>;
  abTests!: Table<ABTest, string>;
  checkoutSessions!: Table<CheckoutSession, string>;

  constructor() {
    super('trimboxDatabase');
    this.version(5).stores({
      mailboxes: '&email_address, is_current',
      subscriptions: '++id, &[mailbox_id+email_address], last_nonce',
      bundles: 'bundle_id, message_count, [mailbox_id+bundle_id]',
      messages: '&external_id, mailbox_id, received_at, [mailbox_id+subscription_id]',
      bundleMemberships: '++id, bundle_id, message_id',
      abTests: 'test_id',
      checkoutSessions: 'session_id',
    });
    this.mailboxes.mapToClass(Mailbox);
    this.subscriptions.mapToClass(Subscription);
    this.bundles.mapToClass(Bundle);
    this.messages.mapToClass(Message);
    this.bundleMemberships.mapToClass(BundleMembership);
    this.abTests.mapToClass(ABTest);
    this.checkoutSessions.mapToClass(CheckoutSession);
  }
}

export const db = new MySubClassedDexie();
