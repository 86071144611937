import React from 'react';

import { useTheme, useMediaQuery, Box, Typography } from '@mui/material';
import SignInButton from '../components/SignInButton';
import email_permissions_still from '../../../../assets/email_permissions_still.png';
import { setPermissionsInfoDialog } from '../../../../features/ui/uiSlice';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import { useAppDispatch } from '../../../../app/hooks';
import CertificationBadge from '../components/CertificationBadge';
import { SCOPE_TYPE } from '../../../../services/credentialFactory';
import PageHeaderInfo from '../components/PageHeaderInfo';
import { useTranslation } from 'react-i18next';

function MissingScopes() {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderInfo headerText={t('needsPermission')}></PageHeaderInfo>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        sx={{
          flexGrow: 1,
          height: 0,
          maxWidth: '30rem',
          margin: '0 1rem',
        }}
      >
        <Typography
          variant='body2'
          sx={{
            fontWeight: 500,
            fontSize: aboveSmall ? '1.25rem' : isExtraSmall ? '0.85rem' : '1rem',
          }}
        >
          {t('checkBoxes')}
        </Typography>
        <Box
          sx={{ width: '100%', margin: isExtraSmall ? '1rem 0' : '2rem 0' }}
          component='img'
          src={email_permissions_still}
        ></Box>
        {/* <Box sx={{ width: '100%' }} component='img' src={email_permissions}></Box> */}
        <Typography
          variant='body2'
          onClick={() => {
            AnalyticsService.track(EventName.PERMISSIONS_INFO_SHOW);
            dispatch(setPermissionsInfoDialog(true));
          }}
          sx={{
            fontSize: aboveSmall ? '1rem' : isExtraSmall ? '0.75rem' : '0.85rem',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {t('learnMorePermissions')}
        </Typography>
        {!isExtraSmall && (
          <Typography
            variant='body2'
            sx={{
              margin: aboveSmall ? '2rem 0' : '1rem 0',
              fontWeight: 500,
              fontSize: aboveSmall ? '1rem' : isExtraSmall ? '0.75rem' : '0.85rem',
            }}
          >
            {t('noDataLeaves')}
          </Typography>
        )}
        {!isExtraSmall && <CertificationBadge></CertificationBadge>}
      </Box>
      <SignInButton
        useGmailIcon={true}
        buttonText={t('connectGmail')}
        scopeType={SCOPE_TYPE.GMAIL_ACCESS}
      ></SignInButton>
      <Box sx={{ margin: aboveSmall ? '2rem' : '1rem' }}></Box>
    </>
  );
}
export default MissingScopes;
