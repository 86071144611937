import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { ButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import 'animate.css';
import { SHOW_MOBILE_ANIMATIONS } from '../../../../../featureFlags';
import {
  EmailListUnsubscribeStatus,
  ListAction,
} from '../../../../../features/emailLists/emailLists.types';
import { useTranslation } from 'react-i18next';
import { LandingPageFlavorEnum } from '../welcomeFlavors.types';
import { getButtonCommandText } from './ExampleEmailList.types';

export type ExampleEmailListActionButtonProps = {
  currentState: EmailListUnsubscribeStatus;
  actionType: ListAction;
  landingPageFlavor: LandingPageFlavorEnum;
};

export function ExampleEmailListActionButton(props: ExampleEmailListActionButtonProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const { currentState, actionType, landingPageFlavor } = props;

  const isUnsubscribed = currentState === 'unsubscribed';

  const isKept = currentState === 'kept';

  const isFinished = isUnsubscribed || isKept;

  const isInitialState = !currentState;

  const isUnsubscribeButton = actionType === 'unsubscribe';

  let shouldHideButton = false;
  if (isUnsubscribeButton) {
    shouldHideButton = isKept;
  } else {
    shouldHideButton = isUnsubscribed;
  }

  const color = isFinished ? 'primary' : actionType === 'keep' ? 'primary' : 'red';

  let buttonText;

  if (isFinished) {
    buttonText = isUnsubscribeButton ? t(getButtonCommandText(landingPageFlavor, true)) : t('kept');
  } else {
    buttonText = isUnsubscribeButton
      ? t(getButtonCommandText(landingPageFlavor, false))
      : t('keep');
  }

  let fontSize = undefined;

  if (aboveMedium && isFinished) {
    fontSize = '1.25rem';
  } else if (isExtraSmall) {
    fontSize = '0.65rem';
  } else if (!aboveSmall) {
    fontSize = '0.75rem';
  }

  const showAnimations = aboveSmall || SHOW_MOBILE_ANIMATIONS;

  return (
    <ButtonGroup
      variant={'outlined'}
      aria-label='outlined button group'
      sx={{
        flexGrow: 1,
        ...(aboveSmall ? {} : { width: 0 }),
        ...(shouldHideButton ? { visibility: 'hidden' } : {}),
        justifyContent: 'center',
      }}
      className={isFinished && showAnimations ? 'animate__animated animate__rubberBand' : ''}
    >
      <LoadingButton
        variant={'outlined'}
        color={color}
        size={aboveSmall ? 'medium' : 'small'}
        sx={{
          fontWeight: 600,
          ...(fontSize ? { fontSize } : {}),
          ...(aboveSmall || !isInitialState
            ? {}
            : {
                flexGrow: 1,
              }),
          ...(isFinished
            ? {
                borderColor: 'transparent',
                '&:hover': {
                  background: 'none',
                },
              }
            : {}),
        }}
        startIcon={
          isFinished ? <CheckIcon /> : isUnsubscribeButton ? <UnsubscribeIcon /> : <ThumbUpIcon />
        }
        onClick={undefined}
        loading={false}
        loadingPosition='start'
      >
        {buttonText}
      </LoadingButton>
    </ButtonGroup>
  );
}
