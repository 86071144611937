import { Dict } from 'mixpanel-browser';
import { isAuthenticatedMailbox } from '../../model/authenticatedMailbox';
import Mailbox from '../../model/mailbox';
import { getErrorMessage } from '../../utilities/errorHelper';
import { EventName, ErrorDict } from './providers/analyticsProvider';
import FacebookAnalyticsProvider from './providers/facebookAnalyticsProvider';
import GoogleAnalyticsProvider from './providers/googleAnalyticsProvider';
import MixpanelAnalyticsProvider from './providers/mixpanelAnalyticsProvider';
import TrackJsAnalyticsProvider from './providers/trackJsAnalyticsProvider';
import TikTokAnalyticsProvider from './providers/tikTokAnalyticsProvider';
import TwitterAnalyticsProvider from './providers/twitterAnalyticsProvider';
import GtmAnalyticsProvider from './providers/gtmAnalyticsProvider';
import BingAnalyticsProvider from './providers/bingAnalyticsProvider';
import { IdentifyParams, CheckoutParams } from './analyticsService.types';
import LinkedInAnalyticsProvider from './providers/linkedInAnalyticsProvider';
import TrimboxServerClient from '../serverClient';

const mixpanelProvider = new MixpanelAnalyticsProvider();
const facebookProvider = new FacebookAnalyticsProvider();
const googleProvider = new GoogleAnalyticsProvider();
const trackJsProvider = new TrackJsAnalyticsProvider();
const tikTokProvider = new TikTokAnalyticsProvider();
const twitterProvider = new TwitterAnalyticsProvider();
const gtmProvider = new GtmAnalyticsProvider();
const bingProvider = new BingAnalyticsProvider();
const linkedInProvider = new LinkedInAnalyticsProvider();
export default class AnalyticsService {
  static identify(params: IdentifyParams) {
    mixpanelProvider.identify(params);
    facebookProvider.identify(params);
    googleProvider.identify(params);
    gtmProvider.identify(params);
    trackJsProvider.identify(params);
    tikTokProvider.identify(params);
    twitterProvider.identify(params);
    linkedInProvider.identify(params);
  }

  static alias(mailbox: Mailbox) {
    const id = mailbox.email_address;
    mixpanelProvider.alias(id);
    facebookProvider.alias(id);
    googleProvider.alias(id);
    tikTokProvider.alias(id);
    twitterProvider.alias(id);
    linkedInProvider.alias(id);
  }

  static trackListEvent(name: EventName, listSender: string, props?: Dict) {
    const unsubscribeProps = {
      ...props,
      listSender,
    };
    mixpanelProvider.track(name, unsubscribeProps);
    facebookProvider.track(name, unsubscribeProps);
    googleProvider.track(name, unsubscribeProps);
    tikTokProvider.track(name, unsubscribeProps);
    twitterProvider.track(name, unsubscribeProps);
    linkedInProvider.track(name, unsubscribeProps);
  }

  static track(name: EventName, props?: Dict) {
    mixpanelProvider.track(name, props);
    facebookProvider.track(name, props);
    googleProvider.track(name, props);
    gtmProvider.track(name, props);
    tikTokProvider.track(name, props);
    twitterProvider.track(name, props);
    bingProvider.track(name, props);
    linkedInProvider.track(name, props);
  }

  static trackCheckout(checkoutParams: CheckoutParams) {
    mixpanelProvider.trackCheckout(checkoutParams);
    facebookProvider.trackCheckout(checkoutParams);
    googleProvider.trackCheckout(checkoutParams);
    gtmProvider.trackCheckout(checkoutParams);
    tikTokProvider.trackCheckout(checkoutParams);
    twitterProvider.trackCheckout(checkoutParams);
    bingProvider.trackCheckout(checkoutParams);
    linkedInProvider.trackCheckout(checkoutParams);
  }

  static trackListError(name: EventName, listSender: string, e: unknown, props?: Dict) {
    const errorProps: Dict = {
      ...(props ? props : {}),
      listSender,
    };
    this.trackError(name, e, errorProps);
  }

  static trackError(name: EventName, e: unknown, props?: Dict) {
    console.error(e);

    const errorMessage: string = getErrorMessage(e);
    const errorStack: string | undefined = (e as Error).stack;
    const errorProps: ErrorDict = {
      ...(props ? props : {}),
      errorMessage,
      ...(errorStack ? { stack: errorStack } : {}),
      isError: true,
    };

    mixpanelProvider.trackError(name, errorProps);
    facebookProvider.trackError(name, errorProps);
    googleProvider.trackError(name, errorProps);
  }

  static async logCheckoutConversion(mailbox: Mailbox | null | undefined, sessionId: string) {
    this.track(EventName.CHECKOUT_CAPTURE);

    if (!mailbox) {
      throw new Error('Current mailbox not found! z');
    }
    if (!isAuthenticatedMailbox(mailbox)) {
      throw new Error('Mailbox is unauthenticated!');
    }

    try {
      const client: TrimboxServerClient = new TrimboxServerClient();

      const checkoutSession = await client.getCheckoutSession(
        sessionId,
        await mailbox.getCredential$()
      );

      // console.log(`[App] found checkout session ${JSON.stringify(checkoutSession)}`);

      if (checkoutSession.payment_status !== 'paid') {
        return;
      }

      // probably shouldn't be dividing this
      const purchaseValue: number = checkoutSession.amount_total / 100;
      const currency: string | undefined = checkoutSession.currency?.toUpperCase();
      const transactionId: string = checkoutSession.id;

      this.trackCheckout({ value: purchaseValue, currency, transactionId });
    } catch (e) {
      AnalyticsService.trackError(EventName.GET_CHECKOUT_SESSION_ERROR, e);
      throw e;
    }
  }

  static async setTestGroup(testName: string, testGroup: string) {
    await mixpanelProvider.setTestGroup(testName, testGroup);
  }

  static setFacebookUser() {
    mixpanelProvider.setFacebookUser();
  }
}
