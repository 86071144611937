import Credential from '../../model/credential';
import AnalyticsService from '../analytics/analyticsService';
import { EventName } from '../analytics/providers/analyticsProvider';
import { ExistingFilter } from './filterHelper/filterHelper';
import GmailThrottle from './gmailThrottle';

type ListMessagesRequest = {
  userId: string;
  options: { maxResults: number; pageToken?: string; query?: string };
};

type GetMessagesRequest = {
  userId: string;
  messageIds: string[];
};

type SendMessageRequest = {
  userId: string;
  message: string;
};

type ModifyMessagesRequest = {
  userId: string;
  messageIds: string[];
  addLabelIds: string[];
  removeLabelIds: string[];
};

export type CreateFilterRequest = {
  userId: string;
  addLabelIds?: string[];
  removeLabelIds?: string[];
  query: string;
};

export type DeleteFilterRequest = {
  userId: string;
  filter: ExistingFilter;
};

export type DeleteFilterByIDRequest = {
  userId: string;
  filterId: string;
};

export type DeleteFilterByQueryRequest = {
  userId: string;
  filterQuery: string;
};

export default class GmailClient {
  private static instance: GmailClient;

  private apiKey: string;
  private initialized: boolean;
  private throttle: GmailThrottle;

  constructor() {
    const apiKey: string | undefined = process.env.REACT_APP_SERVER_API_KEY;
    if (!apiKey) throw new Error('[TrimboxServerClient] api key is missing');

    this.apiKey = apiKey;
    // https://developers.google.com/gmail/api/reference/quota
    this.throttle = new GmailThrottle();
    this.initialized = false;
  }

  static getInstance() {
    if (!this.instance) this.instance = new GmailClient();
    return this.instance;
  }

  async deleteFilter(request: DeleteFilterRequest, credential: Credential): Promise<void> {
    const { filter, userId } = request;
    try {
      await this.deleteFilterById(filter, userId, credential);
    } catch (err) {
      AnalyticsService.trackError(EventName.DELETE_FILTER_BY_ID_FAILED, err);
      try {
        await this.deleteFilterByQuery(filter, userId, credential);
        AnalyticsService.track(EventName.DELETE_FILTER_BY_QUERY_SUCCESS);
      } catch (err2) {
        AnalyticsService.trackError(EventName.DELETE_FILTER_BY_QUERY_FAILED, err2);
      }
    }
  }

  async deleteFilterById(
    filter: ExistingFilter,
    userId: string,
    credential: Credential
  ): Promise<void> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(5);
    try {
      await gapi.client.gmail.users.settings.filters.delete({
        access_token: credential.accessToken,
        userId,
        key: this.apiKey,
        id: filter.id,
      });
    } catch (err) {
      const error: any = err as any;
      if (error?.status !== 404) {
        console.error(err);
        throw err;
      }
    }
  }

  async deleteFilterByQuery(
    filter: ExistingFilter,
    userId: string,
    credential: Credential
  ): Promise<void> {
    const res: gapi.client.gmail.ListFiltersResponse = await this.listFilters(userId, credential);

    const existingFilters = res.filter || [];

    const matchingFilter = existingFilters.find((existingFilter) => {
      return filter.criteria?.query === existingFilter.criteria?.query;
    }) as ExistingFilter;

    if (!matchingFilter) {
      throw new Error('No matching filter found!');
    }

    if (!matchingFilter.id) {
      throw new Error('Matching filter found, but missing an id!');
    }

    await this.deleteFilterById(matchingFilter, userId, credential);
  }

  async createFilter(request: CreateFilterRequest, credential: Credential): Promise<void> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(5);
    try {
      await gapi.client.gmail.users.settings.filters.create({
        access_token: credential.accessToken,
        userId: request.userId,
        key: this.apiKey,
        resource: {
          action: {
            addLabelIds: request.addLabelIds,
            removeLabelIds: request.removeLabelIds,
          },
          criteria: {
            excludeChats: true,
            query: request.query,
          },
        },
      });
    } catch (err: unknown) {
      const error: any = err as any;
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error?.status === 400 &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error?.result?.error?.message?.toLowerCase() === 'filter already exists'
      ) {
        return;
      }

      console.error(err);
      throw err;
    }
  }

  async listFilters(
    userId: string,
    credential: Credential
  ): Promise<gapi.client.gmail.ListFiltersResponse> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(1);
    try {
      const response: gapi.client.Response<gapi.client.gmail.ListFiltersResponse> =
        await gapi.client.gmail.users.settings.filters.list({
          access_token: credential.accessToken,
          userId: userId,
          key: this.apiKey,
        });

      if (response.status === 200) {
        return response.result;
      }

      return { filter: [] };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async modifyMessages(request: ModifyMessagesRequest, credential: Credential): Promise<void> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(50);
    try {
      await gapi.client.gmail.users.messages.batchModify({
        access_token: credential.accessToken,
        userId: request.userId,
        key: this.apiKey,
        resource: {
          ids: request.messageIds,
          addLabelIds: request.addLabelIds,
          removeLabelIds: request.removeLabelIds,
        },
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async sendMessage(
    request: SendMessageRequest,
    credential: Credential
  ): Promise<gapi.client.gmail.Message> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(100);
    try {
      const response: gapi.client.Response<gapi.client.gmail.Message> =
        await gapi.client.gmail.users.messages.send({
          access_token: credential.accessToken,
          userId: request.userId,
          key: this.apiKey,
          resource: {
            raw: request.message,
          },
        });
      return response.result;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async listMessages(
    request: ListMessagesRequest,
    credential: Credential
  ): Promise<gapi.client.gmail.ListMessagesResponse> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(5);
    try {
      const response: gapi.client.Response<gapi.client.gmail.ListMessagesResponse> =
        await gapi.client.gmail.users.messages.list({
          key: this.apiKey,
          access_token: credential.accessToken,
          userId: request.userId,
          q: request.options.query,
          pageToken: request.options.pageToken,
        });

      return response.result;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getMessages(
    request: GetMessagesRequest,
    credential: Credential
  ): Promise<gapi.client.gmail.Message[]> {
    await this.checkCredentialPreconditions(credential);
    await this.initialize();
    await this.throttle.take(request.messageIds.length * 5);
    const batch = gapi.client.newBatch();
    request.messageIds.forEach((id) => {
      batch.add(
        gapi.client.gmail.users.messages.get({
          key: this.apiKey,
          access_token: credential.accessToken,
          userId: request.userId,
          id: id,
        }),
        {
          id,
          callback: (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            individualResponse: gapi.client.Response<gapi.client.gmail.Message>,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            rawBatchResponse: string
          ) => {
            return;
          },
        }
      );
    });

    return await this.executeBatch(batch);
  }

  private async checkCredentialPreconditions(credential: Credential) {
    if (!credential) throw new Error('[GmailClient] credential is missing');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async executeBatch(batch: gapi.client.Batch<any>): Promise<gapi.client.gmail.Message[]> {
    return new Promise((resolve, reject) => {
      batch.execute((responseMap: gapi.client.ResponseMap<gapi.client.gmail.Message>) => {
        const messages: gapi.client.gmail.Message[] = [];
        Object.keys(responseMap).forEach((id: string) => {
          const response = responseMap[id];
          if (response && !response.status) {
            messages.push(response.result);
          } else {
            const error = JSON.parse(response.body);
            reject(new Error(`[GmailClient] ${response.status} - ${error.message.toLowerCase()}`));
          }
        });

        resolve(messages);
      });
    });
  }

  private async initialize(): Promise<void> {
    if (this.initialized) return;

    return new Promise((resolve, reject) => {
      gapi.load('client', {
        callback: async () => {
          await gapi.client.init({
            apiKey: this.apiKey,
            discoveryDocs: ['https://gmail.googleapis.com/$discovery/rest?version=v1'],
          });
          this.initialized = true;
          resolve();
        },
        onerror: () => {
          reject(new Error('[GmailClient] failed to load gapi client'));
        },
        timeout: 5000,
        ontimeout: () => {
          reject(new Error('[GmailClient] timed out while loading gapi client'));
        },
      });
    });
  }
}
