export const forwardedMessageHeaders = [
  '- forwarded message -',
  '- message transféré -',
  '- weitergeleitete nachricht -',
  '- doorgestuurd bericht -',
  '- videresendt besked -',
  '- mensaje reenviado -',
  '- proslijeđena poruka -',
  '- messaggio inoltrato -',
  '- 전달된 메시지 -',
  '- mensagem encaminhada -',
  '- пересылаемое сообщение -',
  '- iletilen mesaj -',
];
