import { EmailBundleConfig } from '../features/emailBundles/emailBundles.configs';
import Bundle from '../model/emailBundle';
import Mailbox from '../model/mailbox';
import Message from '../model/message';
import Subscription from '../model/subscription';
import AnalyticsService from './analytics/analyticsService';
import { EventName } from './analytics/providers/analyticsProvider';
import EmailProvider from './emailProvider';
import { nanoid } from 'nanoid/non-secure';

export type BulkDeleteOperationCallbacks = {
  onSuccess: (number_of_messages_deleted: number) => Promise<void>;
  onError: (e: unknown) => void;
};

export type BulkDeleteOperation = BulkDeleteSubscriptionOperation | BulkDeleteBundleOperation;

export type BulkDeleteSubscriptionOperation = {
  subscription: Subscription;
  nonce: string;
  callbacks: BulkDeleteOperationCallbacks;
};

export type BulkDeleteBundleOperation = {
  bundle: Bundle;
  callbacks: BulkDeleteOperationCallbacks;
};

function isBundleOperation(operation: BulkDeleteOperation): operation is BulkDeleteBundleOperation {
  return !!(operation as BulkDeleteBundleOperation).bundle;
}

export default class BulkDeleteOperationQueue {
  private static instance: BulkDeleteOperationQueue;

  emailProvider: EmailProvider;
  bulkDeleteOperationsQueue: BulkDeleteOperation[] = [];
  isRunning = false;

  static create(emailProvider: EmailProvider): BulkDeleteOperationQueue {
    if (!this.instance) {
      this.instance = new BulkDeleteOperationQueue(emailProvider);
    }

    return this.instance;
  }

  private constructor(emailProvider: EmailProvider) {
    this.emailProvider = emailProvider;
  }

  queue(operation: BulkDeleteOperation) {
    this.bulkDeleteOperationsQueue.push(operation);

    // AnalyticsService.track(EventName.QUEUE_BULK_DELETE_OPERATION);

    this.tryExecute();
  }

  async tryExecute(ignoreLock = false) {
    if (this.isRunning) {
      if (!ignoreLock) {
        return;
      }
    }

    this.isRunning = true;

    const operationId: string = nanoid();

    // AnalyticsService.track(EventName.START_BULK_DELETE_OPERATION, { filterOperationId });

    const bulkDeleteOperations = this.bulkDeleteOperationsQueue;
    this.bulkDeleteOperationsQueue = [];

    for (const bulkDeleteOperation of bulkDeleteOperations) {
      const { callbacks } = bulkDeleteOperation;

      try {
        let number_of_messages: number;
        if (isBundleOperation(bulkDeleteOperation)) {
          const { bundle } = bulkDeleteOperation;
          number_of_messages = await this.bulkDeleteFromBundle(bundle);
        } else {
          const { subscription, nonce } = bulkDeleteOperation;
          number_of_messages = await this.bulkDeleteFromSubscription(subscription, nonce);
        }

        await callbacks.onSuccess(number_of_messages);
      } catch (e) {
        AnalyticsService.trackError(EventName.DELETE_ALL_OPERATION_FAILED, e, {
          filterOperationId: operationId,
        });
        callbacks.onError(e);
      }
    }

    // AnalyticsService.track(EventName.END_FILTER_OPERATION, { filterOperationId });

    if (this.bulkDeleteOperationsQueue.length) {
      // AnalyticsService.track(EventName.RERUN_FILTER_OPERATION, { filterOperationId });

      this.tryExecute(true);
    }

    if (!ignoreLock) {
      this.isRunning = false;
    }
  }

  private async bulkDeleteFromSubscription(subscription: Subscription, nonce: string) {
    const mailbox: Mailbox = await subscription.mailbox();

    const subscription_message_ids = await this.emailProvider.listSubscriptionMessages(
      subscription,
      mailbox
    );

    const number_of_messages = await this.emailProvider.deleteMessagesById(
      mailbox,
      subscription_message_ids
    );

    await subscription.update({
      message_count: 0,
      last_nonce: nonce,
    });

    await Message.deleteMessagesById(await subscription.messageIds());

    return number_of_messages;
  }

  private async bulkDeleteFromBundle(bundle: Bundle) {
    const mailbox: Mailbox = await bundle.mailbox();

    const bundleConfig: EmailBundleConfig = bundle.bundleConfig();

    let number_of_messages: number;

    if (bundleConfig.query) {
      number_of_messages = await this.emailProvider.deleteMessagesByQuery(
        mailbox,
        bundleConfig.query
      );
    } else {
      const messages = await bundle.messages();
      number_of_messages = await this.emailProvider.deleteMessages(mailbox, messages);
    }

    const messageIds = await bundle.messageIds();
    await Message.deleteMessagesById(messageIds);

    await bundle.update({ message_count: 0 });

    return number_of_messages;
  }
}
