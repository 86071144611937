import { useEffect, useState } from 'react';
import TrimboxServerClient, { LicenseTerm } from '../services/serverClient';
import Mailbox from '../model/mailbox';
import { PlanInterval } from '../features/offering/offeringSlice.types';

export function useGetCurrentPlanInterval(mailbox: Mailbox | undefined | null) {
  const [currentPlanIntervals, setCurrentPlanIntervals] = useState<PlanInterval[]>([]);

  useEffect(() => {
    const fetchLicenses = async () => {
      if (!mailbox) {
        return;
      }

      const fetchedLicenses =
        (await new TrimboxServerClient().listLicenses(
          mailbox.user_id,
          await mailbox.getCredential$()
        )) || [];

      const ownedLicenses = fetchedLicenses.filter(
        (license) => license.owner === mailbox.user_id && !!license.type
      );

      const ownedLicensePlanTypes: PlanInterval[] = ownedLicenses?.map((ownedLicense) => {
        const licenseType: LicenseTerm | undefined = ownedLicense.type;
        if (licenseType === 'yearly') {
          return 'year';
        } else if (licenseType === 'monthly') {
          return 'month';
        } else if (licenseType === 'lifetime') {
          return 'lifetime';
        }
        throw new Error('Unrecognized license type!');
      });

      setCurrentPlanIntervals(ownedLicensePlanTypes);
    };

    fetchLicenses();
  }, [mailbox]);

  return {
    currentPlanIntervals,
  };
}
