import React, { useEffect } from 'react';

import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Box,
  Stack,
  Avatar,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  closeProgressDialog,
  openPaywallDialog,
  selectIsProgressDialogOpen,
  selectProgressDialogType,
} from '../features/ui/uiSlice';
import ConfettiFirework from './ConfettiFirework';
import flame from '../assets/flame.gif';
import { SHOW_MOBILE_ANIMATIONS } from '../featureFlags';
import { useTranslation } from 'react-i18next';
import { getCurrentMailboxQuery } from '../model/mailbox';
import { useLiveQuery } from 'dexie-react-hooks';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function ProgressPopup() {
  const isProgressDialogOpen = useAppSelector(selectIsProgressDialogOpen);
  const progressDialogType = useAppSelector(selectProgressDialogType);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeProgressDialog());

    if (progressDialogType === 'prePaywall') {
      dispatch(openPaywallDialog());
    }
  };

  const mailbox = useLiveQuery(getCurrentMailboxQuery);
  const numberOfUnsubscribes = mailbox?.unsubscribe_count;
  const numberOfDeletes = mailbox?.delete_count;

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const showAnimations = aboveSmall || SHOW_MOBILE_ANIMATIONS;

  const { t } = useTranslation();

  return (
    <Dialog
      open={!!isProgressDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        id: 'confetti-canvas',
        sx: {
          maxWidth: 'none',
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
                padding: '0rem 2rem',
              }
            : {
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <ConfettiFirework
        shouldFire={showAnimations && isProgressDialogOpen && progressDialogType !== 'streak'}
      ></ConfettiFirework>
      {progressDialogType === 'streak' ? (
        <Avatar
          src={flame}
          sx={{
            width: '4rem',
            height: '4rem',
            margin: '1rem auto',
          }}
        ></Avatar>
      ) : (
        <Typography fontSize={'4rem'} textAlign='center' mt={'1rem'}>
          🎉
        </Typography>
      )}
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
          ...(aboveSmall ? {} : { paddingTop: 0 }),
        }}
      >
        {t(progressDialogType !== 'streak' ? `inboxLookingGreat` : 'cleaningStreak')}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography color='common.black' fontSize={'1rem'} textAlign={'center'}>
          {t('progressSoFar')}
        </Typography>
        <Stack
          direction='row'
          mt={'2rem'}
          sx={{
            height: '100%',
            alignItems: 'center',
            gap: aboveSmall ? '2rem' : '1rem',
          }}
        >
          {StatTile({
            stat: numberOfUnsubscribes,
            label: t('listsUnsubscribed'),
            aboveSmall,
            isExtraSmall,
          })}
          {StatTile({
            stat: numberOfDeletes,
            label: t('emailsDeleted'),
            aboveSmall,
            isExtraSmall,
          })}
        </Stack>
      </DialogContent>
      <Button
        variant='contained'
        size='large'
        onClick={() => {
          handleClose();
        }}
        sx={{
          margin: aboveSmall ? '2rem' : '1rem 2rem 2rem 2rem',
          fontSize: '1rem',
          fontWeight: 600,
          borderRadius: '1rem',
        }}
      >
        {t('gotIt')}
      </Button>
    </Dialog>
  );
}
export default ProgressPopup;

type StatTileProps = {
  stat: number | undefined;
  label: string;
  aboveSmall: boolean;
  isExtraSmall: boolean;
};

function StatTile(props: StatTileProps) {
  let compactStat: string | number = '--';
  if (props.stat !== undefined) {
    compactStat = kFormatter(props.stat);
  }

  return (
    <Box
      flexGrow={1}
      padding={
        props.isExtraSmall
          ? '0rem 0.5rem 0.5rem 0.5rem '
          : props.aboveSmall
          ? '1rem 0.5rem'
          : '0.5rem 1rem 1rem 1rem'
      }
      borderRadius={'1rem'}
      flex={1}
      textAlign={'center'}
      sx={{
        boxShadow: '10px 10px 4px -3px rgba(0,0,0,0.17);',
        border: '1px solid black',
      }}
    >
      <Typography
        color='common.black'
        fontSize={props.isExtraSmall ? '2rem' : props.aboveSmall ? '3rem' : '2.5rem'}
      >
        {compactStat}
      </Typography>
      <Typography color='common.black' fontSize={props.aboveSmall ? '0.85rem' : '0.75rem'} noWrap>
        {props.label}
      </Typography>
    </Box>
  );
}

function kFormatter(num: number) {
  if (num < 1000) {
    return num;
  }
  const abbreviated = (Math.abs(num) / 1000).toFixed(1);
  return abbreviated + 'K';
}
