import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { EventName, ErrorDict } from './analyticsProvider';

const CONVERSION_LABEL_BY_EVENT_NAME: Record<string, string> = {
  [EventName.CLICK_LOGIN]: 'aA4vCNWk34oYEJ61ic8B',
  [EventName.LOGIN_SUCCESSFUL]: 'aOfTCK_94YoYEJ61ic8B',
  [EventName.CLICK_UNSUBSCRIBE]: 'M0ytCNWz34oYEJ61ic8B',
  [EventName.CLICK_KEEP]: 'M0ytCNWz34oYEJ61ic8B',
  [EventName.CLICK_DELETE_BUNDLE]: 'M0ytCNWz34oYEJ61ic8B',
  [EventName.PAYWALL_SHOW]: 'YQjoCKey4YoYEJ61ic8B',
  [EventName.PAYWALL_CONFIRM]: 'N3FBCIy44YoYEJ61ic8B',
  [EventName.CHECKOUT_COMPLETE]: 'FVvoCMWs4YoYEJ61ic8B',
};

export default class GoogleAnalyticsProvider implements AnalyticsProvider {
  public identify(params: IdentifyParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag('set', 'user_data', {
      ...(params.first_name ? { first_name: params.first_name } : {}),
      ...(params.last_name ? { last_name: params.last_name } : {}),
      email: params.email_address,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public alias(id: string) {
    // Not needed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public track(name: EventName, props?: Dict) {
    if (name in CONVERSION_LABEL_BY_EVENT_NAME) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      gtag('event', 'conversion', {
        send_to: `AW-434264734/${CONVERSION_LABEL_BY_EVENT_NAME[name]}`,
      });
    }
  }

  public trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      send_to: `AW-434264734/${CONVERSION_LABEL_BY_EVENT_NAME[EventName.CHECKOUT_COMPLETE]}`,
      ...checkoutParams,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    gtag('event', 'conversion', params);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public trackError(name: EventName, errorProps: ErrorDict) {
    // Do not log errors to Google
  }
}
