import React from 'react';

import { useTheme, useMediaQuery, Typography } from '@mui/material';

export type PageHeaderInfoProps = {
  headerText: string;
  subHeaderText?: string | null;
  noTopMargin?: boolean;
};

function PageHeaderInfo(props: PageHeaderInfoProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { noTopMargin } = props;

  return (
    <>
      <Typography
        variant='h1'
        component='h1'
        fontWeight={600}
        margin={
          isExtraSmall
            ? '1rem 1rem 1rem 1rem'
            : aboveSmall
            ? '4rem 1rem 2rem 1rem'
            : '2rem 1rem 1rem 1rem'
        }
        {...(noTopMargin
          ? {
              marginTop: isExtraSmall ? '1rem ' : aboveSmall ? '2rem' : '1rem',
            }
          : {})}
        maxWidth='40rem'
        textAlign={'center'}
        fontSize={isExtraSmall ? '1.5rem' : aboveSmall ? '2rem' : '1.75rem'}
      >
        {props.headerText}
      </Typography>
      {props.subHeaderText && (
        <Typography
          margin='0rem 1rem 1rem 1rem'
          fontSize={isExtraSmall ? '0.85rem' : aboveSmall ? '1.35rem' : '1rem'}
          maxWidth='40rem'
        >
          {props.subHeaderText}
        </Typography>
      )}
    </>
  );
}

export default PageHeaderInfo;
