import React from 'react';

import { useTheme, useMediaQuery, Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlansList from './PlansList';
import { selectSelectedPlanInterval } from '../../../../features/offering/offeringSlice';
import { useAppSelector } from '../../../../app/hooks';
import TotalDue from '../TotalDue';
import logo from '../../../../assets/trimbox_logo.png';
import { useGetOffering } from '../../../../app/useGetOffering';
import Mailbox from '../../../../model/mailbox';

export type PlanSelectionProps = {
  onClick: () => void;
  hasFeedbackDiscount: boolean;
  mailbox: Mailbox | null | undefined;
};

function PlanSelection(props: PlanSelectionProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const { hasFeedbackDiscount, mailbox, onClick } = props;

  const selectedPlanIndex = useAppSelector(selectSelectedPlanInterval);

  const { hasAnnualOption, discountPercentage } = useGetOffering({ mailbox });

  return (
    <>
      {aboveSmall && (
        <Box
          component='img'
          src={logo}
          sx={{
            maxWidth: isExtraSmall ? '60%' : aboveSmall ? '20rem' : '80%',
            margin: isExtraSmall ? '0rem auto 0.5rem auto' : '0rem auto 0.5rem auto',
            width: aboveSmall ? 50 : isExtraSmall ? 30 : 35,
            height: aboveSmall ? 50 : isExtraSmall ? 30 : 35,
          }}
        />
      )}
      <Typography
        fontSize={isExtraSmall ? '1rem' : aboveSmall ? '1.25rem' : '1.15rem'}
        fontWeight={500}
        textAlign='center'
      >
        {t('chooseYourPlan')}
      </Typography>
      {hasFeedbackDiscount && (
        <Typography
          fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
          textAlign='center'
          margin='0.5rem 0rem'
        >
          {t('additionalPercentOff', { percentage: discountPercentage * 100 })}
        </Typography>
      )}
      <PlansList hasFeedbackDiscount={hasFeedbackDiscount} mailbox={mailbox} />
      {selectedPlanIndex !== undefined && selectedPlanIndex !== null && hasAnnualOption && (
        <TotalDue mailbox={mailbox} />
      )}
      <Button
        variant='contained'
        size='large'
        disabled={selectedPlanIndex === undefined || selectedPlanIndex === null}
        onClick={onClick}
        sx={{
          margin: 0,
          marginTop: aboveSmall ? '1.5rem' : '1rem',
          fontSize: isExtraSmall ? '0.85rem' : '1rem',
          fontWeight: 600,
          borderRadius: '0.25rem',
          ...(aboveSmall ? { padding: '0.5rem 4rem' } : {}),
        }}
      >
        {t('keepCleaningYourInbox')}
      </Button>
    </>
  );
}

export default PlanSelection;
