import React from 'react';

import { Avatar, Box, ListItemIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmailListActionButton } from './EmailListActionButton';
import { LIGHTER_GRAY_HEX, WHITE_HEX } from '../../theme';
import { SubscriptionProperties } from '../../model/subscription';
import extractDomain from 'extract-domain';
import ReactTimeAgo from 'react-time-ago';
import { useTranslation } from 'react-i18next';

export type EmailListItemProps = SubscriptionProperties & {
  mailboxId: string;
};

export function EmailListItem(props: EmailListItemProps) {
  const { email_address, display_name, message_count, newest_message_received_at, mailboxId } =
    props;

  const theme = useTheme();
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const avatarSize: number = isExtraSmall ? 30 : aboveSmall ? 44 : 36;

  const favIconUrl = getGoogleFavIcon(email_address);

  const { t } = useTranslation();

  return (
    <Box
      key={email_address}
      sx={{
        backgroundColor: WHITE_HEX,
        borderRadius: '0.5rem',
        padding: '1rem',
        display: 'flex',
        marginBottom: '1rem',
        ...(aboveSmall ? { flexDirection: 'row' } : { flexDirection: 'column' }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...(aboveSmall ? { flexGrow: 1, marginRight: '1rem' } : { marginBottom: '1rem' }),
          minWidth: 0,
          overflow: 'hidden',
        }}
      >
        <ListItemIcon
          sx={{
            marginRight: isExtraSmall ? '0.5rem' : '1rem',
            height: 'fit-content',
            minWidth: 'unset',
          }}
        >
          {favIconUrl ? (
            <Box
              component='img'
              src={favIconUrl}
              sx={{
                height: avatarSize,
                width: avatarSize,
                borderRadius: '0.25rem',
              }}
            />
          ) : (
            <Avatar
              alt={display_name}
              sx={{
                // backgroundColor: PRIMARY_COLOR_HEX,
                width: avatarSize,
                height: avatarSize,
              }}
            >
              {display_name?.[0]}
            </Avatar>
          )}
        </ListItemIcon>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            ...(aboveSmall
              ? {
                  flexDirection: 'column',
                  alignItems: 'start',
                  overflow: 'hidden',
                }
              : {
                  flexDirection: 'row',
                  alignItems: 'center',
                }),
          }}
        >
          <Box
            sx={{
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'start',
              ...(aboveSmall
                ? { marginBottom: '0.5rem', display: 'flex' }
                : { marginRight: '1rem', display: 'grid' }),
            }}
          >
            <Typography
              noWrap
              sx={{
                fontWeight: 600,
                textAlign: 'start',
                fontSize: aboveSmall ? '1rem' : '0.85rem',
              }}
            >
              {display_name}
            </Typography>
            <Typography variant='caption' noWrap sx={{ textAlign: 'start' }}>
              {email_address}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant='caption'
              sx={{
                borderRadius: '1rem',
                backgroundColor: LIGHTER_GRAY_HEX,
                padding: '0.15rem 0.75rem',
                whiteSpace: 'nowrap',
              }}
            >
              {t('xEmails', { count: message_count })}
            </Typography>
            {aboveMedium && newest_message_received_at && !!message_count && (
              <Typography
                variant='caption'
                sx={{
                  borderRadius: '1rem',
                  backgroundColor: LIGHTER_GRAY_HEX,
                  padding: '0.15rem 0.75rem',
                  whiteSpace: 'nowrap',
                  marginLeft: '0.75rem',
                }}
              >
                {t('lastEmail')}
                <ReactTimeAgo date={newest_message_received_at} locale='en-US' />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: isExtraSmall ? '0.5rem' : '1rem',
          ...(aboveSmall
            ? {
                alignSelf: 'center',
              }
            : {
                justifyContent: 'space-between',
              }),
          alignItems: 'center',
        }}
      >
        <EmailListActionButton
          subscriptionProperties={props}
          actionType={'keep'}
          mailboxId={mailboxId}
        />
        <EmailListActionButton
          subscriptionProperties={props}
          actionType={'unsubscribe'}
          mailboxId={mailboxId}
        />
      </Box>
    </Box>
  );
}

function getGoogleFavIcon(emailAddress: string) {
  const senderDomain = extractDomain(emailAddress);
  const favIconUrl = `https://s2.googleusercontent.com/s2/favicons?domain=${senderDomain}&sz=256`;
  return favIconUrl;
}

function getDuckDuckGoFavIcon(emailAddress: string) {
  const senderDomain = extractDomain(emailAddress);
  const favIconUrl = `https://icons.duckduckgo.com/ip3/${senderDomain}.ico`;
  return favIconUrl;
}
