import { useAppSelector } from './hooks';
import { selectHasGivenFeedback } from '../features/ui/uiSlice';
import Mailbox from '../model/mailbox';
import {
  FEEDBACK_PROMO_DEV_30,
  FEEDBACK_PROMO_DEV_30_LIFETIME,
  FEEDBACK_PROMO_PROD_30,
  FEEDBACK_PROMO_PROD_50,
  FEEDBACK_PROMO_PROD_50_LIFETIME,
  PromoCoupon,
} from './PromoCoupon.types';
import { CHURN_SURVEY_QUESTION_1 } from '../constants';

export function useHasGivenFeedback(mailbox: Mailbox | null | undefined) {
  // Get "thanks for giving feedback" promo
  const hasGivenFeedbackState = useAppSelector(selectHasGivenFeedback);
  const hasGivenFeedbackCache = mailbox?.has_given_feedback;
  const hasFeedbackDiscount = !!(hasGivenFeedbackState || hasGivenFeedbackCache);

  return hasFeedbackDiscount;
}

export function getFeedbackDiscountCode(question1?: QUESTION_1 | undefined): PromoCoupon {
  const stored_question_1 = localStorage.getItem(CHURN_SURVEY_QUESTION_1) as QUESTION_1;

  // if (shouldOfferLifetimeOffer(question1 || stored_question_1)) {
  //   return process.env.REACT_APP_TRIMBOX_ENV !== 'production'
  //     ? FEEDBACK_PROMO_DEV_30_LIFETIME
  //     : FEEDBACK_PROMO_PROD_50_LIFETIME;
  // }

  return process.env.REACT_APP_TRIMBOX_ENV !== 'production'
    ? FEEDBACK_PROMO_DEV_30
    : // : FEEDBACK_PROMO_PROD_50;
      FEEDBACK_PROMO_PROD_30;
}

export enum QUESTION_1 {
  DID_NOT_WORK = 'DID_NOT_WORK',
  FINISHED = 'FINISHED_CLEANING',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  OTHER = 'OTHER',
}

type QuestionOneOption = {
  value: QUESTION_1;
  labelResource: string;
};

export const radioOptions: QuestionOneOption[] = [
  { value: QUESTION_1.DID_NOT_WORK, labelResource: 'churn_q1_a_v1' },
  { value: QUESTION_1.FINISHED, labelResource: 'churn_q1_a_v2' },
  { value: QUESTION_1.TOO_EXPENSIVE, labelResource: 'churn_q1_a_v3' },
  { value: QUESTION_1.OTHER, labelResource: 'churn_q1_a_v4' },
];

type OptionToStringMapping = Record<QUESTION_1, string>;

export const question1ToQuestion2Mapping: OptionToStringMapping = {
  [QUESTION_1.DID_NOT_WORK]: 'churn_q2_v1',
  [QUESTION_1.FINISHED]: 'churn_q2_v2',
  [QUESTION_1.TOO_EXPENSIVE]: 'churn_q2_v3',
  [QUESTION_1.OTHER]: 'churn_q2_v4',
};

export const question1ToDiscountTitleMapping: OptionToStringMapping = {
  [QUESTION_1.DID_NOT_WORK]: 'churn_discount_v1',
  [QUESTION_1.FINISHED]: 'churn_discount_v2',
  [QUESTION_1.TOO_EXPENSIVE]: 'churn_discount_v3',
  [QUESTION_1.OTHER]: 'churn_discount_v4',
};

function shouldOfferLifetimeOffer(question1Response: QUESTION_1 | undefined) {
  if (question1Response == QUESTION_1.FINISHED || question1Response == QUESTION_1.TOO_EXPENSIVE) {
    return true;
  }
  return false;
}
