import { useCallback, useEffect, useState } from 'react';

import { useGetActiveLicenseInfo } from '../../app/useGetLicenses';
import TrimboxServerClient from '../../services/serverClient';
import Mailbox from '../../model/mailbox';

export type LicensedUser = {
  email: string;
  isOwner: boolean;
};

export function useLicenseManagerAPI(mailbox: Mailbox) {
  const [licensedUsers, setLicensedUsers] = useState<LicensedUser[]>([]);

  const { licenseInfo } = useGetActiveLicenseInfo(mailbox);

  useEffect(() => {
    if (licenseInfo) {
      const _licensedUsers: LicensedUser[] = [];
      Object.entries(licenseInfo).forEach(([id, userInfo]) => {
        if (userInfo.active && userInfo.email) {
          _licensedUsers.push({
            email: userInfo.email,
            isOwner: id === mailbox.user_id,
          });
        }
      });
      setLicensedUsers(_licensedUsers);
    }
  }, [licenseInfo]);

  const onDeleteUserFromLicense = useCallback(async (email: string) => {
    const updatedLicense = await new TrimboxServerClient().removeUserFromLicense(
      {
        userToRemoveEmail: email,
        currentUserId: mailbox.user_id,
      },
      await mailbox.getCredential$()
    );
    setLicensedUsers((prevLicensedUsers) => prevLicensedUsers.filter((u) => u.email !== email));
  }, []);

  const onAddUserToLicense = useCallback(async (email: string) => {
    const updatedLicense = await new TrimboxServerClient().addUserToLicense(
      {
        userToAddEmail: email,
        currentUserId: mailbox.user_id,
      },
      await mailbox.getCredential$()
    );
    const newLicensedUser: LicensedUser = { email, isOwner: false };
    setLicensedUsers((prevLicenses) => [...prevLicenses, newLicensedUser]);
  }, []);

  const onUnlinkAccount = useCallback(async () => {
    await new TrimboxServerClient().unlinkUserFromLicenses(
      mailbox.user_id,
      await mailbox.getCredential$()
    );
    return true;
  }, []);

  return { licensedUsers, onDeleteUserFromLicense, onAddUserToLicense, onUnlinkAccount };
}
