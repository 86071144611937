import React, { Button, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import theme from '../../theme';

export type LicensesPageButtonsProps = {
  primaryButtonClick: () => void;
  primaryButtonText: string;
  secondaryButtonClick: () => void;
  secondaryButtonText: string;
};

export default function LicensesPageButtons({
  primaryButtonClick,
  primaryButtonText,
  secondaryButtonClick,
  secondaryButtonText,
}: LicensesPageButtonsProps) {
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Button
        variant={'contained'}
        color='primary'
        size='large'
        sx={{
          fontWeight: 700,
          fontSize: aboveSmall ? 18 : 14,
        }}
        onClick={primaryButtonClick}
      >
        {t(primaryButtonText)}
      </Button>
      <Button
        variant={'outlined'}
        color='darkGray'
        size='large'
        sx={{
          fontWeight: 700,
          fontSize: aboveSmall ? 18 : 14,
        }}
        onClick={secondaryButtonClick}
      >
        {t(secondaryButtonText)}
      </Button>
    </>
  );
}
