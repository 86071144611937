import retry from 'retry';
import { EmailProviderMessage } from '../../model/emailProviderMessage';
import Mailbox from '../../model/mailbox';
import Subscription from '../../model/subscription';
import BatchGenerator from '../../utilities/batchGenerator';
import EmailProvider, { ListMessageOptions, SMPT_SUBJECT } from '../emailProvider';
import GmailClient, { CreateFilterRequest, DeleteFilterRequest } from './gmailClient';
import { SenderInfoAdapter, SenderInfo } from './senderInfoAdapter';
import { UnsubscribeInfoAdapter } from './unsubscribeInfo/unsubscribeInfoAdapter';
import { AuthenticatedMailbox, isAuthenticatedMailbox } from '../../model/authenticatedMailbox';
import { generateFilterCommands, hasDuplicateFilterId } from './filterHelper/filterHelper';
import Message from '../../model/message';
import AnalyticsService from '../analytics/analyticsService';
import { EventName } from '../analytics/providers/analyticsProvider';
import Credential from '../../model/credential';
import { FilterOperation } from '../filterOperationQueue';

export class MutexTimeoutError extends Error {
  constructor() {
    super('Failed to acquire mutex');
  }
}

export default class GmailEmailProvider implements EmailProvider {
  private static instance: GmailEmailProvider;

  private client: GmailClient;
  private senderInfoAdapter: SenderInfoAdapter;
  private unsubscribeInfoAdapter: UnsubscribeInfoAdapter;
  private static hasBouncebackFilter = false;

  static create(): GmailEmailProvider {
    if (!this.instance) {
      this.instance = new GmailEmailProvider(
        GmailClient.getInstance(),
        new SenderInfoAdapter(),
        new UnsubscribeInfoAdapter()
      );
    }

    return this.instance;
  }

  private constructor(
    client: GmailClient,
    senderInfoAdapter: SenderInfoAdapter,
    unsubscribeInfoAdapter: UnsubscribeInfoAdapter
  ) {
    this.client = client;
    this.senderInfoAdapter = senderInfoAdapter;
    this.unsubscribeInfoAdapter = unsubscribeInfoAdapter;
  }

  async deleteMessagesByQuery(mailbox: Mailbox, query: string): Promise<number> {
    if (!mailbox) throw new Error('[Gmail] mailbox is missing');

    const messageIds = await this.listMessageIds(mailbox, query);
    return await this.deleteMessagesById(mailbox, messageIds);
  }

  async deleteMessages(mailbox: Mailbox, messages: Message[]): Promise<number> {
    if (!mailbox) throw new Error('[Gmail] mailbox is missing');
    if (!isAuthenticatedMailbox(mailbox)) {
      throw new Error('[Gmail] credential is missing');
    }

    const messageIds: string[] = messages.map((message) => message.external_id);
    return await this.deleteMessagesById(mailbox, messageIds);
  }

  async deleteMessagesById(mailbox: Mailbox, messageIds: string[]): Promise<number> {
    for (const ids of BatchGenerator.generate<string>(messageIds, 1000)) {
      await this.client.modifyMessages(
        {
          userId: mailbox.email_address,
          messageIds: ids,
          addLabelIds: ['TRASH'],
          removeLabelIds: ['INBOX'],
        },
        await mailbox.getCredential$()
      );
    }
    return messageIds.length;
  }

  async filterSubscriptions(
    operations: FilterOperation[],
    filterOperationId: string
  ): Promise<void> {
    const mailbox: Mailbox = await operations[0].subscription.mailbox();
    if (!mailbox) throw new Error('[Gmail] mailbox is missing');

    if (!isAuthenticatedMailbox(mailbox)) {
      throw new Error('[Gmail] credential is missing');
    }

    let res;
    try {
      res = await this.client.listFilters(mailbox.email_address, await mailbox.getCredential$());
    } catch (e) {
      for (const operation of operations) {
        try {
          AnalyticsService.trackListError(
            EventName.FILTER_LIST_FAILED,
            operation.subscription.email_address,
            e,
            { filterOperationId }
          );
          await operation.callbacks.onError(e);
        } catch (innerError) {
          console.error('Error handling operation:', innerError);
        }
      }
      return;
    }
    const filters = res.filter || [];

    const filterCommands = generateFilterCommands(filters, operations);

    if (!filterCommands?.length) {
      for (const operation of operations) {
        await operation.callbacks.onSuccess();
      }
      return;
    }

    const hasDuplicate = hasDuplicateFilterId(filterCommands);
    if (hasDuplicate) {
      AnalyticsService.track(EventName.DUPLICATE_FILTER_ID_FOUND);
    }

    for (const command of filterCommands) {
      try {
        try {
          await this.createFilter(
            {
              userId: mailbox.email_address,
              query: command.newFilterQuery,
              addLabelIds: ['TRASH'],
            },
            await mailbox.getCredential$(),
            filterOperationId
          );
        } catch (e) {
          for (const operation of command.operations) {
            try {
              AnalyticsService.trackListError(
                EventName.CREATE_FILTER_FAILED,
                operation.subscription.email_address,
                e,
                { filterOperationId }
              );
              await operation.callbacks.onError(e);
            } catch (innerError) {
              console.error('Error handling operation:', innerError);
            }
          }
          return;
        }

        if (command.existingFilter) {
          try {
            await this.deleteFilter(
              {
                userId: mailbox.email_address,
                filter: command.existingFilter,
              },
              await mailbox.getCredential$(),
              filterOperationId
            );
          } catch (e) {
            for (const operation of command.operations) {
              try {
                AnalyticsService.trackListError(
                  EventName.DELETE_FILTER_FAILED,
                  operation.subscription.email_address,
                  e,
                  { filterOperationId }
                );
                await operation.callbacks.onError(e);
              } catch (innerError) {
                console.error('Error handling operation:', innerError);
              }
            }
            return;
          }
        }

        for (const operation of command.operations) {
          await operation.callbacks.onSuccess();
        }
      } catch (e) {
        for (const operation of command.operations) {
          try {
            AnalyticsService.trackListError(
              EventName.FILTER_SUBSCRIPTION_FAILED,
              operation.subscription.email_address,
              e,
              { filterOperationId }
            );
            await operation.callbacks.onError(e);
          } catch (innerError) {
            console.error('Error handling operation:', innerError);
          }
        }
      }
    }
  }

  async createBouncebackFilter(mailbox: Mailbox) {
    if (GmailEmailProvider.hasBouncebackFilter) {
      // bouncebacks are already being filtered
      return;
    }

    if (!mailbox) throw new Error('[Gmail] mailbox is missing');

    if (!isAuthenticatedMailbox(mailbox)) {
      throw new Error('[Gmail] credential is missing');
    }

    const res = await this.client.listFilters(
      mailbox.email_address,
      await mailbox.getCredential$()
    );
    const filters = res.filter || [];

    const hasBouncebackFilter = filters.some((filter) => {
      if (!filter?.criteria?.query) return false;

      const query = filter?.criteria?.query;

      return query.includes(SMPT_SUBJECT);
    });

    if (hasBouncebackFilter) {
      GmailEmailProvider.hasBouncebackFilter = true;
      // bouncebacks are already being filtered
      return;
    }

    await this.createFilter(
      {
        userId: mailbox.email_address,
        query: `subject:(${SMPT_SUBJECT})`,
        addLabelIds: ['TRASH'],
      },
      await mailbox.getCredential$()
    );

    GmailEmailProvider.hasBouncebackFilter = true;
  }

  async sendMessage(mailbox: Mailbox, message: string) {
    if (!mailbox) throw new Error('[Gmail] mailbox is missing');

    await this.client.sendMessage(
      { userId: mailbox.email_address, message },
      await mailbox.getCredential$()
    );
  }

  async listSubscriptionMessages(subscription: Subscription, mailbox: Mailbox): Promise<string[]> {
    const strippedEmailAddress = this.stripPlusSignFromEmail(subscription.email_address);

    const messageIds = await this.listMessageIds(mailbox, `from: "${strippedEmailAddress}"`);
    return messageIds;
  }

  stripPlusSignFromEmail(email_address: string): string {
    const plusIndex = email_address.indexOf('+');
    const atIndex = email_address.indexOf('@');
    if (plusIndex !== -1 && atIndex !== -1) {
      const stripped = email_address.slice(0, plusIndex) + email_address.slice(atIndex);
      return stripped;
    }
    return email_address;
  }

  async *listMessages(mailbox: Mailbox, options: ListMessageOptions) {
    if (!mailbox) throw new Error('[Gmail] mailbox is missing');
    if (!isAuthenticatedMailbox(mailbox)) {
      throw new Error('[Gmail] credential is missing');
    }

    let pageToken;
    do {
      const listMessagesResponse: gapi.client.gmail.ListMessagesResponse =
        await this.client.listMessages(
          {
            userId: mailbox.email_address,
            options: {
              maxResults: 500,
              pageToken: pageToken,
              query: options.query,
            },
          },
          await mailbox.getCredential$()
        );

      pageToken = listMessagesResponse.nextPageToken;
      const messages: gapi.client.gmail.Message[] = listMessagesResponse.messages || [];
      const messageIds: string[] = messages.map((msg) => msg.id).filter((id) => !!id) as string[];
      const batches = BatchGenerator.generate<string>(messageIds, 20);

      for (const batch of batches) {
        try {
          const messages = await this.getMessages(batch, mailbox);
          for (const message of messages) {
            yield message;
          }
        } catch (error) {
          AnalyticsService.trackError(EventName.SYNC_ERROR, error);
          console.error(`[Gmail] get messages failed`);
          console.error(error);
        }
      }
    } while (pageToken);
  }

  private async listMessageIds(mailbox: Mailbox, query: string): Promise<string[]> {
    const messageIds: string[] = [];
    let pageToken;
    do {
      const response: gapi.client.gmail.ListMessagesResponse = await this.client.listMessages(
        {
          userId: mailbox.email_address,
          options: {
            maxResults: 500,
            pageToken: pageToken,
            query,
          },
        },
        await mailbox.getCredential$()
      );
      response.messages?.forEach((msg) => {
        if (msg.id) {
          messageIds.push(msg.id);
        }
      });
      pageToken = response.nextPageToken;
    } while (pageToken);

    return messageIds;
  }

  private async getMessages(
    messageIds: string[],
    mailbox: AuthenticatedMailbox
  ): Promise<Promise<EmailProviderMessage | null>[]> {
    const messages: gapi.client.gmail.Message[] = await this.client.getMessages(
      { userId: mailbox.email_address, messageIds },
      await mailbox.getCredential$()
    );

    return messages
      .filter((msg) => msg !== undefined)
      .map(async (msg) => {
        try {
          return await this.parseMessage(msg, mailbox.email_address);
        } catch (error) {
          AnalyticsService.trackError(EventName.PARSE_MESSAGE_FAILED, error);
          console.error(`[ParseMessage] failed with error ${error}`);
          return null;
        }
      });
  }

  private async parseMessage(msg: gapi.client.gmail.Message, userEmailAddress: string) {
    if (!msg) {
      throw new Error('Message is undefined');
    }
    if (!msg.id) {
      throw new Error('Message is missing an id!');
    }
    if (!msg.threadId) {
      throw new Error('Message is missing a threadId!');
    }
    if (!msg.payload) {
      throw new Error('Message is missing a payload!');
    }
    if (!msg.payload.headers) {
      throw new Error('Message is missing headers!');
    }

    const senderInfo: SenderInfo = await this.senderInfoAdapter.adapt(msg.payload.headers);
    const unsubscribeInfo = this.unsubscribeInfoAdapter.adapt(msg, senderInfo, userEmailAddress);

    const bytesInMB = 1000000;

    const sizeInMB = (msg.sizeEstimate || 0) / bytesInMB;

    const attachment = msg.payload?.parts?.find((part) => {
      return !!part.filename;
    });

    const receivedAt = Number(msg.internalDate);

    const emailProviderMessage: EmailProviderMessage = {
      id: msg.id,
      threadId: msg.threadId,
      senderEmail: senderInfo.senderEmail,
      senderName: senderInfo.senderName,
      receivedAt,
      unsubscribeInfo,
      sizeInMB,
      hasAttachments: !!attachment,
    };

    return emailProviderMessage;
  }

  private async createFilter(
    request: CreateFilterRequest,
    credential: Credential,
    filterOperationId?: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const operation = retry.operation({ retries: 5 });

      operation.attempt(async (currentAttempt) => {
        try {
          await this.client.createFilter(request, credential);

          // AnalyticsService.track(EventName.CREATE_FILTER_SUCCESS, { filterOperationId });

          resolve();
        } catch (error) {
          AnalyticsService.trackError(EventName.CREATE_FILTER_ATTEMPT_FAILED, error, {
            filterOperationId,
            retryAttempt: currentAttempt,
          });

          if (operation.retry(error as Error)) {
            return;
          }

          reject(operation.mainError());
        }
      });
    });
  }

  private async deleteFilter(
    request: DeleteFilterRequest,
    credential: Credential,
    filterOperationId?: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const operation = retry.operation({ retries: 5 });

      operation.attempt(async (currentAttempt) => {
        try {
          await this.client.deleteFilter(request, credential);

          // AnalyticsService.track(EventName.DELETE_FILTER_SUCCESS, { filterOperationId });

          resolve();
        } catch (error) {
          AnalyticsService.trackError(EventName.DELETE_FILTER_ATTEMPT_FAILED, error, {
            filterOperationId,
            retryAttempt: currentAttempt,
          });

          if (operation.retry(error as Error)) {
            return;
          }

          reject(operation.mainError());
        }
      });
    });
  }
}
