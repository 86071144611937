import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Box,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectPermissionsInfoDialog, setPermissionsInfoDialog } from '../features/ui/uiSlice';
import trimbox_gmail from '../assets/trimbox-gmail.svg';
import PermissionsInfoLine, { PermissionsInfoLineBadgeIcon } from './PermissionsInfoLine';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function PermissionsInfoPopup() {
  const permissionsInfoDialog = useAppSelector(selectPermissionsInfoDialog);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setPermissionsInfoDialog(false));
  };

  const { t } = useTranslation();

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={!!permissionsInfoDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
                padding: '0rem 2rem',
              }
            : {
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <Box
        mt={'2rem'}
        component='img'
        src={trimbox_gmail}
        sx={{
          height: aboveSmall ? 80 : 50,
          marginTop: aboveSmall ? '2rem' : '1rem',
        }}
      />
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
          paddingLeft: 0,
          paddingRight: 0,
          margin: '0 1rem',
        }}
      >
        {t('whyPermissions')}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <PermissionsInfoLine
          icon={PermissionsInfoLineBadgeIcon.READ}
          title={t('whyRead')}
        ></PermissionsInfoLine>
        <PermissionsInfoLine
          icon={PermissionsInfoLineBadgeIcon.SEND_AND_FILTER}
          title={t('whyCreateFilters')}
        ></PermissionsInfoLine>
        <PermissionsInfoLine
          icon={PermissionsInfoLineBadgeIcon.DELETE}
          title={t('whyDelete')}
        ></PermissionsInfoLine>
        <PermissionsInfoLine
          icon={PermissionsInfoLineBadgeIcon.PRIVATE}
          title={t('dataNeverSaved')}
        ></PermissionsInfoLine>
      </DialogContent>
      <Button
        variant='contained'
        size='large'
        onClick={() => {
          handleClose();
        }}
        sx={{
          margin: isExtraSmall ? '1rem' : aboveSmall ? '2rem' : '1rem 2rem 2rem 2rem',
          fontSize: isExtraSmall ? '0.8rem' : '1rem',
          fontWeight: 600,
          borderRadius: '1rem',
        }}
      >
        {t('gotIt')}
      </Button>
    </Dialog>
  );
}
export default PermissionsInfoPopup;
