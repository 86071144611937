import { useEffect } from 'react';
import TrimboxServerClient from '../../services/serverClient';
import { useSearchParams } from 'react-router-dom';

function getTrimboxQueryParams() {
    const trimboxQueryParams = document.cookie.split(';').map(cookie => cookie.trim()).find(cookie => cookie.startsWith('trimboxQueryParams='));
    if (!trimboxQueryParams) return null;

    return new URLSearchParams(trimboxQueryParams.split('trimboxQueryParams=').at(-1));
}

export function usePurchaseTracking() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const source = searchParams.get('source');
        if (source !== 'extension') return;

        const trimboxQueryParams = getTrimboxQueryParams();
        if (!trimboxQueryParams) {
            console.log('No Trimbox query params found');
            return;
        }

        const value = searchParams.get('value');
        const currency = searchParams.get('currency');
        const gclid = trimboxQueryParams.get('gclid');
        if (!value || !currency || !gclid) {
            console.log('Missing value, currency, or gclid');
            return;
        }

        const server = new TrimboxServerClient();
        server.trackOfflineConversion({
            type: 'ext_offline_purchase',
            value: parseFloat(value),
            currency,
            gclid,
            occuredAt: Date.now(),
        }).then(() => {
            console.log('Offline conversion tracked');
        }).catch(err => {
            console.error('Failed to track offline conversion', err);
        });
    }, []);
}
