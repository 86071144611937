import {
  PromoCoupon,
  UNINSTALL_PROMO_DEV_30,
  UNINSTALL_PROMO_DEV_30_LIFETIME,
  UNINSTALL_PROMO_PROD_70,
  UNINSTALL_PROMO_PROD_70_LIFETIME,
} from './PromoCoupon.types';

export function getUninstallDiscountCode(
  question1: QUESTION_1 | undefined,
  isDisableUninstallSurveyLifetimeDiscountTestActive: boolean
): PromoCoupon {
  if (shouldOfferLifetimeOffer(question1) && !isDisableUninstallSurveyLifetimeDiscountTestActive) {
    return process.env.REACT_APP_TRIMBOX_ENV !== 'production'
      ? UNINSTALL_PROMO_DEV_30_LIFETIME
      : UNINSTALL_PROMO_PROD_70_LIFETIME;
  }

  return process.env.REACT_APP_TRIMBOX_ENV !== 'production'
    ? UNINSTALL_PROMO_DEV_30
    : UNINSTALL_PROMO_PROD_70;
}

export enum QUESTION_1 {
  DID_NOT_WORK = 'DID_NOT_WORK',
  FINISHED = 'FINISHED_CLEANING',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  PERMISSIONS = 'PERMISSIONS',
  OTHER = 'OTHER',
}

type QuestionOneOption = {
  value: QUESTION_1;
  labelResource: string;
};

export const radioOptions: QuestionOneOption[] = [
  { value: QUESTION_1.DID_NOT_WORK, labelResource: 'uninstall_q1_a_v1' },
  { value: QUESTION_1.FINISHED, labelResource: 'uninstall_q1_a_v2' },
  { value: QUESTION_1.TOO_EXPENSIVE, labelResource: 'uninstall_q1_a_v3' },
  { value: QUESTION_1.PERMISSIONS, labelResource: 'uninstall_q1_a_v4' },
  { value: QUESTION_1.OTHER, labelResource: 'uninstall_q1_a_v5' },
];

type OptionToStringMapping = Record<QUESTION_1, string>;

export const question1ToQuestion2Mapping: OptionToStringMapping = {
  [QUESTION_1.DID_NOT_WORK]: 'uninstall_q2_v1',
  [QUESTION_1.FINISHED]: 'uninstall_q2_v2',
  [QUESTION_1.TOO_EXPENSIVE]: 'uninstall_q2_v3',
  [QUESTION_1.PERMISSIONS]: 'uninstall_q2_v4',
  [QUESTION_1.OTHER]: 'uninstall_q2_v5',
};

export const question1ToDiscountTitleMapping: OptionToStringMapping = {
  [QUESTION_1.DID_NOT_WORK]: 'uninstall_discount_v1',
  [QUESTION_1.FINISHED]: 'uninstall_discount_v2',
  [QUESTION_1.TOO_EXPENSIVE]: 'uninstall_discount_v3',
  [QUESTION_1.PERMISSIONS]: 'uninstall_discount_v4',
  [QUESTION_1.OTHER]: 'uninstall_discount_v5',
};

function shouldOfferLifetimeOffer(question1Response: QUESTION_1 | undefined) {
  if (question1Response == QUESTION_1.FINISHED || question1Response == QUESTION_1.TOO_EXPENSIVE) {
    return true;
  }
  return false;
}
