import { useCallback, useEffect, useState } from 'react';

import Mailbox, { getCurrentMailboxQuery } from '../../model/mailbox';
import { useLiveQuery } from 'dexie-react-hooks';
import { useGetMailboxData } from '../../app/useGetMailboxData';
import {
  userHasActiveLicenses,
  userHasIAPSubscription,
  userOwnsLicense,
} from '../../utilities/paywallHelper';

export type LicenseStatus = {
  hasActiveStripeLicense: boolean;
  ownsActiveStripeLicense: boolean;
  hasActiveIAPLicense: boolean;
};

export function useLoadMailboxAndLicenses(): {
  licenseStatus: LicenseStatus | undefined;
  hasAccessToken: boolean;
  mailbox: Mailbox | null | undefined;
  isMailboxQueryPending: boolean;
} {
  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const { hasAccessToken, isMailboxQueryPending } = useGetMailboxData(mailbox);

  const [licenseStatus, setLicenseStatus] = useState<LicenseStatus>();

  const fetchUserLicenses = useCallback(
    async (current_mailbox: Mailbox) => {
      const user = await current_mailbox.getUser$();

      const hasActiveStripeLicense = userHasActiveLicenses(user);
      let ownsActiveStripeLicense = false;
      let hasActiveIAPLicense = false;

      if (hasActiveStripeLicense) {
        if (await userOwnsLicense(user.id, await current_mailbox.getCredential$())) {
          ownsActiveStripeLicense = true;
        }
      } else {
        hasActiveIAPLicense = await userHasIAPSubscription(current_mailbox.email_address);
      }

      setLicenseStatus({
        hasActiveStripeLicense,
        ownsActiveStripeLicense,
        hasActiveIAPLicense,
      });
    },
    [mailbox]
  );

  useEffect(() => {
    if (isMailboxQueryPending) {
      return;
    }
    if (mailbox) {
      fetchUserLicenses(mailbox);
    }
  }, [mailbox]);

  return { licenseStatus, hasAccessToken, mailbox, isMailboxQueryPending };
}
