import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { getUninstallDiscountCode } from '../../app/useGetUninstallFeedback';
import ConfettiFirework from '../../components/ConfettiFirework';
import { UNINSTALL_SURVEY_QUESTION_1, UNINSTALL_SURVEY_ACTIVE_STEP } from '../../constants';
import ABTest from '../../model/abTest';
import { getCurrentMailboxQuery } from '../../model/mailbox';
import {
  getTestValueQuery,
  DISABLE_UNINSTALL_SURVEY_LIFETIME_DISCOUNT,
  A_GROUP,
} from '../../services/abTestingService';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import theme, { WHITE_HEX, PRIMARY_COLOR_HEX, PURPLE_HEX } from '../../theme';
import TopBar from '../PricingPage/TopBar';
import StyledStepper from './StyledStepper';
import DiscountIcon from '@mui/icons-material/Discount';

export type UninstallPageProps = {
  userEmailFromURL?: string;
};

enum QUESTION_1 {
  DID_NOT_WORK = 'DID_NOT_WORK',
  FINISHED = 'FINISHED_CLEANING',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  PERMISSIONS = 'PERMISSIONS',
  OTHER = 'OTHER',
}

type QuestionOneOption = {
  value: QUESTION_1;
  labelResource: string;
};

const radioOptions: QuestionOneOption[] = [
  { value: QUESTION_1.DID_NOT_WORK, labelResource: 'uninstall_q1_a_v1' },
  { value: QUESTION_1.FINISHED, labelResource: 'uninstall_q1_a_v2' },
  { value: QUESTION_1.TOO_EXPENSIVE, labelResource: 'uninstall_q1_a_v3' },
  { value: QUESTION_1.PERMISSIONS, labelResource: 'uninstall_q1_a_v4' },
  { value: QUESTION_1.OTHER, labelResource: 'uninstall_q1_a_v5' },
];

type OptionToStringMapping = Record<QUESTION_1, string>;

const question1ToQuestion2Mapping: OptionToStringMapping = {
  [QUESTION_1.DID_NOT_WORK]: 'uninstall_q2_v1',
  [QUESTION_1.FINISHED]: 'uninstall_q2_v2',
  [QUESTION_1.TOO_EXPENSIVE]: 'uninstall_q2_v3',
  [QUESTION_1.PERMISSIONS]: 'uninstall_q2_v4',
  [QUESTION_1.OTHER]: 'uninstall_q2_v5',
};

const question1ToDiscountTitleMapping: OptionToStringMapping = {
  [QUESTION_1.DID_NOT_WORK]: 'uninstall_discount_v1',
  [QUESTION_1.FINISHED]: 'uninstall_discount_v2',
  [QUESTION_1.TOO_EXPENSIVE]: 'uninstall_discount_v3',
  [QUESTION_1.PERMISSIONS]: 'uninstall_discount_v4',
  [QUESTION_1.OTHER]: 'uninstall_discount_v5',
};

export default function UninstallPage(props: UninstallPageProps) {
  const { userEmailFromURL } = props;

  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const userEmail = mailbox?.email_address || userEmailFromURL;

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const [activeStep, setActiveStep] = React.useState(0);

  const [question1, setQuestion1] = useState<QUESTION_1>();
  const [question2, setQuestion2] = useState<string>();

  const disableUninstallSurveyLifetimeDiscountTest: ABTest | undefined = useLiveQuery(() =>
    getTestValueQuery(DISABLE_UNINSTALL_SURVEY_LIFETIME_DISCOUNT)
  );
  const isDisableUninstallSurveyLifetimeDiscountTestActive =
    disableUninstallSurveyLifetimeDiscountTest?.test_value === A_GROUP;

  useEffect(() => {
    if (!question1) {
      return;
    }

    localStorage.setItem(UNINSTALL_SURVEY_QUESTION_1, question1);
  }, [question1]);

  useEffect(() => {
    if (!activeStep) {
      return;
    }

    localStorage.setItem(UNINSTALL_SURVEY_ACTIVE_STEP, activeStep.toString());
  }, [activeStep]);

  useEffect(() => {
    AnalyticsService.track(EventName.UNINSTALL_PAGE_VIEW);

    const stored_question_1 = localStorage.getItem(UNINSTALL_SURVEY_QUESTION_1) as QUESTION_1;
    if (stored_question_1) {
      setQuestion1(stored_question_1);
    }

    const stored_step_string = localStorage.getItem(UNINSTALL_SURVEY_ACTIVE_STEP);
    if (stored_step_string) {
      const stored_step_num: number = parseInt(stored_step_string);
      setActiveStep(stored_step_num);
    }
  }, []);

  const handleRadioChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined = (event) => {
    setQuestion1(event.target.value as QUESTION_1);
  };

  const handleMultiLineTextChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    setQuestion2(event.target.value);
  };

  const navigate = useNavigate();

  const promoCode = getUninstallDiscountCode(
    question1,
    isDisableUninstallSurveyLifetimeDiscountTestActive
  );
  const offerLifetime = promoCode.withLifetime;

  const claimDiscount = () => {
    AnalyticsService.track(EventName.UNINSTALL_PAGE_CLAIM_DISCOUNT);

    let pricingLink = `/pricing?promo=${promoCode.id}&product=extension`;
    if (offerLifetime) {
      pricingLink += '&lifetime=true';
    }

    navigate(pricingLink);
  };

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: WHITE_HEX,
      }}
    >
      <TopBar userEmail={userEmail} hideSignIn={true} />
      <Container
        disableGutters
        maxWidth='md'
        component='main'
        sx={{
          pt: aboveSmall ? 8 : 2,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          component='h1'
          variant='h2'
          fontWeight={800}
          align='center'
          color='text.primary'
          gutterBottom
          mb={aboveSmall ? '2rem' : '1rem'}
          maxWidth={aboveMedium ? 'unset' : '80%'}
          fontSize={aboveMedium ? '4rem' : aboveSmall ? '2.5rem' : '2rem'}
        >
          {t('uninstallPageTitle')}
        </Typography>
        <Typography
          variant='h5'
          align='center'
          color='text.primary'
          component='p'
          gutterBottom
          fontSize={aboveMedium ? '1.4rem' : '1.0rem'}
        >
          {t('uninstallPageSubtitle')}
        </Typography>
        <Box sx={{ mt: '2rem' }}>
          <StyledStepper activeStep={activeStep} />
        </Box>
        <Stack
          sx={{
            display: 'flex',
            gap: aboveMedium ? 6 : 2,
            justifyContent: 'center',
            pt: aboveSmall ? 4 : 2,
            pb: aboveSmall ? 6 : 4,
            mt: aboveSmall ? 4 : 0,
            alignItems: 'center',
            px: 2,
          }}
        >
          {activeStep == 0 && (
            <>
              <FormControl component='fieldset'>
                <Typography
                  variant='h5'
                  color='text.primary'
                  fontWeight={700}
                  component='p'
                  fontSize={aboveMedium ? '1.4rem' : '1.0rem'}
                  mb={'1rem'}
                >
                  {t('uninstall_q1')}
                </Typography>
                <RadioGroup
                  aria-label='options'
                  name='row-radio-buttons-group'
                  value={question1}
                  onChange={handleRadioChange}
                >
                  {radioOptions.map((radioOption, i) => (
                    <FormControlLabel
                      key={i}
                      value={radioOption.value}
                      label={t(radioOption.labelResource)}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                onClick={() => {
                  setActiveStep(1);
                }}
                size='large'
                variant='contained'
                color='primary'
                disabled={!question1}
                sx={{
                  fontSize: aboveSmall ? '1.25rem' : '1rem',
                }}
              >
                {t('next')}
              </Button>
            </>
          )}
          {activeStep == 1 && (
            <>
              <FormControl component='fieldset'>
                {question1 == QUESTION_1.PERMISSIONS && (
                  <>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      component='p'
                      fontSize={aboveMedium ? '1rem' : '1.0rem'}
                      mb={'1rem'}
                    >
                      {t('uninstall_permissions_info_header')}
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      component='p'
                      fontSize={aboveMedium ? '1rem' : '1.0rem'}
                      mb={'0.5rem'}
                    >
                      {t('uninstall_permissions_info_1')}
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      component='p'
                      fontSize={aboveMedium ? '1rem' : '1.0rem'}
                      mb={'0.5rem'}
                    >
                      {t('uninstall_permissions_info_2')}
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      component='p'
                      fontSize={aboveMedium ? '1rem' : '1.0rem'}
                      mb={'0.5rem'}
                    >
                      {t('uninstall_permissions_info_3')}
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      component='p'
                      fontSize={aboveMedium ? '1rem' : '1.0rem'}
                      mb={'1rem'}
                    >
                      {t('uninstall_permissions_info_4')}{' '}
                      <Link
                        href='https://www.trimbox.io/email-faq'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('learnMore')}
                      </Link>
                    </Typography>
                  </>
                )}
                <Typography
                  variant='h5'
                  align='center'
                  color='text.primary'
                  fontWeight={700}
                  component='p'
                  fontSize={aboveMedium ? '1.4rem' : '1.0rem'}
                  mb={'1rem'}
                >
                  {question1 && t(question1ToQuestion2Mapping[question1])}
                </Typography>
                <TextField
                  label={t('typeYourAnswerHere')}
                  multiline
                  rows={4}
                  value={question2}
                  onChange={handleMultiLineTextChange}
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
              </FormControl>
              <Button
                onClick={() => {
                  setActiveStep(2);
                  AnalyticsService.track(EventName.UNINSTALL_PAGE_UNLOCK_DISCOUNT, {
                    question1: question1,
                    question2: question2,
                  });
                }}
                size='large'
                variant='contained'
                color='primary'
                disabled={!question2}
                sx={{
                  fontSize: aboveSmall ? '1.25rem' : '1rem',
                }}
              >
                {t('next')}
              </Button>
            </>
          )}
          {activeStep == 2 && (
            <>
              <Typography
                variant='body2'
                align='center'
                color='text.primary'
                component='p'
                fontSize={aboveMedium ? '1.2rem' : '1.0rem'}
              >
                {question1 && t(question1ToDiscountTitleMapping[question1])}
              </Typography>
              <Chip
                icon={<DiscountIcon color='white' fontSize='small' />}
                onClick={() => {
                  claimDiscount();
                }}
                sx={{
                  background: `linear-gradient(120deg,${PRIMARY_COLOR_HEX} 13.4%,${PURPLE_HEX} 86.6%)`,
                  color: WHITE_HEX,
                  fontWeight: 600,
                  borderRadius: 8,
                  mt: 2,
                  mb: 4,
                  padding: aboveSmall || !offerLifetime ? 4 : 3,
                  fontSize: aboveSmall ? '1.6rem' : offerLifetime ? '1rem' : '1.4rem',
                }}
                label={t(offerLifetime ? 'xOffLifetime' : 'xOffAllPlans', {
                  percentage: promoCode.percentOff * 100,
                })}
                className={'animate__animated animate__tada'}
              />
              <ConfettiFirework shouldFire={true}></ConfettiFirework>
              <Button
                onClick={() => {
                  claimDiscount();
                }}
                size='large'
                variant='contained'
                color='primary'
                sx={{
                  fontSize: aboveSmall ? '1.25rem' : '1rem',
                }}
              >
                {t('claimYourOffer')}
              </Button>
            </>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
