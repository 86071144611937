export default class BatchGenerator {
  static generate<T>(elements: T[], batchSize = 50): T[][] {
    const batches: T[][] = [];
    let left = 0;
    let right = batchSize;
    while (left < elements.length) {
      const chunk = elements.slice(left, right);
      batches.push(chunk);
      left = right;
      right = right + batchSize;
    }
    return batches;
  }
}
