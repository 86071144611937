import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { EventName, ErrorDict } from './analyticsProvider';

const EVENT_NAME_TO_GA4_EVENT_NAME: Record<string, string> = {
  [EventName.LOGIN_SUCCESSFUL]: 'login',
  [EventName.PLANS_CONFIRM]: 'begin_checkout',
};

export default class GtmAnalyticsProvider implements AnalyticsProvider {
  identify(params: IdentifyParams): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.dataLayer.push({
      user_id: params.email_address,
    });
  }

  alias(id: string): void {
    return;
  }

  track(name: EventName, props?: Dict | undefined): void {
    const ga4Event = EVENT_NAME_TO_GA4_EVENT_NAME[name];
    if (!ga4Event) return;

    switch (ga4Event) {
      case 'login':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.dataLayer.push({ event: ga4Event });
        break;
      case 'begin_checkout':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.dataLayer.push({ event: ga4Event });
        break;
      default:
        break;
    }
  }

  trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        value: checkoutParams.value,
        currency: checkoutParams.currency,
        transaction_id: checkoutParams.transactionId,
      },
    });
  }

  trackError(name: EventName, errorProps: ErrorDict): void {
    return;
  }
}
