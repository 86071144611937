import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Chip, Stack, useMediaQuery } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import { t } from 'i18next';
import { useSnackbar, OptionsObject } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { useGetCurrentPlanInterval } from '../../app/useGetCurrentPlanInterval';
import { useGetOffering } from '../../app/useGetOffering';
import { useLoadPricingPage } from '../../app/useLoadPricingPage';
import { TRIMBOX_REDIRECT_KEY } from '../../constants';
import { PlanTypePresentable, CurrencyConfig } from '../../features/offering/offeringSlice.types';
import { getCurrentMailboxQuery } from '../../model/mailbox';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { SCOPE_TYPE } from '../../services/credentialFactory';
import MailboxConnector from '../../services/mailboxConnector';
import theme, { WHITE_HEX, PRIMARY_COLOR_HEX, PURPLE_HEX, LIGHT_GRAY_HEX } from '../../theme';
import { openCheckoutPage } from '../../utilities/paywallHelper';
import PaywallBullet from './PaywallBullet';
import PlanOption from './PlanOption';
import TopBar from './TopBar';
import DiscountIcon from '@mui/icons-material/Discount';

export type PricingPageProps = {
  userEmailFromURL?: string;
};

const BULLETS: string[] = [
  'unlimitedUnsubscribes',
  'unlimitedBulkDeletes',
  // 'neverSellData',
  'forAllInboxes',
];

export default function PricingPage(props: PricingPageProps) {
  const { userEmailFromURL } = props;

  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const userEmail = mailbox?.email_address || userEmailFromURL;

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { discountPercentage, includeLifetime, promoCode, returnUrl, source } =
    useLoadPricingPage(mailbox);

  const { plans, currency } = useGetOffering({
    includeFree: aboveMedium,
    sortByAscending: aboveMedium,
    mailbox,
  });

  const { currentPlanIntervals } = useGetCurrentPlanInterval(mailbox);

  const { enqueueSnackbar } = useSnackbar();

  function showErrorToast(e: unknown) {
    const snackbarOptions: OptionsObject = {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: false,
    };

    const errorMessage = t('failedToSignIn');

    enqueueSnackbar(errorMessage, snackbarOptions);
  }

  const onPlanCheckout = useCallback(
    async (plan: PlanTypePresentable, curr: CurrencyConfig) => {
      if (plan.amountWithDiscount == 0) {
        navigate('/');
        return;
      }

      AnalyticsService.track(EventName.PRICING_PAGE_PLAN_SELECT, {
        planInterval: plan.interval,
        planAmount: plan.amountWithoutDiscount,
        includeFree: aboveMedium,
        discountPercentage,
        includeLifetime,
        hasEmail: !!userEmail,
      });

      if (!userEmail) {
        AnalyticsService.track(EventName.CLICK_LOGIN);

        AnalyticsService.track(EventName.LOGIN_START);

        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const g = google;

          localStorage.setItem(TRIMBOX_REDIRECT_KEY, window.location.href);

          MailboxConnector.create(g).startConnection(SCOPE_TYPE.USER_INFO);
        } catch (e: unknown) {
          AnalyticsService.trackError(EventName.LOGIN_FAILED, e);

          showErrorToast(e);
        }
        return;
      }
      try {
        await openCheckoutPage({
          mailboxId: userEmail,
          selectedPlan: plan.planType,
          currency: curr,
          promoCode: promoCode?.id,
          returnUrl,
          source,
        });
      } catch (e) {
        enqueueSnackbar(t('somethingWentWrong'), {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          persist: false,
        });
        AnalyticsService.trackError(EventName.CHECKOUT_FAILED, e);
      }
    },
    [userEmail, discountPercentage, includeLifetime, promoCode, returnUrl, source, aboveMedium]
  );

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: WHITE_HEX,
      }}
    >
      <TopBar userEmail={userEmail} />
      <Container
        disableGutters
        maxWidth='md'
        component='main'
        sx={{
          pt: aboveSmall ? 8 : 2,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!!discountPercentage && (
          <Chip
            icon={<DiscountIcon color='white' fontSize='small' />}
            sx={{
              background: `linear-gradient(120deg,${PRIMARY_COLOR_HEX} 13.4%,${PURPLE_HEX} 86.6%)`,
              color: WHITE_HEX,
              fontWeight: 600,
              marginBottom: aboveSmall ? 5 : 4,
              marginTop: aboveSmall ? '-4rem' : 0,
              padding: 2,
              fontSize: '1rem',
            }}
            label={t('xOffAllPlans', { percentage: discountPercentage * 100 })}
          />
        )}
        <Typography
          component='h1'
          variant='h2'
          fontWeight={800}
          align='center'
          color='text.primary'
          gutterBottom
          mb={aboveSmall ? '2rem' : '1rem'}
          maxWidth={aboveMedium ? 'unset' : '80%'}
          fontSize={aboveMedium ? '4rem' : aboveSmall ? '2.5rem' : '2rem'}
        >
          {t('pricingPageTitle')}
        </Typography>
        <Typography
          variant='h5'
          align='center'
          color='text.primary'
          component='p'
          gutterBottom
          fontSize={aboveMedium ? '1.6rem' : '1.2rem'}
        >
          {t('pricingPageSubtitle')}
        </Typography>
      </Container>
      <Box
        sx={{
          display: 'flex',
          gap: aboveMedium ? 6 : 2,
          flexWrap: 'wrap',
          justifyContent: 'center',
          pt: aboveSmall ? 4 : 2,
          pb: aboveSmall ? 6 : 4,
          px: 2,
          borderBottom: aboveSmall ? `1px ${LIGHT_GRAY_HEX} solid` : 'unset',
          mb: aboveSmall ? 4 : 0,
        }}
      >
        {BULLETS.map((bullet) => (
          <PaywallBullet key={bullet} text={t(bullet)} />
        ))}
      </Box>
      <Container maxWidth='lg' component='main' sx={{ pb: 2 }}>
        {plans && currency ? (
          <Stack
            spacing={5}
            justifyContent='center'
            direction={aboveMedium ? 'row' : 'column'}
            alignItems={aboveMedium ? 'start' : 'center'}
          >
            {plans?.map((plan) => (
              <PlanOption
                key={plan.interval}
                plan={plan}
                currentPlanIntervals={currentPlanIntervals}
                onCheckoutClick={async () => {
                  await onPlanCheckout(plan, currency);
                }}
                currency={currency}
              />
            ))}
          </Stack>
        ) : null}
      </Container>
    </Stack>
  );
}
