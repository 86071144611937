import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Stack,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsCheckbox, { SettingsCheckboxProps } from './SettingsCheckbox';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  settingsCheckboxes: SettingsCheckboxProps[];
  confirmText: string;
  handleConfirm: () => void;
  handleClose: () => void;
};

function SettingsPopup(props: Props) {
  const {
    isOpen,
    title,
    description,
    settingsCheckboxes,
    confirmText,
    handleConfirm,
    handleClose,
  } = props;

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const buttonStyle = {
    fontSize: isExtraSmall ? '0.85rem' : '1rem',
    fontWeight: 600,
    borderRadius: '1rem',
    ...(aboveSmall ? { padding: '0.5rem 4rem' } : {}),
    flex: 1,
  };

  return (
    <Dialog
      open={!!isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          ...(aboveSmall
            ? {
                maxWidth: '450px',
                borderRadius: '1rem',
              }
            : {
                maxWidth: 'none',
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          color='common.black'
          fontSize={isExtraSmall ? '0.85rem' : aboveSmall ? '1.15rem' : '1rem'}
          textAlign='center'
          marginBottom='1.5rem'
        >
          {description}
        </Typography>
        {settingsCheckboxes.map((settingsCheckboxProps, index) => (
          <SettingsCheckbox key={`settings-${index}`} {...settingsCheckboxProps} />
        ))}
        <Stack
          flexDirection='row'
          marginTop='1rem'
          marginBottom={aboveSmall ? '0rem' : '1rem'}
          gap='1rem'
        >
          <Button
            variant='contained'
            size='large'
            color='lightGray'
            onClick={() => {
              handleClose();
            }}
            sx={buttonStyle}
          >
            {t('cancel')}
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={async () => {
              await handleConfirm();
            }}
            sx={buttonStyle}
          >
            {confirmText}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default SettingsPopup;
