import Mailbox from '../model/mailbox';
import CredentialFactory, { SCOPE_TYPE } from '../services/credentialFactory';

export function useGetMailboxData(mailbox: Mailbox | undefined | null) {
  const mailboxId = mailbox?.email_address;
  const mailboxCredential = mailbox?.credential;

  const hasMailbox = !!mailboxId;
  const isMailboxQueryPending = mailbox === undefined;
  const hasAccessToken = hasMailbox && !!mailboxCredential?.accessToken;
  const hasTokenAndUserInfoScopes =
    hasAccessToken && CredentialFactory.hasFullScopes(mailboxCredential, SCOPE_TYPE.USER_INFO);
  const hasTokenAndFullScopes =
    hasTokenAndUserInfoScopes &&
    CredentialFactory.hasFullScopes(mailboxCredential, SCOPE_TYPE.GMAIL_ACCESS);

  return {
    mailboxId,
    isMailboxQueryPending,
    hasTokenAndFullScopes,
    hasTokenAndUserInfoScopes,
    hasAccessToken,
    hasMailbox,
  };
}
