import { useEffect } from 'react';
import { fetchOffering } from '../features/offering/offeringSlice';
import { useAppDispatch } from './hooks';

export function useFetchOffering() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOffering());
  });
}
