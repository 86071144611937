import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Stack, useMediaQuery } from '@mui/material';
import theme, {
  GREEN_HEX,
  LIGHT_GRAY_HEX,
  PRIMARY_COLOR_HEX,
  PURPLE_HEX,
  WHITE_HEX,
} from '../../theme';
import { t } from 'i18next';
import { formatPrice } from '../../features/offering/currencyHelper';
import StarIcon from '@mui/icons-material/Star';
import PlanBullet from './PlanBullet';
import {
  CurrencyConfig,
  PlanInterval,
  PlanTypePresentable,
} from '../../features/offering/offeringSlice.types';

export type PlanOptionprops = {
  plan: PlanTypePresentable;
  currentPlanIntervals: PlanInterval[];
  currency: CurrencyConfig;
  onCheckoutClick: (plan: PlanTypePresentable, currency: CurrencyConfig) => Promise<void>;
};

export default function PlanOption(props: PlanOptionprops) {
  const { plan, currentPlanIntervals, currency } = props;

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const maximizedPriceString = formatPrice(plan.maximizedPrice, currency);

  const presentedPriceString = formatPrice(plan.presentedPrice, currency);

  const isDiscounted = maximizedPriceString != presentedPriceString;

  const userHasPlan = currentPlanIntervals.includes(plan.interval);

  let spotlight;
  if (plan.interval === 'lifetime') {
    spotlight = t('noSubscription');
  } else if (plan.presentedSavings) {
    spotlight = t('xPercentOff', { percent: plan.presentedSavings });
  }

  return (
    <Grid
      item
      key={plan.title}
      xs={12}
      sm={6}
      md={4}
      flex={1}
      sx={{
        background: spotlight
          ? `linear-gradient(120deg,${PRIMARY_COLOR_HEX} 13.4%,${PURPLE_HEX} 86.6%)`
          : 'unset',
        padding: '4px',
        borderRadius: 4,
        height: 'fit-content',
        maxWidth: aboveMedium ? '300px' : aboveSmall ? '400px' : 'unset',
        width: '100%',
      }}
    >
      <Stack>
        {(spotlight || aboveMedium) && (
          <Stack
            direction={'row'}
            sx={{
              alignItems: 'center',
              gap: '0.5rem',
              alignSelf: 'center',
              mt: 1,
              mb: 1.5,
            }}
          >
            {plan.isPreferredOption && <StarIcon color='yellow' sx={{ fontSize: '1.6rem' }} />}
            <Typography
              variant='h5'
              align='center'
              component='p'
              fontSize={'1.2rem'}
              fontWeight={800}
              sx={{ color: WHITE_HEX }}
            >
              {spotlight || '\u00A0'}
            </Typography>
          </Stack>
        )}
        <Card
          sx={{
            border: spotlight ? 'none' : `2px ${LIGHT_GRAY_HEX} solid`,
            boxShadow: 'none',
            borderRadius: 4,
            padding: '0rem 0.5rem',
          }}
        >
          <CardHeader
            title={t(plan.title)}
            titleTypographyProps={{
              align: 'left',
              fontSize: aboveSmall ? '1.2rem' : '1rem',
              fontWeight: 700,
            }}
            subheaderTypographyProps={{
              align: 'center',
            }}
          />
          <CardContent sx={{ ...(aboveSmall ? {} : { pt: 0 }) }}>
            <Stack
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                mb: 1,
                mt: 0,
              }}
            >
              <Typography
                component='h2'
                variant='h3'
                color='text.primary'
                fontSize={aboveSmall ? '1.2rem' : '1rem'}
                sx={isDiscounted ? { textDecorationLine: 'line-through' } : {}}
                mb={1}
              >
                {isDiscounted ? maximizedPriceString : '\u00A0'}
              </Typography>
              <Typography
                component='h2'
                variant='h3'
                color={isDiscounted ? GREEN_HEX : 'text.primary'}
                fontSize={aboveSmall ? '2.4rem' : '1.6rem'}
                fontWeight={800}
              >
                {presentedPriceString}
              </Typography>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <Typography variant='body1' component='p' color='text.primary'>
                {t(plan.billingPeriod)}
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant={!spotlight ? 'contained' : 'contained'}
              size='large'
              sx={{ fontSize: '1rem', fontWeight: 700 }}
              disabled={userHasPlan}
              onClick={async () => {
                await props.onCheckoutClick(plan, currency);
              }}
            >
              {t(
                plan.amountWithDiscount == 0
                  ? 'tryForFree'
                  : userHasPlan
                  ? 'yourCurrentPlan'
                  : 'upgradeYourInbox'
              )}
            </Button>
          </CardActions>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                mb: 2,
              }}
            >
              <Typography
                variant='h6'
                component='p'
                color='text.primary'
                fontSize={'1rem'}
                fontWeight={500}
              >
                {t(plan.subtitle)}
              </Typography>
            </Box>
            <Stack>
              {plan.bullets?.map((bullet) => (
                <PlanBullet key={bullet} text={t(bullet)} />
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Grid>
  );
}
