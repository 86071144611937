import { useEffect } from 'react';

import { getCurrentMailbox } from '../model/mailbox';
import AnalyticsService from '../services/analytics/analyticsService';
import { db } from '../db/db';
import CheckoutSession from '../model/checkoutSession';
import { EventName } from '../services/analytics/providers/analyticsProvider';
import { useSearchParams } from 'react-router-dom';
import { CheckoutParams } from '../services/analytics/analyticsService.types';

export function useCheckoutConversionLogging() {
  useEffect(() => {
    db.checkoutSessions
      .toArray()
      .then((checkoutSessions) => {
        if (!checkoutSessions || checkoutSessions.length === 0) {
          return null;
        }
        return checkoutSessions[0];
      })
      .then(async (checkoutSession: CheckoutSession | null) => {
        if (!checkoutSession) {
          return;
        }

        const mailbox = await getCurrentMailbox();
        if (!mailbox) {
          throw new Error('Mailbox not found!');
        }

        AnalyticsService.logCheckoutConversion(mailbox, checkoutSession.session_id)
          .then(() => {
            db.checkoutSessions.delete(checkoutSession.session_id);
          })
          .catch((e) => {
            AnalyticsService.trackError(EventName.LOG_CHECKOUT_SESSION_ERROR, e);
          });
      });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams) {
      return;
    }

    const valueString = searchParams.get('value');
    if (!valueString) {
      return;
    }
    const value = Number.parseFloat(valueString);

    const currency = searchParams.get('currency');
    if (!currency) {
      return;
    }

    const transactionId = searchParams.get('transactionId');
    if (!transactionId) {
      return;
    }

    const checkoutParams: CheckoutParams = {
      value: value ?? 39.99,
      currency: currency ?? 'USD',
      transactionId,
    };

    searchParams.delete('value');
    searchParams.delete('currency');
    searchParams.delete('transactionId');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag('event', 'conversion', {
      send_to: 'AW-434264734/aG3KCIPZ4csYEJ61ic8B',
      value: checkoutParams.value,
      currency: checkoutParams.currency || 'USD',
      transaction_id: transactionId,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag('event', 'ext_purchase', {
      send_to: 'G-WDZ4B7N25R',
      currency: checkoutParams.currency || 'USD',
      value: checkoutParams.value ?? 39.99,
    });

    AnalyticsService.trackCheckout(checkoutParams);
  }, [searchParams]);
}
