import React from 'react';

import { useAppDispatch } from '../app/hooks';
import { useCallback, useEffect } from 'react';
import Mailbox from '../model/mailbox';
import { syncMailbox } from '../features/user/userSlice';

export function useMailboxSync(
  mailbox: Mailbox | undefined | null,
  hasTokenAndFullScopes: boolean,
  hasAccessToken: boolean
) {
  const dispatch = useAppDispatch();

  const startMailboxSync = useCallback(
    async (mailbox: Mailbox) => {
      if (await mailbox.isLoggedIn()) {
        // If logged in, start syncing
        dispatch(syncMailbox(mailbox));
      } else {
        await mailbox.logout();
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (hasTokenAndFullScopes) {
      startMailboxSync(mailbox!);
    }
  }, [hasTokenAndFullScopes, hasAccessToken, startMailboxSync]);
}
