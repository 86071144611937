import Dexie from 'dexie';
import { MissingScopesError, WrongUserSelectedError } from '../services/mailboxConnector.errors';

export function getErrorMessage(e: unknown) {
  let errorMessage: string;
  if (typeof e === 'string') {
    errorMessage = e;
  } else if (e instanceof Dexie.AbortError) {
    errorMessage = e?.inner?.message
      ? `Transaction aborted due to error ${e?.inner?.message}`
      : e.message;
  } else if (e instanceof Dexie.DatabaseClosedError) {
    errorMessage = e?.inner?.message
      ? `Database closed due to error ${e?.inner?.message}`
      : e.message;
  } else if (e instanceof MissingScopesError) {
    errorMessage = 'User is missing scopes';
  } else if (e instanceof WrongUserSelectedError) {
    errorMessage = 'Wrong user is selected';
  } else if (e instanceof Error) {
    errorMessage = e.message;
  } else if (e) {
    errorMessage = JSON.stringify(e);
  } else {
    errorMessage = 'Unknown error';
  }
  return errorMessage;
}

export function getErrorStack(e: unknown) {
  let errorMessage: string;
  if (typeof e === 'string') {
    errorMessage = e;
  } else if (e instanceof Error) {
    errorMessage = e.stack || e.message;
  } else if (e) {
    errorMessage = JSON.stringify(e);
  } else {
    errorMessage = 'Unknown error';
  }
  return errorMessage;
}
