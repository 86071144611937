import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { ErrorDict, EventName } from './analyticsProvider';

export default class TrackJsAnalyticsProvider implements AnalyticsProvider {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public identify(params: IdentifyParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (!window.TrackJS || !TrackJS) {
      return;
    }

    if (!params.user_id) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    TrackJS.configure({ userId: params.user_id });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public alias(id: string) {
    // Not needed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public track(name: EventName, props?: Dict) {
    // Not needed
  }

  public trackCheckout(checkoutParams: CheckoutParams) {
    // Not needed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public trackError(name: EventName, errorProps: ErrorDict) {
    // Not needed
  }
}
