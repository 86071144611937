import EmailProvider from '../services/emailProvider';
import GmailEmailProvider from '../services/gmail/gmailEmailProvider';
import BulkDeleteOperationQueue, {
  BulkDeleteBundleOperation,
  BulkDeleteOperationCallbacks,
} from '../services/bulkDeleteOperationQueue';
import Bundle from '../model/emailBundle';

export default class DeleteBundleCommand {
  private bundle: Bundle;
  private bulkDeleteQueue: BulkDeleteOperationQueue;

  constructor(bundle: Bundle, provider: EmailProvider) {
    this.bundle = bundle;
    this.bulkDeleteQueue = BulkDeleteOperationQueue.create(provider);
  }

  static create(bundle: Bundle) {
    return new DeleteBundleCommand(bundle, GmailEmailProvider.create());
  }

  run(callbacks: BulkDeleteOperationCallbacks) {
    const operation: BulkDeleteBundleOperation = {
      bundle: this.bundle,
      callbacks,
    };
    this.bulkDeleteQueue.queue(operation);
  }
}
