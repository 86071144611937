import Mailbox from '../model/mailbox';
import Credential from '../model/credential';
import CredentialFactory, { SCOPE_TYPE } from './credentialFactory';
import TrimboxServerClient from './serverClient';
import { MissingScopesError, WrongUserSelectedError } from './mailboxConnector.errors';

export const MISSING_SCOPES_ERROR_CAUSE = 'Missing required scopes!';
export const WRONG_USER_SELECTED_ERROR_CAUSE = 'Wrong user selected!';

export default class MailboxConnector {
  private mailbox: Mailbox | undefined;
  private credentialFactory: CredentialFactory;
  private serverClient: TrimboxServerClient = new TrimboxServerClient();

  constructor(credentialFactory: CredentialFactory, mailbox?: Mailbox) {
    this.mailbox = mailbox;
    this.credentialFactory = credentialFactory;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(google: any, mailbox?: Mailbox): MailboxConnector {
    return new MailboxConnector(new CredentialFactory(google), mailbox);
  }

  public async startConnection(scopeType: SCOPE_TYPE, emailHint?: string) {
    await this.credentialFactory.getAuthCode(scopeType, emailHint);
  }

  public async finishConnection(authCode: string, scopeType: SCOPE_TYPE): Promise<Mailbox> {
    const credential: Credential = await this.credentialFactory.getCredentials(authCode);

    // First, make sure the user gave us basic login access. If not, we should not create a mailbox
    this.assertScopesNotMissing(credential, SCOPE_TYPE.USER_INFO);

    const userInfo = await this.getUserInfo(credential);

    // Make sure the user signed in the correct account. If not, we should not create a mailbox
    this.assertCorrectUserSelected(userInfo, scopeType);

    // Create and save the mailbox
    const mailbox: Mailbox = await this.createOrUpdateMailbox(credential, userInfo);
    await mailbox.setCurrent();

    // If the user did not give us all of the requested scopes, throw an error so we can handle it downstream
    this.assertScopesNotMissing(credential, scopeType);

    return mailbox;
  }

  private async getUserInfo(credential: Credential) {
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${credential.accessToken}`
    );
    const payload = await response.json();
    if (response.ok) return payload;

    throw new Error(
      `Unexpected response. Status: ${response.status} Payload: ${JSON.stringify(payload)}`
    );
  }

  private async getUserId(email: string, credential: Credential) {
    let user = await this.serverClient.findUser({ email, credential });
    if (!user) {
      user = await this.serverClient.createUser({ email, credential });
    } else {
      await this.serverClient.updateUser({ id: user.id }, credential); // Update the users last seen at timestamp
    }

    return user.id;
  }

  private assertScopesNotMissing(credential: Credential, scopeType: SCOPE_TYPE) {
    const missingScopes: string[] = CredentialFactory.getMissingScopes(credential, scopeType);
    if (missingScopes.length) {
      throw new MissingScopesError({ missingScopes });
    }
  }

  private assertCorrectUserSelected(userInfo: any, scopeType: SCOPE_TYPE) {
    if (scopeType !== SCOPE_TYPE.GMAIL_ACCESS) {
      return;
    }

    if (this.mailbox && this.mailbox.email_address !== userInfo.email) {
      throw new WrongUserSelectedError({ expectedUser: this.mailbox.email_address });
    }
  }

  private async createOrUpdateMailbox(credential: Credential, userInfo: any) {
    if (this.mailbox !== undefined) {
      await this.mailbox.update({ credential });
      return this.mailbox;
    }

    return Mailbox.create<Mailbox>({
      email_address: userInfo.email,
      user_id: await this.getUserId(userInfo.email, credential),
      first_name: userInfo.given_name,
      last_name: userInfo.family_name,
      avatar_url: userInfo.picture,
      credential: credential,
    });
  }
}
