import Mailbox from '../model/mailbox';
import { EmailProviderMessage } from '../model/emailProviderMessage';
import Subscription from '../model/subscription';
import Message from '../model/message';
import { FilterOperation } from './filterOperationQueue';

export const SMPT_SUBJECT = 'Unsubscribe request by Trimbox';

export type ListMessageOptions = {
  query?: string;
};

export default interface EmailProvider {
  sendMessage: (mailbox: Mailbox, message: string) => Promise<void>;

  listMessages: (
    mailbox: Mailbox,
    options: ListMessageOptions
  ) => AsyncGenerator<EmailProviderMessage | null>;

  listSubscriptionMessages: (subscription: Subscription, mailbox: Mailbox) => Promise<string[]>;

  deleteMessages: (mailbox: Mailbox, messages: Message[]) => Promise<number>;

  deleteMessagesByQuery: (mailbox: Mailbox, query: string) => Promise<number>;

  deleteMessagesById: (mailbox: Mailbox, messageIds: string[]) => Promise<number>;

  filterSubscriptions: (operations: FilterOperation[], filterOperationId: string) => void;

  createBouncebackFilter: (mailbox: Mailbox) => void;
}
