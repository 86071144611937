import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { EventName, ErrorDict } from './analyticsProvider';

const EVENT_CODE_BY_EVENT_NAME: Record<string, string> = {
  [EventName.CLICK_LOGIN]: 'ClickButton',
  [EventName.LOGIN_SUCCESSFUL]: 'ViewContent',
  [EventName.CLICK_UNSUBSCRIBE]: 'ClickButton',
  [EventName.CLICK_KEEP]: 'ClickButton',
  [EventName.CLICK_DELETE_BUNDLE]: 'ClickButton',
  [EventName.PAYWALL_SHOW]: 'ViewContent',
  [EventName.PAYWALL_CONFIRM]: 'ClickButton',
  [EventName.CHECKOUT_COMPLETE]: 'CompletePayment',
};

export default class TikTokAnalyticsProvider implements AnalyticsProvider {
  public identify(params: IdentifyParams) {
    if (process.env.REACT_APP_TRIMBOX_ENV !== 'production') {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ttq.identify({
      email: params.email_address,
    });
  }

  public alias(id: string) {
    // Not needed
  }

  public track(name: EventName, props?: Dict) {
    if (process.env.REACT_APP_TRIMBOX_ENV !== 'production') {
      return;
    }

    const eventCode = EVENT_CODE_BY_EVENT_NAME[name];
    if (!eventCode) {
      return;
    }

    if (eventCode === 'ClickButton') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ttq.track(eventCode);
    } else if (eventCode === 'ViewContent') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ttq.track(eventCode, {
        content_name: name,
        content_id: name,
      });
    }
  }

  trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ttq.track(EVENT_CODE_BY_EVENT_NAME[EventName.CHECKOUT_COMPLETE], {
      content_type: 'product',
      content_name: 'trimbox_unlimited',
      content_id: 'trimbox_unlimited',
      value: checkoutParams.value,
      currency: checkoutParams.currency,
    });
  }

  public trackError(name: EventName, errorProps: ErrorDict) {
    // Do not log errors to TikTok
  }
}
