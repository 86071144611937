import { EmailProviderMessage } from '../../model/emailProviderMessage';

export type EmailBundleConfig = {
  bundleId: number;
  displayName: string;
  criteria: (message: EmailProviderMessage) => boolean;
  query?: string;
};

export enum SUPPORTED_EMAIL_BUNDLES_IDS {
  MAILING_LISTS,
  OLDER_THAN_1_YEAR,
  OLDER_THAN_6_MONTHS,
  LARGER_THAN_1_MB,
  ATTACHMENTS_LARGER_THAN_10_MB,
  NOT_YOU,
}

export const SUPPORTED_EMAIL_BUNDLES: EmailBundleConfig[] = [
  {
    bundleId: SUPPORTED_EMAIL_BUNDLES_IDS.MAILING_LISTS,
    displayName: 'Emails from all mailing lists',
    criteria: (message: EmailProviderMessage) => {
      return !!message.unsubscribeInfo;
    },
  },
  {
    bundleId: SUPPORTED_EMAIL_BUNDLES_IDS.LARGER_THAN_1_MB,
    displayName: 'Emails larger than 1MB',
    criteria: (message: EmailProviderMessage) => {
      return message.sizeInMB >= 1;
    },
    query: 'larger:1M',
  },
  {
    bundleId: SUPPORTED_EMAIL_BUNDLES_IDS.ATTACHMENTS_LARGER_THAN_10_MB,
    displayName: 'Emails with attachments larger than 10MB',
    criteria: (message: EmailProviderMessage) => {
      return message.hasAttachments && message.sizeInMB >= 10;
    },
    query: 'has:attachment larger:10M',
  },
  // {
  //   bundleId: SUPPORTED_EMAIL_BUNDLES_IDS.NOT_YOU,
  //   displayName: 'Emails not addressed directly to you',
  //   criteria: (message: EmailProviderMessage) => {
  //     // TODO
  //     return false;
  //   },
  // },
  {
    bundleId: SUPPORTED_EMAIL_BUNDLES_IDS.OLDER_THAN_1_YEAR,
    displayName: 'Emails older than 1 year',
    criteria: (message: EmailProviderMessage) => {
      const today = new Date();
      const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
      return message.receivedAt < oneYearAgo.getTime() / 1000;
    },
    query: 'older_than:1y',
  },
  {
    bundleId: SUPPORTED_EMAIL_BUNDLES_IDS.OLDER_THAN_6_MONTHS,
    displayName: 'Emails older than 6 months',
    criteria: (message: EmailProviderMessage) => {
      const today = new Date();
      const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
      return message.receivedAt < sixMonthsAgo.getTime() / 1000;
    },
    query: 'older_than:6m',
  },
];
