import findKey from 'lodash/findKey';
import { IMITATE_WEBVIEW } from '../../featureFlags';
import AnalyticsService from '../analytics/analyticsService';
import { EventName } from '../analytics/providers/analyticsProvider';
import { DeviceOS } from './webViewDetector.types';
import {
  FacebookWebView,
  InstagramWebView,
  MessengerWebView,
  SnapchatWebView,
  TikTokWebView,
  WebViewLayoutConfig,
} from './webViewLayoutConfig';
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../../constants';

// This whole file is based on https://github.com/f2etw/detect-inapp/blob/master/src/inapp.js

export type BrowserData = {
  regex: RegExp;
  layoutConfig?: WebViewLayoutConfig;
};

export const UA_IDENTIFERS: Record<string, BrowserData> = {
  messenger: {
    regex: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
    layoutConfig: MessengerWebView,
  },
  facebook: {
    regex: /\bFB[\w_]+\//,
    layoutConfig: FacebookWebView,
  },
  twitter: { regex: /\bTwitter/i },
  line: { regex: /\bLine\//i },
  wechat: { regex: /\bMicroMessenger\//i },
  puffin: { regex: /\bPuffin/i },
  miui: { regex: /\bMiuiBrowser\//i },
  instagram: {
    regex: /\bInstagram/i,
    layoutConfig: InstagramWebView,
  },
  // Added by David based on this SO post: https://stackoverflow.com/questions/69550524/what-does-bytelo-mean-in-user-agent
  tiktok: {
    regex: /\bBytedanceWebview\b|(?=.*\bAndroid\b)(?=.*\bByteLo\b)/,
    layoutConfig: TikTokWebView,
  },
  // Added by David based on this SO post: https://stackoverflow.com/questions/69550524/what-does-bytelo-mean-in-user-agent
  snapchat: {
    regex: /\bSnapchat/i,
    layoutConfig: SnapchatWebView,
  },
  chrome: { regex: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/ },
  safari: { regex: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/ },
  ie: { regex: /IEMobile|MSIEMobile/ },
  firefox: {
    regex: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
  },
};

export type WebViewInfo = {
  layoutConfig: WebViewLayoutConfig | undefined;
  deviceOS: DeviceOS;
  isInApp: boolean;
  browser: string;
  appStoreLink: string;
};

export class WebViewDetector {
  static checkForWebView(): WebViewInfo | undefined {
    const ua: string = navigator.userAgent || navigator.vendor || (window as any).opera;
    let webViewInfo = this._detect(ua);

    if (IMITATE_WEBVIEW) {
      webViewInfo = IMITATE_WEBVIEW;
    }

    if (webViewInfo?.isInApp) {
      AnalyticsService.track(EventName.CAPTURE_UA, { ua, webViewInfo });
    }

    return webViewInfo;
  }

  static _detect(useragent: string): WebViewInfo | undefined {
    const browser = findKey(UA_IDENTIFERS, (data) => data.regex.test(useragent)) || 'other';
    const deviceOS = this.getDeviceOS(useragent);

    const webViewInfo: WebViewInfo = {
      layoutConfig: UA_IDENTIFERS[browser]?.layoutConfig,
      browser,
      deviceOS,
      appStoreLink: this.getAppStoreLink(deviceOS),
      isInApp: this.getIsInApp(useragent),
    };
    return webViewInfo;
  }

  static getDeviceOS(ua: string): DeviceOS {
    if (/(iPad|iPhone)/i.test(ua)) {
      return 'ios';
    }
    if (/(Android)/i.test(ua)) {
      return 'android';
    }
    return 'android';
  }

  static getAppStoreLink(deviceOS: DeviceOS): string {
    if (deviceOS == 'android') {
      return PLAY_STORE_LINK;
    }
    return APP_STORE_LINK;
  }

  static getIsInApp(ua: string): boolean {
    const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv)'];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig');

    // Snapchat is acting weird...
    // The UA is
    // Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.2 Mobile/15E148 Snapchat/12.19.1.17 (like Safari/8614.3.7.0.6, panda)
    // But this function doesn't detect it
    if (ua.includes('Snapchat')) {
      return true;
    }
    return Boolean(ua.match(regex)) || ua.includes('Snapchat');
  }
}
