import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { getCurrentMailbox } from '../../model/mailbox';
import TrimboxServerClient from '../../services/serverClient';
import { Offering, OfferingState, PlanInterval } from './offeringSlice.types';

export const initialState: OfferingState = {};

export const offeringSlice = createSlice({
  name: 'offering',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOffering: (state, action: PayloadAction<Offering>) => {
      state.offering = action.payload;
      state.selectedPlanInterval = action.payload.plans?.[0].interval || 'month';
    },
    setSelectedPlan: (state, action: PayloadAction<PlanInterval>) => {
      state.selectedPlanInterval = action.payload;
    },
  },
});

export const { setOffering, setSelectedPlan } = offeringSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOffering = (state: RootState) => state.offering.offering;
export const selectSelectedPlanInterval = (state: RootState) => state.offering.selectedPlanInterval;

export default offeringSlice.reducer;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const fetchOffering = (): AppThunk => async (dispatch, getState) => {
  if (selectOffering(getState())) {
    return;
  }

  let userId;

  const mailbox = await getCurrentMailbox();
  if (mailbox) {
    const user = await mailbox.getUser$();
    if (user) {
      userId = user.id;
    }
  }

  const client: TrimboxServerClient = new TrimboxServerClient();

  let offering;
  if (mailbox && userId) {
    offering = await client.fetchOffering({ id: userId }, await mailbox.getCredential$());
  } else {
    offering = await client.fetchAnonymousOffering();
  }

  dispatch(setOffering(offering));
};
