import { useSearchParams } from 'react-router-dom';

export function useGetSourceFromURL(): {
  isChromeExtension: boolean;
  gmailLink: string | null;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const source = searchParams.get('source');
  const isChromeExtension = source === 'extension';

  const gmailLink = searchParams.get('gmailLink');

  return { isChromeExtension, gmailLink };
}
