import React from 'react';

import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { closeStudentPromoDialog, selectIsStudentPromoDialogOpen } from '../features/ui/uiSlice';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function StudentPromoPopup() {
  const isOpen = useAppSelector(selectIsStudentPromoDialogOpen);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeStudentPromoDialog());
  };

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const { t } = useTranslation();

  return (
    <Dialog
      open={!!isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
              }
            : {
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <Typography fontSize={'4rem'} textAlign='center' mt={'1rem'}>
        🎓
      </Typography>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
          ...(aboveSmall ? {} : { paddingTop: 0 }),
        }}
      >
        {t('areYouAStudent')}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography color='common.black' fontSize={'1rem'} textAlign={'center'}>
          {t('collegeTooBusy')}
        </Typography>
      </DialogContent>
      <Button
        variant='contained'
        size='large'
        onClick={() => {
          handleClose();
        }}
        sx={{
          margin: aboveSmall ? '2rem auto' : '1rem 2rem 2rem 2rem',
          fontSize: '1rem',
          fontWeight: 600,
          borderRadius: '1rem',
        }}
      >
        {t('activateYourAccount')}
      </Button>
    </Dialog>
  );
}
export default StudentPromoPopup;
