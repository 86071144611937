import { EmailDeleteStatus } from '../../shared.types';

export type EmailList = {
  listSender: string;

  unsubscribeStatus?: EmailListUnsubscribeStatus;

  deleteStatus?: EmailDeleteStatus;
};

export type EmailListUnsubscribeStatus =
  | undefined
  | 'unsubscribing'
  | 'keeping'
  // The db is the real source of truth for terminal states, but we also store it here to prevent flickering in the UI
  | 'unsubscribed'
  | 'kept';

export type ListAction = 'unsubscribe' | 'keep';

export type ListInfoPayload = {
  listSender: string;
  listName: string;
};

export enum UnsubscribeListSort {
  NEWEST = 'newest',
  NUMBER_OF_MESSAGES = 'mostMessages',
}

export type EmailListParams = {
  mailboxId: string;
  listSender: string;
};
