import React from 'react';

import { Button, useTheme, useMediaQuery, Avatar } from '@mui/material';

export type MockSignInButtonProps = {
  onClick: () => void;
  avatarString: string;
  buttonText: string;
  buttonColor: string;
};

function MockSignInButton(props: MockSignInButtonProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Button
      variant='contained'
      size='large'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={props.buttonColor}
      sx={{
        fontSize: aboveSmall ? '1.2rem' : isExtraSmall ? '1rem' : '1.2rem',
        margin: aboveSmall ? '1rem' : isExtraSmall ? '0.5rem' : '0.75rem',
        fontWeight: 500,
        padding: '2px 1rem 2px 2px',
        borderRadius: '2px',
        justifyContent: 'revert',
        '& .MuiButton-startIcon': {
          marginRight: '1rem',
          marginLeft: '0rem',
          background: 'white',
        },
      }}
      startIcon={
        <Avatar
          src={props.avatarString}
          sx={{
            width: aboveSmall ? 25 : 20,
            height: aboveSmall ? 25 : 20,
            padding: '0.5rem',
          }}
        />
      }
      onClick={async () => {
        props.onClick();
      }}
    >
      {props.buttonText}
    </Button>
  );
}
export default MockSignInButton;
