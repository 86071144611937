import ModelBase from './base';

export default class CheckoutSession extends ModelBase {
  public static table = 'checkoutSessions';

  public session_id: string;

  constructor(params: { session_id: string }) {
    super();
    this.session_id = params.session_id;
  }
}
