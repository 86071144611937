import Mailbox from '../model/mailbox';
import Subscription from '../model/subscription';
import EmailProvider, { SMPT_SUBJECT } from '../services/emailProvider';
import GmailEmailProvider from '../services/gmail/gmailEmailProvider';
import {
  HttpOneClickUnsubscribeInfo,
  isOneClickUnsubscribe,
  SmtpUnsubscribeInfo,
} from '../services/gmail/unsubscribeInfo/unsubscribeInfoAdapter';
import MessageBuilder from '../utilities/messageBuilder';
import AnalyticsService from '../services/analytics/analyticsService';
import { EventName } from '../services/analytics/providers/analyticsProvider';
import FilterOperationQueue, { FilterOperationCallbacks } from '../services/filterOperationQueue';

export default class UnsubscribeCommand {
  private subscription: Subscription;
  private provider: EmailProvider;
  private filterQueue: FilterOperationQueue;

  constructor(subscription: Subscription, provider: EmailProvider) {
    this.subscription = subscription;
    this.provider = provider;
    this.filterQueue = FilterOperationQueue.create(provider);
  }

  static create(subscription: Subscription) {
    return new UnsubscribeCommand(subscription, GmailEmailProvider.create());
  }

  run(callbacks: FilterOperationCallbacks) {
    const httpsUnsubscribeContext = this.subscription.unsubscribe_info.https;
    const smtpUnsubscribeContext = this.subscription.unsubscribe_info.smtp;

    // Try one click unsubscribe
    if (httpsUnsubscribeContext && isOneClickUnsubscribe(httpsUnsubscribeContext)) {
      // TODO - add this back once we have CORS proxy
      // success = await this.httpsUnsubscribe(httpsUnsubscribeContext);
    }

    // Try smtp unsubscribe
    if (smtpUnsubscribeContext) {
      // await this.smtpUnsubscribe(smtpUnsubscribeContext);
    }

    // Try adding a filter
    this.filterQueue.queue(this.subscription, callbacks);
  }

  private async httpsUnsubscribe(httpsContext: HttpOneClickUnsubscribeInfo): Promise<boolean> {
    try {
      const req: Request = new Request(httpsContext.httpsEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: httpsContext.oneClickSecret,
      });
      const resp = await fetch(req);
      if (resp.ok) {
        return true;
      }

      AnalyticsService.trackListError(
        EventName.HTTP_UNSUBSCRIBE_FAILED,
        this.subscription.email_address,
        resp
      );
      console.error(`[UnsubscribeCommand] failed with status ${resp.status} ${resp.statusText}`);

      return false;
    } catch (error) {
      AnalyticsService.trackListError(
        EventName.HTTP_UNSUBSCRIBE_FAILED,
        this.subscription.email_address,
        error
      );
      console.error(
        `[UnsubscribeCommand] failed to http unsubscribe with error ${this.errorMessage(error)}`
      );
    }

    return false;
  }

  private async smtpUnsubscribe(smtpUnsubscribeContext: SmtpUnsubscribeInfo): Promise<boolean> {
    try {
      const builder = new MessageBuilder(
        smtpUnsubscribeContext.recipient,
        smtpUnsubscribeContext.subject ?? SMPT_SUBJECT,
        'This message was automatically generated by Trimbox.'
      );

      const mailbox = await this.subscription.mailbox();
      await this.provider.sendMessage(mailbox, builder.build());

      await this.addBouncebackFilter(mailbox);

      return true;
    } catch (error) {
      AnalyticsService.trackListError(
        EventName.SMTP_UNSUBSCRIBE_FAILED,
        this.subscription.email_address,
        error
      );
      console.error(
        `[UnsubscribeCommand] failed to smtp unsubscribe with error ${this.errorMessage(error)}`
      );
    }

    return false;
  }

  private async addBouncebackFilter(mailbox: Mailbox): Promise<boolean> {
    try {
      await this.provider.createBouncebackFilter(mailbox);
      return true;
    } catch (error) {
      AnalyticsService.trackError(EventName.CREATE_BOUNCEBACK_FILTER_FAILED, error);
      console.error(
        `[UnsubscribeCommand] failed to add bounceback filter with error ${this.errorMessage(
          error
        )}`
      );
    }

    return false;
  }

  private errorMessage(error: any) {
    if (error instanceof Error) {
      return error.message;
    }

    return 'unknown error';
  }
}
