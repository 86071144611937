import instagram from '../../assets/instagram.svg';
import messenger from '../../assets/messenger.svg';
import tiktok from '../../assets/tiktok.svg';
import snapchat from '../../assets/snapchat.svg';
import facebook from '../../assets/facebook.svg';
import { MoreOptionsCorner, MoreOptionsIcon } from './webViewDetector.types';

export type WebViewLayoutConfig = {
  icon?: string;
  name: string;
  androidLayout: {
    moreOptionsCorner: MoreOptionsCorner;
    moreOptionsIcon: MoreOptionsIcon;
    buttonText: string;
  };
  iosLayout: {
    moreOptionsCorner: MoreOptionsCorner;
    moreOptionsIcon: MoreOptionsIcon;
    buttonText: string;
  };
};

export const FacebookWebView: WebViewLayoutConfig = {
  icon: facebook,
  name: 'Facebook',
  androidLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in Chrome',
  },
  iosLayout: {
    moreOptionsCorner: MoreOptionsCorner.BOTTOM_RIGHT_PADDED,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in browser',
  },
};

export const MessengerWebView: WebViewLayoutConfig = {
  icon: messenger,
  name: 'Messenger',
  androidLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_V,
    buttonText: 'Open in Chrome',
  },
  iosLayout: {
    moreOptionsCorner: MoreOptionsCorner.BOTTOM_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.SHARE,
    buttonText: 'Open in Safari',
  },
};

export const InstagramWebView: WebViewLayoutConfig = {
  icon: instagram,
  name: 'Instagram',
  androidLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_V,
    buttonText: 'Open in Chrome',
  },
  iosLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in browser',
  },
};

export const TikTokWebView: WebViewLayoutConfig = {
  icon: tiktok,
  name: 'TikTok',
  androidLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in browser',
  },
  iosLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in Safari',
  },
};

export const SnapchatWebView: WebViewLayoutConfig = {
  icon: snapchat,
  name: 'Snapchat',
  androidLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_LEFT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in Browser',
  },
  iosLayout: {
    moreOptionsCorner: MoreOptionsCorner.TOP_RIGHT,
    moreOptionsIcon: MoreOptionsIcon.DOTS_H,
    buttonText: 'Open in browser',
  },
};
