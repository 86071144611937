import { createTheme, PaletteColorOptions } from '@mui/material';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export const PRIMARY_COLOR_HEX = '#4285F4';
export const PRIMARY_COLOR_TRANSPARENT = 'rgba(66, 133, 244, 0.5)';

export const DARK_PRIMARY_COLOR_HEX = '#4059FF';
export const PURPLE_COLOR_HEX = '#3E00C1';

export const RED_COLOR_HEX = '#D30000';
export const RED_COLOR_TRANSPARENT = 'rgba(211, 0, 0, 0.5);';

export const YELLOW_COLOR_HEX = '#FFBF00';

export const PINK_COLOR_HEX = '#FF5F57';
export const SECONDARY_COLOR_HEX = '#6AFFE8';
export const GHOST_WHITE_HEX = '#F3F3F3';
export const WHITE_HEX = '#FFFFFF';
export const BLACK_HEX = '#232323';

export const GOOGLE_BLUE = '#4285F4';
export const YAHOO_PURPLE = '#6001D1';
export const OUTLOOK_BLUE = '#2172B9';

export const GRAY_HEX = '#969595';
export const LIGHTER_GRAY_HEX = '#e6e6e6';
export const LIGHT_GRAY_HEX = '#c9c7c7';
export const WHITESMOKE_HEX = '#f5f5f5';

export const FOREST_GREEN_HEX = '#3f826d';
export const LAWN_GREEN_HEX = '#7CFC00';
export const GREEN_HEX = '#31b044';
export const PURPLE_HEX = '#9543ff';

export const STREAK_1_HEX = '#FF8700';
export const STREAK_1_TRANSPARENT_HEX = 'rgba(255, 135, 0, 0.1);';
export const STREAK_2_HEX = '#F86B2F';
export const STREAK_2_TRANSPARENT_HEX = 'rgba(248, 107, 47, 0.1);';
export const STREAK_3_HEX = '#F23A36';
export const STREAK_3_TRANSPARENT_HEX = 'rgba(242, 57, 54, 0.1);';

export const DARK_GRAY_HEX = '#636262';

const font = "'system-ui', sans-serif";

const theme = createTheme({
  typography: {
    fontFamily: font,
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: createColor(PRIMARY_COLOR_HEX),
    secondary: createColor(SECONDARY_COLOR_HEX),
    darkPrimary: createColor(DARK_PRIMARY_COLOR_HEX),
    ghostWhite: createColor(GHOST_WHITE_HEX),
    white: createColor(WHITE_HEX),
    black: createColor(BLACK_HEX),
    purple: createColor(PURPLE_COLOR_HEX),
    red: createColor(RED_COLOR_HEX),
    yellow: createColor(YELLOW_COLOR_HEX),
    pink: createColor(PINK_COLOR_HEX),
    googleBlue: createColor(GOOGLE_BLUE),
    yahooPurple: createColor(YAHOO_PURPLE),
    outlookBlue: createColor(OUTLOOK_BLUE),
    aolBlack: createColor(BLACK_HEX),
    gray: createColor(GRAY_HEX),
    darkGray: createColor(DARK_GRAY_HEX),
    lightGray: createColor(LIGHTER_GRAY_HEX),
    primaryTransparent: createColor(PRIMARY_COLOR_TRANSPARENT),
    redTransparent: createColor(PRIMARY_COLOR_TRANSPARENT),
  },
  breakpoints: {
    values: {
      xs: 350,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

declare module '@mui/material/styles' {
  interface CustomPalette {
    darkPrimary: PaletteColorOptions;
    ghostWhite: PaletteColorOptions;
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    purple: PaletteColorOptions;
    red: PaletteColorOptions;
    yellow: PaletteColorOptions;
    pink: PaletteColorOptions;
    googleBlue: PaletteColorOptions;
    yahooPurple: PaletteColorOptions;
    outlookBlue: PaletteColorOptions;
    aolBlack: PaletteColorOptions;
    gray: PaletteColorOptions;
    darkGray: PaletteColorOptions;
    lightGray: PaletteColorOptions;
    primaryTransparent: PaletteColorOptions;
    redTransparent: PaletteColorOptions;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    darkPrimary: true;
    ghostWhite: true;
    white: true;
    black: true;
    purple: true;
    red: true;
    yellow: true;
    pink: true;
    googleBlue: true;
    gray: true;
    darkGray: true;
    lightGray: true;
    primaryTransparent: true;
    redTransparent: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    darkPrimary: true;
    ghostWhite: true;
    white: true;
    black: true;
    purple: true;
    red: true;
    yellow: true;
    pink: true;
    googleBlue: true;
    gray: true;
    darkGray: true;
    lightGray: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    darkPrimary: true;
    ghostWhite: true;
    white: true;
    black: true;
    purple: true;
    red: true;
    yellow: true;
    pink: true;
    googleBlue: true;
    gray: true;
    darkGray: true;
    lightGray: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    darkPrimary: true;
    ghostWhite: true;
    white: true;
    black: true;
    purple: true;
    red: true;
    pink: true;
    googleBlue: true;
    gray: true;
    darkGray: true;
    lightGray: true;
  }
}

export default theme;
