import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { ErrorDict, EventName } from './analyticsProvider';

const CONVERSION_LABEL_BY_EVENT_NAME: Record<string, string> = {
  [EventName.CLICK_LOGIN]: 'tw-oe54d-oe54s',
  [EventName.LOGIN_SUCCESSFUL]: 'tw-oe54d-oe59k',
  [EventName.CLICK_UNSUBSCRIBE]: 'tw-oe54d-oe59m',
  [EventName.CLICK_KEEP]: 'tw-oe54d-oe59n',
  [EventName.CLICK_DELETE_BUNDLE]: 'tw-oe54d-oe59o',
  [EventName.PAYWALL_SHOW]: 'tw-oe54d-oe59p',
  [EventName.PAYWALL_CONFIRM]: 'tw-oe54d-oe59q',
  [EventName.CHECKOUT_CAPTURE]: 'tw-oe54d-oe59s',
  [EventName.CHECKOUT_COMPLETE]: 'tw-oe54d-oe59r',
};

export default class TwitterAnalyticsProvider implements AnalyticsProvider {
  identify(params: IdentifyParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  }

  alias(id: string) {
    //
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track(name: EventName, props?: Dict) {
    if (name in CONVERSION_LABEL_BY_EVENT_NAME) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      twq('event', CONVERSION_LABEL_BY_EVENT_NAME[name], {});
    }
  }

  trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    twq('event', CONVERSION_LABEL_BY_EVENT_NAME[EventName.CHECKOUT_COMPLETE], {
      value: checkoutParams.value,
      currency: checkoutParams.currency,
    });
  }

  trackError(name: EventName, errorProps: ErrorDict) {
    //
  }
}
