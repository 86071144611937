import React from 'react';

import { Button, useTheme, useMediaQuery, Avatar } from '@mui/material';
import google_g from '../../assets/google_g.svg';
import gmail_g from '../../assets/gmail_g.svg';

export type GoogleSignInButton = {
  buttonText: string;
  onSignIn: () => void;
  useGmailIcon: boolean;
};

function GoogleSignInButton(props: GoogleSignInButton) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { buttonText, onSignIn, useGmailIcon } = props;

  return (
    <Button
      variant='contained'
      size='large'
      color='googleBlue'
      sx={{
        fontSize: aboveSmall ? '1.2rem' : isExtraSmall ? '1rem' : '1.2rem',
        margin: aboveSmall ? '1rem' : isExtraSmall ? '0.5rem' : '0.75rem',
        fontWeight: 600,
        padding: '0.75rem 1.5rem',
        borderRadius: '50px',
        justifyContent: 'revert',
        display: 'flex',
        alignItems: 'center',
        '& .MuiButton-startIcon': {
          marginRight: '1rem',
          marginLeft: '0',
          background: 'white',
          borderRadius: '50%',
        },
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
        },
      }}
      startIcon={
        <Avatar
          src={useGmailIcon ? gmail_g : google_g}
          sx={{
            ...(props.useGmailIcon
              ? {
                  width: aboveSmall ? 25 : 20,
                  height: aboveSmall ? 20 : 15,
                  padding: '0.6rem 0.5rem',
                }
              : {
                  width: aboveSmall ? 25 : 20,
                  height: aboveSmall ? 25 : 20,
                  padding: '0.5rem',
                }),
            backgroundColor: 'transparent',
          }}
        />
      }
      onClick={onSignIn}
    >
      {buttonText}
    </Button>
  );
}
export default GoogleSignInButton;
