import Subscription from '../model/subscription';
import BulkDeleteOperationQueue, {
  BulkDeleteOperationCallbacks,
  BulkDeleteSubscriptionOperation,
} from '../services/bulkDeleteOperationQueue';
import EmailProvider from '../services/emailProvider';
import GmailEmailProvider from '../services/gmail/gmailEmailProvider';

export default class DeleteListCommand {
  private subscription: Subscription;
  private bulkDeleteQueue: BulkDeleteOperationQueue;

  constructor(subscription: Subscription, provider: EmailProvider) {
    this.subscription = subscription;
    this.bulkDeleteQueue = BulkDeleteOperationQueue.create(provider);
  }

  static create(subscription: Subscription) {
    return new DeleteListCommand(subscription, GmailEmailProvider.create());
  }

  run(nonce: string, callbacks: BulkDeleteOperationCallbacks) {
    const operation: BulkDeleteSubscriptionOperation = {
      subscription: this.subscription,
      nonce,
      callbacks,
    };
    this.bulkDeleteQueue.queue(operation);
  }
}
