import React, { useCallback } from 'react';

import { t } from 'i18next';
import { useSnackbar, OptionsObject } from 'notistack';
import { TRIMBOX_REDIRECT_KEY } from '../../constants';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { SCOPE_TYPE } from '../../services/credentialFactory';
import MailboxConnector from '../../services/mailboxConnector';
import GoogleSignInButton from '../../components/GoogleSignInButton/GoogleSignInButton';

export type SignInButtonProps = {
  buttonText: string;
};

function SignInButton(props: SignInButtonProps) {
  const { enqueueSnackbar } = useSnackbar();

  function showErrorToast(e: unknown) {
    const snackbarOptions: OptionsObject = {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: false,
    };

    const errorMessage = t('failedToSignIn');

    enqueueSnackbar(errorMessage, snackbarOptions);
  }

  const onSignIn = useCallback(async () => {
    AnalyticsService.track(EventName.CLICK_LOGIN);

    AnalyticsService.track(EventName.LOGIN_START);

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const g = google;

      localStorage.setItem(TRIMBOX_REDIRECT_KEY, window.location.href);

      MailboxConnector.create(g).startConnection(SCOPE_TYPE.USER_INFO);
    } catch (e: unknown) {
      AnalyticsService.trackError(EventName.LOGIN_FAILED, e);

      showErrorToast(e);
    }
  }, []);

  const { buttonText } = props;

  return <GoogleSignInButton buttonText={buttonText} onSignIn={onSignIn} useGmailIcon={false} />;
}
export default SignInButton;
