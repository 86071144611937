import React, { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useMediaQuery } from '@mui/material';
import banner_image from '../../assets/banner_image.svg';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';
import ManagePopup from './ManagePopup';
import { LicenseStatus } from './useLoadMailboxAndLicenses';
import { t } from 'i18next';
import TrimboxServerClient from '../../services/serverClient';
import Mailbox from '../../model/mailbox';

export type TopBarProps = {
  hasAccessToken: boolean;
  licenseStatus: LicenseStatus | undefined;
  mailbox: Mailbox | null | undefined;
};

export default function TopBar(props: TopBarProps) {
  const { hasAccessToken, licenseStatus, mailbox } = props;

  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const navigate = useNavigate();

  const [isManagePopupOpen, setIsManagePopupOpen] = useState<boolean>();

  return (
    <AppBar
      elevation={0}
      position='sticky'
      color='white'
      sx={{
        padding: '0.5rem 0rem',
      }}
    >
      <Container maxWidth={'lg'}>
        <Toolbar disableGutters sx={{ ...(isExtraSmall ? { minHeight: '48px' } : {}) }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <Box
              component='img'
              src={banner_image}
              sx={{
                height: aboveSmall ? 36 : isExtraSmall ? 24 : 28,
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/');
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          ></Box>
          <Box sx={{ flexGrow: 0 }}>
            {hasAccessToken &&
              licenseStatus &&
              (licenseStatus.hasActiveIAPLicense || licenseStatus.ownsActiveStripeLicense) && (
                <Button
                  color='darkGray'
                  size='small'
                  variant='outlined'
                  sx={{ fontSize: aboveSmall ? '1rem' : '0.8rem' }}
                  onClick={async () => {
                    if (licenseStatus.hasActiveIAPLicense) {
                      setIsManagePopupOpen(true);
                    } else {
                      if (!mailbox) {
                        throw new Error('Mailbox not found!');
                      }

                      const user = await mailbox.getUser$();

                      const billingSession =
                        await new TrimboxServerClient().createBillingSettingsSession(
                          { lang: 'en', userId: user.id, returnUrl: window.location.href },
                          await mailbox.getCredential$()
                        );
                      const billingSessionUrl = billingSession.location;
                      window.location.href = billingSessionUrl;
                    }
                  }}
                >
                  {t('manageSubscription')}
                </Button>
              )}
          </Box>
        </Toolbar>
      </Container>
      <ManagePopup
        isOpen={!!isManagePopupOpen}
        onDismiss={() => {
          setIsManagePopupOpen(false);
        }}
      />
    </AppBar>
  );
}
