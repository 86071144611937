import React from 'react';

import { useTheme, useMediaQuery, Typography, Box } from '@mui/material';
import { GRAY_HEX, GREEN_HEX } from '../../../theme';
import {} from '../../../features/offering/offeringSlice.types';
import { formatPrice } from '../../../features/offering/currencyHelper';
import { useTranslation } from 'react-i18next';
import { useGetOffering } from '../../../app/useGetOffering';
import Mailbox from '../../../model/mailbox';

type TotalDueProps = {
  mailbox: Mailbox | null | undefined;
};

function TotalDue(props: TotalDueProps) {
  const { mailbox } = props;

  const { currency, totalDueAmounts } = useGetOffering({ mailbox });

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: aboveSmall ? '1.5rem' : '1rem',
        alignItems: 'center',
      }}
    >
      <Typography
        fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
        sx={{ flexGrow: 1 }}
      >
        {t('totalDueToday')}
      </Typography>
      {currency && (
        <>
          {totalDueAmounts && (
            <Typography
              fontSize={isExtraSmall ? '0.65rem' : aboveSmall ? '0.85rem' : '0.75rem'}
              textAlign='center'
              sx={totalDueAmounts.comparisonPrice ? { textDecorationLine: 'line-through' } : {}}
              color={GRAY_HEX}
            >
              {formatPrice(totalDueAmounts.comparisonPrice || totalDueAmounts.totalDue, currency)}
            </Typography>
          )}
          {totalDueAmounts?.comparisonPrice && (
            <Typography
              fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '1rem'}
              fontWeight={500}
              color={GREEN_HEX}
              textAlign='center'
              marginLeft={'0.5rem'}
            >
              {formatPrice(totalDueAmounts.totalDue, currency)}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

export default TotalDue;
