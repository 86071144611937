import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import DiscountIcon from '@mui/icons-material/Discount';
import { Typography, useMediaQuery } from '@mui/material';
import theme from '../../theme';
import { useTranslation } from 'react-i18next';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(95deg, rgb(33, 150, 242) 0%, rgb(33, 203, 243) 50%, rgb(35, 87, 138) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(95deg, rgb(33, 150, 242) 0%, rgb(33, 203, 243) 50%, rgb(35, 87, 138) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(136deg, rgb(33, 140, 242) 0%, rgb(33, 95, 233) 50%, rgb(35, 60, 138) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(136deg, rgb(33, 140, 242) 0%, rgb(33, 95, 233) 50%, rgb(35, 60, 138) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement | null } = {
    1: <Typography fontWeight={800}> 1 </Typography>,
    2: <Typography fontWeight={800}> 2 </Typography>,
    3: <DiscountIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export type StyledStepperProps = {
  activeStep: number;
};

export default function StyledStepper(props: StyledStepperProps) {
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={props.activeStep} connector={<ColorlibConnector />}>
        <Step key={'1'} sx={{ minWidth: aboveMedium ? '200px' : '100px' }}>
          <StepLabel StepIconComponent={ColorlibStepIcon}> </StepLabel>
        </Step>
        <Step key={'2'} sx={{ minWidth: aboveMedium ? '200px' : '100px' }}>
          <StepLabel StepIconComponent={ColorlibStepIcon}> </StepLabel>
        </Step>
        <Step key={'3'} sx={{ minWidth: aboveMedium ? '200px' : '100px' }}>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            sx={{ fontWeight: 800 }}
            slotProps={{
              label: {
                style: {
                  fontWeight: 600,
                  marginTop: '0.5rem',
                  fontSize: aboveMedium ? '1rem' : '0.75rem',
                },
              },
            }}
          >
            {t('unlockYourOffer')}
          </StepLabel>
        </Step>
      </Stepper>
    </Stack>
  );
}
