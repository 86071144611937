import React from 'react';

import { useTheme, useMediaQuery, Stack, Button } from '@mui/material';
import { BLACK_HEX, LIGHTER_GRAY_HEX, WHITE_HEX } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../app/hooks';
import { closePlansDialog } from '../../../features/ui/uiSlice';
import AnalyticsService from '../../../services/analytics/analyticsService';
import { EventName } from '../../../services/analytics/providers/analyticsProvider';
import { openCheckoutPage } from '../../../utilities/paywallHelper';
import { useSnackbar } from 'notistack';
import PlanSelection from './PlanSelection/PlanSelection';
import { useGetOffering } from '../../../app/useGetOffering';
import Mailbox from '../../../model/mailbox';

export type PlansContainerProps = {
  hasFeedbackDiscount: boolean;
  onNoThanks: () => void;
  onAlreadyPaid: () => void;
  closePopup: () => void;
  mailbox: Mailbox | null | undefined;
};

function PlansContainer(props: PlansContainerProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { hasFeedbackDiscount, onAlreadyPaid, mailbox } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { currency, selectedPlan, promoCode } = useGetOffering({ mailbox });

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Stack
      sx={{
        ...(aboveSmall
          ? {
              width: '50%',
            }
          : { marginTop: '1rem' }),
        backgroundColor: WHITE_HEX,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: LIGHTER_GRAY_HEX,
        padding: '1rem 1rem 0.5rem 1rem',
      }}
    >
      <PlanSelection
        mailbox={mailbox}
        onClick={async () => {
          try {
            if (!selectedPlan || !currency) {
              return;
            }

            await openCheckoutPage({
              mailboxId: mailbox?.email_address,
              selectedPlan: selectedPlan.planType,
              currency,
              promoCode: promoCode?.id,
            });
            dispatch(closePlansDialog());
          } catch (e) {
            enqueueSnackbar(t('somethingWentWrong'), {
              variant: 'error',
              preventDuplicate: true,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              persist: false,
            });
            AnalyticsService.trackError(EventName.CHECKOUT_FAILED, e);
          }
        }}
        hasFeedbackDiscount={hasFeedbackDiscount}
      />
      <Button
        variant='text'
        size='small'
        onClick={() => {
          onAlreadyPaid();
        }}
        sx={{
          margin: 'auto',
          marginTop: '1rem',
          fontSize: isExtraSmall ? '0.75rem' : '0.85rem',
          color: BLACK_HEX,
          width: 'fit-content',
        }}
      >
        {t('alreadyPaid')}
      </Button>
    </Stack>
  );
}

export default PlansContainer;
