import React from 'react';

import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { DARK_GRAY_HEX } from '../../theme';
import CircleIcon from '@mui/icons-material/Circle';

export type UpsellBulletProps = {
  text: string;
  fontSize: string;
};

function UpsellBullet(props: UpsellBulletProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...(aboveSmall ? { marginTop: '1rem' } : { marginBottom: '0.5rem' }),
      }}
    >
      <CircleIcon sx={{ fontSize: '0.5rem', color: DARK_GRAY_HEX, marginRight: '0.5rem' }} />
      <Typography fontSize={props.fontSize} color={DARK_GRAY_HEX} fontWeight='500'>
        {props.text}
      </Typography>
    </Box>
  );
}
export default UpsellBullet;
