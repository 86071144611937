export interface OfferingState {
  offering?: Offering;
  selectedPlanInterval?: PlanInterval;
}

export type Offering = {
  currency: CurrencyConfig;
  plans: PlanType[];
};

export type PlanType = {
  label: 'default';
  amount: number;
  interval: PlanInterval;
};

export type PlanIntervalConfig = {
  title: string;
  subtitle: string;

  bullets?: string[];
  billingPeriod: string;
};

export type PlanTypePresentable = PlanIntervalConfig & {
  amountWithoutDiscount: number;
  amountWithDiscount: number;

  maximizedPrice: number;
  presentedPrice: number;

  isPreferredOption?: boolean;

  interval: PlanInterval;

  presentedSavings?: number;

  // Used by stripe
  planType: PlanType;
};

export type PlanInterval = 'month' | 'year' | 'lifetime' | 'free';

export type CurrencyConfig = {
  code: string;
  symbol: string;
  minorUnitPrecision: number;
};

export function getAmountWithDiscount(discountPercentage: number | undefined, amount: number) {
  const discount = discountPercentage || 0;
  return amount * (1 - discount);
}

export type TotalDueAmounts = {
  totalDue: number;
  comparisonPrice?: number;
};

export type PromoCode = {
  id: string;
  active: boolean;
  code: string;
  coupon: {
    name: string;
    percent_off: number;
    valid: boolean;
  };
};
