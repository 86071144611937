import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectKeepSettingsDialog, closeKeepSettingsDialog } from '../../features/ui/uiSlice';
import { useSnackbar } from 'notistack';
import Mailbox, { getCurrentMailbox } from '../../model/mailbox';
import {
  performKeepAction,
  selectSessionListCount,
} from '../../features/emailLists/emailListsSlice';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import AnalyticsService from '../../services/analytics/analyticsService';
import { useTranslation } from 'react-i18next';
import SettingsPopup from './SettingsPopup';
import { SettingsCheckboxProps } from './SettingsCheckbox';

function KeepSettingsPopup() {
  const keepSettingsDialog = useAppSelector(selectKeepSettingsDialog);

  const sessionListCount = useAppSelector(selectSessionListCount);

  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [moveToTrash, setMoveToTrash] = useState(keepSettingsDialog?.moveToTrash);
  const [doNotAskAgain, setDoNotAskAgain] = useState(keepSettingsDialog?.doNotAskAgain);
  const [useAsNewDefault, setUseAsNewDefault] = useState(keepSettingsDialog?.useAsNewDefault);

  const settingsCheckboxes: SettingsCheckboxProps[] = [
    {
      isChecked: !!moveToTrash,
      onChange: setMoveToTrash,
      labelString: 'moveExistingToTrash',
    },
  ];

  if (doNotAskAgain !== undefined) {
    settingsCheckboxes.push({
      isChecked: !!doNotAskAgain,
      onChange: setDoNotAskAgain,
      labelString: 'doNotAskAgain',
    });
  }

  if (useAsNewDefault !== undefined) {
    settingsCheckboxes.push({
      isChecked: !!useAsNewDefault,
      onChange: setUseAsNewDefault,
      labelString: 'useAsNewDefault',
    });
  }

  const handleClose = () => {
    dispatch(closeKeepSettingsDialog(undefined));
  };

  const handleConfirm = async () => {
    try {
      const mailbox = await getCurrentMailbox();
      if (!mailbox) {
        throw new Error('Current mailbox not found! a');
      }

      const mailboxParams: Partial<Pick<Mailbox, 'keep_trash' | 'keep_do_not_ask'>> = {};

      if (doNotAskAgain !== undefined) {
        mailboxParams.keep_do_not_ask = doNotAskAgain;
      }

      if (useAsNewDefault === undefined || useAsNewDefault == true) {
        mailboxParams.keep_trash = moveToTrash;
      }

      await mailbox.update(mailboxParams);

      const showErrorToast = (errorMessage: string) => {
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          preventDuplicate: true,
          persist: false,
        });
      };

      if (!keepSettingsDialog) {
        return;
      }

      // this is a high volume event, so we'll log it only once to save on Mixpanel costs
      if (!sessionListCount) {
        AnalyticsService.trackListEvent(
          EventName.CLICK_KEEP,
          keepSettingsDialog.listInfo.listSender
        );
      }

      dispatch(
        performKeepAction(
          keepSettingsDialog.mailboxId,
          keepSettingsDialog.listInfo,
          showErrorToast,
          {
            alsoDelete: !!moveToTrash,
            isFromSettings: true,
          }
        )
      );

      handleClose();
    } catch (e) {
      enqueueSnackbar(t('somethingWentWrong'), {
        variant: 'error',
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        persist: false,
      });
    }
  };

  return (
    <SettingsPopup
      isOpen={!!keepSettingsDialog}
      title={t('keepThisList')}
      description={t('keepDescription', {
        listName: keepSettingsDialog?.listInfo.listName || t('thisMailingList'),
      })}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      confirmText={t('keep')}
      settingsCheckboxes={settingsCheckboxes}
    ></SettingsPopup>
  );
}

export default KeepSettingsPopup;
