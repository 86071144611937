const english_keywords = [
  'unsubscribe',
  'subscription settings',
  'optout',
  'opt-out',
  'opt out',
  'manage your',
  'manage preference',
  'stop receiving these emails',
  'don’t want to receive',
  'do not want to receive',
  'email preference',
];

const french_keywords = [
  'désabonner',
  'se désabonner',
  'désinscription',
  'se désinscrire',
  'cesser de recevoir',
  "annuler l'abonnement",
  "préférences d'email",
  'préférences de courriel',
  'ne plus recevoir',
  'liste de diffusion',
  'désinscrire de cette liste',
];

const german_keywords = [
  'abmelden',
  'abbestellen',
  'abonnement kündigen',
  'newsletter abbestellen',
  'abmeldung',
  'keine e-mails mehr',
  'e-mail-einstellungen ändern',
  'vom verteiler entfernen',
  'klicken sie hier, um sich abzumelden',
];

const dutch_unsubscribe_keywords = [
  'afmelden',
  'uitschrijven',
  'annuleer abonnement',
  'beheer uw e-mailvoorkeuren',
  'stop deze e-mails',
  'klik hier om af te melden',
];

const danish_unsubscribe_keywords = [
  'afmeld',
  'framelde',
  'afmeld abonnement',
  'administrer dine e-mailindstillinger',
  'stop disse e-mails',
  'klik her for at afmelde',
];

const spanish_unsubscribe_keywords = [
  'darse de baja',
  'cancelar suscripción',
  'gestionar preferencias de correo electrónico',
  'dejar de recibir estos correos',
  'haz clic aquí para darte de baja',
];

const croatian_unsubscribe_keywords = [
  'odjava',
  'prekini pretplatu',
  'upravljanje postavkama e-pošte',
  'prestani primati ove e-mailove',
  'kliknite ovdje za odjavu',
];

const italian_unsubscribe_keywords = [
  'disiscrivi',
  "annulla l'iscrizione",
  'gestisci le preferenze di posta elettronica',
  'smetti di ricevere queste email',
  'clicca qui per disiscriverti',
];

const korean_unsubscribe_keywords = [
  '구독 해지',
  '구독 취소',
  '이메일 설정 관리',
  '이 이메일을 더 이상 받지 않음',
  '구독 해지를 원하시면 여기를 클릭하세요',
];

const portuguese_unsubscribe_keywords = [
  'cancelar inscrição',
  'cancelar a assinatura',
  'gerenciar preferências de email',
  'parar de receber esses emails',
  'clique aqui para cancelar a inscrição',
];

const russian_unsubscribe_keywords = [
  'отписаться',
  'отменить подписку',
  'управление настройками электронной почты',
  'прекратить получение этих писем',
  'нажмите здесь, чтобы отписаться',
];

const turkish_unsubscribe_keywords = [
  'aboneliği iptal et',
  'abonelikten çık',
  'e-posta tercihlerinizi yönetin',
  'bu e-postaları almayı bırak',
  'abonelikten çıkmak için burayı tıklayın',
];

export const unsubscribeKeywords = english_keywords.concat(
  french_keywords,
  german_keywords,
  dutch_unsubscribe_keywords,
  danish_unsubscribe_keywords,
  spanish_unsubscribe_keywords,
  croatian_unsubscribe_keywords,
  italian_unsubscribe_keywords,
  korean_unsubscribe_keywords,
  portuguese_unsubscribe_keywords,
  russian_unsubscribe_keywords,
  turkish_unsubscribe_keywords
);
