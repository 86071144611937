import { db } from '../../db/db';
import { BulkDeleteOperationCallbacks } from '../../services/bulkDeleteOperationQueue';

export type EmailBundlesAPIParams = {
  bundleId: number;
  mailboxId: string;
};

export async function deleteEmailBundle(
  params: EmailBundlesAPIParams,
  callbacks: BulkDeleteOperationCallbacks
) {
  const bundle = await db.bundles.get({
    mailbox_id: params.mailboxId,
    bundle_id: params.bundleId,
  });
  if (!bundle) {
    throw new Error('Bundle not found!');
  }
  await bundle.delete(callbacks);
}
