import Base64Url from './base64';

/*
 Creates an email according to rfc2822 format
 https://tools.ietf.org/html/rfc2822
*/
export default class MessageBuilder {
  private recipient: string;
  private subject: string;
  private body: string;

  constructor(recipient: string, subject: string, body: string) {
    this.recipient = recipient;
    this.subject = subject;
    this.body = body;
  }

  build() {
    const recipient = `to: ${this.recipient}\r\n`;
    const subject = `subject: ${this.subject}\r\n`;
    const body = `\r\n${this.body}`;
    const message = [recipient, subject, body].join('');
    return Base64Url.encode(message);
  }
}
