import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { EmailDeleteStatus } from '../../shared.types';
import { deleteEmailBundle } from './emailBundlesAPI';
import { BundleInfoPayload, EmailBundle } from './emailBundles.types';
import { setPrePaywallProgressDialog } from '../ui/uiSlice';
import Mailbox from '../../model/mailbox';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';

export type BundlesMap = { [mailboxId: string]: { [bundleId: string | number]: EmailBundle } };

export interface EmailBundlesState {
  bundles: BundlesMap;
}

export const initialState: EmailBundlesState = {
  bundles: {},
};

export type BundleParams = {
  mailboxId: string;
  bundleId: number;
};

export const emailBundlesSlice = createSlice({
  name: 'emailBundles',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDeleteStatus: (
      state,
      action: PayloadAction<{
        params: BundleParams;
        deleteStatus: EmailDeleteStatus;
      }>
    ) => {
      const bundle = getOrCreateBundle(state, action.payload.params);
      if (bundle) {
        bundle.deleteStatus = action.payload.deleteStatus;
      }
    },
  },
});

export const { setDeleteStatus } = emailBundlesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBundle = (state: RootState, params: BundleParams) => {
  const emailBundles = selectBundles(state, params.mailboxId);
  return emailBundles?.[params.bundleId];
};

export const selectBundles = (state: RootState, mailboxId: string) => {
  const emailBundles = selectBundlesFromBundleState(state.emailBundles, mailboxId);
  return emailBundles;
};

export const selectBundlesFromBundleState = (
  state: EmailBundlesState,
  mailboxId: string | undefined
) => {
  if (!mailboxId) {
    throw new Error('Current mailbox id is not set! c');
  }
  const emailBundles = state.bundles[mailboxId];
  return emailBundles;
};

export const selectBundleFromBundleState = (state: EmailBundlesState, params: BundleParams) => {
  const emailBundles = selectBundlesFromBundleState(state, params.mailboxId);
  return emailBundles?.[params.bundleId];
};

export const selectBundleDeleteStatus = (
  state: RootState,
  params: BundleParams
): EmailDeleteStatus | undefined => {
  const bundle = selectBundle(state, params);
  return bundle?.deleteStatus;
};

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const performBundleDelete =
  (
    mailbox: Mailbox | undefined | null,
    bundleInfo: BundleInfoPayload,
    showErrorToast: (errorMessage: string) => void,
    setRecentlyDeleted: () => void,
    shouldShowPaywallWhenUnsubscribing: () => Promise<boolean>
  ): AppThunk =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState) => {
    const { bundleId, bundleName } = bundleInfo;

    if (!mailbox) {
      throw new Error('Mailbox not found!');
    }

    const mailboxId = mailbox.email_address;

    try {
      dispatch(
        setDeleteStatus({
          params: {
            bundleId,
            mailboxId,
          },
          deleteStatus: 'deleting',
        })
      );

      if (await shouldShowPaywallWhenUnsubscribing()) {
        dispatch(setPrePaywallProgressDialog(mailbox, showErrorToast));
        dispatch(
          setDeleteStatus({
            params: {
              bundleId,
              mailboxId,
            },
            deleteStatus: undefined,
          })
        );
      } else {
        const onBulkDeleteFailure = (e: unknown) => {
          AnalyticsService.trackError(EventName.DELETE_BUNDLE_FAILED, e);
          showErrorToast('Failed to delete ' + bundleName);
          dispatch(
            setDeleteStatus({
              params: {
                bundleId,
                mailboxId,
              },
              deleteStatus: undefined,
            })
          );
        };

        const onBulkDeleteSuccess = async (number_of_messages_deleted: number) => {
          AnalyticsService.track(EventName.DELETE_BUNDLE_SUCCESSFUL);
          dispatch(
            setDeleteStatus({
              params: {
                bundleId,
                mailboxId,
              },
              deleteStatus: 'deleted',
            })
          );
          setRecentlyDeleted();
        };

        await deleteEmailBundle(
          { bundleId, mailboxId },
          { onSuccess: onBulkDeleteSuccess, onError: onBulkDeleteFailure }
        );
      }
    } catch (e) {
      AnalyticsService.trackError(EventName.DELETE_BUNDLE_FAILED, e);
      showErrorToast('Failed to delete ' + bundleName);
      dispatch(
        setDeleteStatus({
          params: {
            bundleId,
            mailboxId,
          },
          deleteStatus: undefined,
        })
      );
    }
  };

function getOrCreateBundle(state: EmailBundlesState, params: BundleParams): EmailBundle {
  let bundle = selectBundleFromBundleState(state, params);
  if (bundle) {
    return bundle;
  }

  bundle = {
    id: params.bundleId,
  };

  const mailboxBundles = state.bundles[params.mailboxId];
  if (!mailboxBundles) {
    state.bundles[params.mailboxId] = {
      [params.bundleId]: bundle,
    };
  } else {
    state.bundles[params.mailboxId][params.bundleId] = bundle;
  }

  return bundle;
}

export default emailBundlesSlice.reducer;
