import { db } from '../db/db';
import ABTest from '../model/abTest';
import AnalyticsService from './analytics/analyticsService';

// export const EXPENSIVE_PLANS_TEST = 'expensive_plans_test';
// export const CHEAP_MONTHLY_PLAN_TEST = 'cheap_monthly_plan_test';
// export const FREE_DELETES_TEST = 'free_deletes_test';
// export const HIDE_TOTAL_DUE_TEST = 'hide_total_due_test';
// export const HIDE_DELETE_TAB = 'hide_delete_tab';
// export const SHOW_FREE_UNSUBSCRIBES_REMAINING = 'show_free_unsubscribes_remaining';
// export const DIRECT_LOGIN_TEST = 'direct_login_test';
// export const DIRECT_LOGIN_TEST_2 = 'direct_login_test_2';
// export const CHURN_WINBACK_TEST = 'churn_winback_test';
// export const CHURN_WINBACK_TEST_2 = 'churn_winback_test_2';
// export const CHURN_WINBACK_TEST_3 = 'churn_winback_test_3';
// export const SOLID_BUTTONS_TEST = 'solid_buttons_test';
export const DISABLE_UNINSTALL_SURVEY_LIFETIME_DISCOUNT =
  'disable_uninstall_survey_lifetime_discount_test';

export const PRICE_TEST_LIFETIME_PLAN = 'price_test_lifetime_plan_web';

export const A_GROUP = 'A_GROUP';
export const B_GROUP = 'B_GROUP';
export const C_GROUP = 'C_GROUP';
export const D_GROUP = 'D_GROUP';

export default class ABTestingService {
  static async initialize() {
    // this.initializeTest(EXPENSIVE_PLANS_TEST, 2);
    // this.initializeTest(CHEAP_MONTHLY_PLAN_TEST, 4);
    // this.initializeTest(FREE_DELETES_TEST, 2);
    // this.initializeTest(HIDE_TOTAL_DUE_TEST, 2);
    // this.initializeTest(HIDE_DELETE_TAB, 2);
    // this.initializeTest(SHOW_FREE_UNSUBSCRIBES_REMAINING, 2);
    // this.initializeTest(DIRECT_LOGIN_TEST, 2);
    // this.initializeTest(DIRECT_LOGIN_TEST_2, 2);
    // this.initializeTest(CHURN_WINBACK_TEST, 2);
    // this.initializeTest(CHURN_WINBACK_TEST_3, 2);
    // this.initializeTest(SOLID_BUTTONS_TEST, 2);
    this.initializeTest(DISABLE_UNINSTALL_SURVEY_LIFETIME_DISCOUNT, 2);

    // TODO - turn on this test once mobile ads are stabilized
    // this.initializeTest(PRICE_TEST_LIFETIME_PLAN, 2);
  }

  static async initializeTest(test_id: string, bands: number) {
    const testGroup = await this.getOrSetTestGroup(test_id, bands);

    await AnalyticsService.setTestGroup(test_id, testGroup);
  }

  static async getOrSetTestGroup(test_id: string, bands: number): Promise<string> {
    const test = await getTestValueQuery(test_id);

    if (test?.test_value) {
      return test.test_value;
    }

    let testGroup = null;

    const bandSize = 1 / bands;

    const random = Math.random();

    if (random < bandSize * 1) {
      testGroup = A_GROUP;
    } else if (random < bandSize * 2) {
      testGroup = B_GROUP;
    } else if (random < bandSize * 3) {
      testGroup = C_GROUP;
    } else {
      testGroup = D_GROUP;
    }

    await ABTest.create<ABTest>({
      test_id,
      test_value: testGroup,
    });

    return testGroup;
  }
}

export async function getABTest(test_id: string): Promise<ABTest | undefined> {
  const testValue = await getTestValueQuery(test_id);
  return testValue;
}

export const getTestValueQuery = (test_id: string) => db.abTests.get({ test_id });
