import { TRIMBOX_SENTINTEL, UNSUBSCRIBE_TEMPLATE, V4_TAG, V5_TAG, V6_TAG } from './filterHelper';

export function runMigrations(query: string) {
  const version = getQueryVersion(query);
  if (version < 2) {
    query = migration1(query);
  }
  if (version < 3) {
    query = migration2(query);
  }
  if (version < 4) {
    query = migration3(query);
  }
  if (version < 5) {
    query = migration4(query);
  }
  if (version < 6) {
    query = migration5(query);
  }
  return query;
}

export function getQueryVersion(query: string): number {
  const regex = /v(\d+)@trimbox\.io/g;
  const matches = regex.exec(query);
  if (!!matches && matches.length > 1) {
    return parseInt(matches[1]);
  }
  return 1;
}

function migration1(query: string) {
  const isOldFormat = query.includes('list:');
  if (!isOldFormat) return query;

  const newQuery = query.replaceAll('list:', 'from:');
  return newQuery;
}

function migration2(query: string) {
  const isOldFormat = query.startsWith('unsubscribe');
  if (!isOldFormat) return query;

  const newQuery = query.replaceAll('unsubscribe', UNSUBSCRIBE_TEMPLATE);
  return newQuery;
}

function migration3(query: string) {
  const isOldFormat = query.includes(TRIMBOX_SENTINTEL);
  if (!isOldFormat) return query;

  const newQuery = query.replaceAll(TRIMBOX_SENTINTEL, V4_TAG);
  return newQuery;
}

function migration4(query: string) {
  const isOldFormat = !query.includes(V5_TAG);
  if (!isOldFormat) return query;

  const regex = /from:(-\S+@\S+\.\S+)/g;
  let lastMatch;
  let newQuery = query;
  while ((lastMatch = regex.exec(query))) {
    const withQuotes = `"${lastMatch[1]}"`;
    newQuery = newQuery.replace(lastMatch[1], withQuotes);
  }

  newQuery = newQuery.replaceAll(V4_TAG, V5_TAG);
  return newQuery;
}

function migration5(query: string) {
  const isOldFormat = !query.includes(V6_TAG);
  if (!isOldFormat) return query;

  const beginningIndex = query.indexOf('{');
  let newQuery = query.substring(beginningIndex);

  newQuery = newQuery.replaceAll(V5_TAG, V6_TAG);
  return newQuery;
}
