import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { closeAlreadyPaidDialog, selectIsAlreadyPaidDialogOpen } from '../features/ui/uiSlice';
import { grey } from '@mui/material/colors';
import { WHITESMOKE_HEX } from '../theme';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function AlreadyPaidPopup() {
  const isOpen = useAppSelector(selectIsAlreadyPaidDialogOpen);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleDismiss = () => {
    dispatch(closeAlreadyPaidDialog());
  };

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={!!isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDismiss}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          backgroundColor: WHITESMOKE_HEX,
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
              }
            : {
                maxWidth: 'none',
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
        }}
      >
        {t('alreadyPaidTitle')}
      </DialogTitle>
      <DialogContent sx={{ alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography
          color='common.black'
          fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
        >
          {t('alreadyPaidContent')}
        </Typography>
      </DialogContent>
      <Button
        variant='contained'
        size='large'
        onClick={async () => {
          navigate('/unlimited');
        }}
        sx={{
          margin: isExtraSmall
            ? '0.5rem 2rem 0rem 2rem'
            : aboveSmall
            ? '1rem auto 0rem auto'
            : '1rem 2rem 0rem 2rem',
          fontSize: isExtraSmall ? '0.85rem' : '1rem',
          fontWeight: 600,
          borderRadius: '1rem',
          ...(aboveSmall ? { padding: '0.5rem 4rem' } : {}),
        }}
      >
        {t('addAccount')}
      </Button>
      <Button
        variant='text'
        size='small'
        onClick={() => {
          handleDismiss();
        }}
        sx={{
          margin: isExtraSmall ? '0.5rem auto' : '1rem auto',
          fontSize: isExtraSmall ? '0.75rem' : '0.85rem',
          color: grey[700],
          width: 'fit-content',
        }}
      >
        {t('noThanks')}
      </Button>
    </Dialog>
  );
}
export default AlreadyPaidPopup;
