import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { EventName, ErrorDict } from './analyticsProvider';


export default class BingAnalyticsProvider implements AnalyticsProvider {
  public identify(params: IdentifyParams) {
    if (process.env.REACT_APP_TRIMBOX_ENV !== 'production') {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ttq.identify({
      email: params.email_address,
    });
  }

  public alias(id: string) {
    // Not needed
  }

  public track(name: EventName, props?: Dict) {
    // if (process.env.REACT_APP_TRIMBOX_ENV !== 'production') {
    //   return;
    // }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    uetq.push('event', name);
  }

  trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    uetq.push('event', 'purchase', {"revenue_value": checkoutParams.value,"currency": checkoutParams.currency?.toUpperCase()});
  }

  public trackError(name: EventName, errorProps: ErrorDict) {
    // Do not log errors to Bing
  }
}