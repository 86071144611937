import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Box,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  closePaywallDialog,
  openPlansDialog,
  selectIsPaywallDialogOpen,
} from '../features/ui/uiSlice';
import hero from '../assets/inbox-master.svg';
import { grey } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import AnalyticsService from '../services/analytics/analyticsService';
import { EventName } from '../services/analytics/providers/analyticsProvider';
import { WHITESMOKE_HEX } from '../theme';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function PaywallPopup() {
  const isPaywallDialogOpen = useAppSelector(selectIsPaywallDialogOpen);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const handleDismiss = () => {
    AnalyticsService.track(EventName.PAYWALL_DENY);
    dispatch(closePaywallDialog());
  };

  useEffect(() => {
    if (isPaywallDialogOpen) {
      AnalyticsService.track(EventName.PAYWALL_SHOW);
    }
  }, [isPaywallDialogOpen]);

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={!!isPaywallDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDismiss}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          backgroundColor: WHITESMOKE_HEX,
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
              }
            : {
                maxWidth: 'none',
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
        }}
      >
        {t('thanksForTrying')}
      </DialogTitle>
      <DialogContent sx={{ alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography
          color='common.black'
          fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
        >
          {t('relyOnUsers')}
        </Typography>
        <Box
          component='img'
          src={hero}
          sx={{
            maxWidth: isExtraSmall ? '60%' : aboveSmall ? '20rem' : '80%',
            margin: isExtraSmall ? '0.5rem auto' : '2rem auto',
          }}
        />
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: isExtraSmall ? '0.85rem' : aboveSmall ? '1.25rem' : '1rem',
          }}
        >
          {t('unlockUnlimitedAccess')}
        </Typography>
      </DialogContent>
      <Button
        variant='contained'
        size='large'
        onClick={async () => {
          try {
            AnalyticsService.track(EventName.PAYWALL_CONFIRM);
            dispatch(openPlansDialog());
            dispatch(closePaywallDialog());
          } catch (e) {
            enqueueSnackbar(t('somethingWentWrong'), {
              variant: 'error',
              preventDuplicate: true,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              persist: false,
            });
            AnalyticsService.trackError(EventName.CHECKOUT_FAILED, e);
          }
        }}
        sx={{
          margin: isExtraSmall
            ? '0.5rem 2rem 0rem 2rem'
            : aboveSmall
            ? '1rem auto 0rem auto'
            : '1rem 2rem 0rem 2rem',
          fontSize: isExtraSmall ? '0.85rem' : '1rem',
          fontWeight: 600,
          borderRadius: '1rem',
          ...(aboveSmall ? { padding: '0.5rem 4rem' } : {}),
        }}
      >
        {t('upgradeYourInbox')}
      </Button>
      <Button
        variant='text'
        size='small'
        onClick={() => {
          handleDismiss();
        }}
        sx={{
          margin: isExtraSmall ? '0.5rem auto' : '1rem auto',
          fontSize: isExtraSmall ? '0.75rem' : '0.85rem',
          color: grey[700],
          width: 'fit-content',
        }}
      >
        {t('noThanks')}
      </Button>
    </Dialog>
  );
}
export default PaywallPopup;
