import React from 'react';

import { Box, ListItemIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SubscriptionProperties } from '../../../../../model/subscription';
import { LIGHTER_GRAY_HEX, WHITE_HEX } from '../../../../../theme';
import { ExampleEmailListActionButton } from './ExampleEmailListActionButton';
import { EmailListUnsubscribeStatus } from '../../../../../features/emailLists/emailLists.types';
import { MOCKED_LIST_PROPERTIES } from './ExampleEmailList.types';
import { useTranslation } from 'react-i18next';
import kayakLogo from '../../../../../assets/favicons/kayak.png';
import macysLogo from '../../../../../assets/favicons/macys.png';
import bestbuyLogo from '../../../../../assets/favicons/bestbuy.png';
import facebookLogo from '../../../../../assets/favicons/facebook.png';
import siriusLogo from '../../../../../assets/favicons/sirius.png';
import { LandingPageFlavorEnum } from '../welcomeFlavors.types';

export type ExampleEmailListItemProps = SubscriptionProperties & {
  currentState: EmailListUnsubscribeStatus;
  landingPageFlavor: LandingPageFlavorEnum;
};

export function getExampleEmailListItemHeight(aboveSmall: boolean, isExtraSmall: boolean): number {
  return isExtraSmall ? 114.51 : aboveSmall ? 124.63 : 133.31;
}

export function getNumberOfExampleEmailLists(
  container: Element,
  aboveSmall: boolean,
  isExtraSmall: boolean
) {
  const containerHeight = container.clientHeight;
  const dummyListItemHeight = getExampleEmailListItemHeight(aboveSmall, isExtraSmall);
  const numberToFit = containerHeight / dummyListItemHeight;
  return numberToFit > MOCKED_LIST_PROPERTIES.length ? MOCKED_LIST_PROPERTIES.length : numberToFit;
}

export function ExampleEmailListItem(props: ExampleEmailListItemProps) {
  const { email_address, display_name, message_count, currentState } = props;

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const avatarSize: number = isExtraSmall ? 30 : aboveSmall ? 44 : 36;

  const { t } = useTranslation();

  const renderIcon = () => {
    return (
      <>
        {email_address === 'deals@kayak.com' && (
          <Box
            component='img'
            src={kayakLogo}
            sx={{
              height: avatarSize,
              width: avatarSize,
              borderRadius: '0.25rem',
            }}
          />
        )}
        {email_address === 'sales@macys.com' && (
          <Box
            component='img'
            src={macysLogo}
            sx={{
              height: avatarSize,
              width: avatarSize,
              borderRadius: '0.25rem',
            }}
          />
        )}
        {email_address === 'promotions@bestbuy.com' && (
          <Box
            component='img'
            src={bestbuyLogo}
            sx={{
              height: avatarSize,
              width: avatarSize,
              borderRadius: '0.25rem',
            }}
          />
        )}
        {email_address === 'notifications@facebook.com' && (
          <Box
            component='img'
            src={facebookLogo}
            sx={{
              height: avatarSize,
              width: avatarSize,
              borderRadius: '0.25rem',
            }}
          />
        )}
        {email_address === 'hello@siriusxm.com' && (
          <Box
            component='img'
            src={siriusLogo}
            sx={{
              height: avatarSize,
              width: avatarSize,
              borderRadius: '0.25rem',
            }}
          />
        )}
      </>
    );
  };

  return (
    <Box
      key={email_address}
      sx={{
        backgroundColor: WHITE_HEX,
        borderRadius: '0.5rem',
        padding: isExtraSmall ? '0.5rem 1rem' : '1rem',
        display: 'flex',
        marginBottom: '1rem',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adds slight elevation
        transition: 'box-shadow 0.3s ease-in-out', // Smooth shadow transition for hover
        ...(aboveSmall ? { flexDirection: 'row' } : { flexDirection: 'column' }),
        '&:hover': {
          boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.15)', // Slightly more elevation on hover
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...(aboveSmall ? { flexGrow: 1, marginRight: '1rem' } : { marginBottom: '1rem' }),
          minWidth: 0,
          overflow: 'hidden',
        }}
      >
        <ListItemIcon
          sx={{
            marginRight: isExtraSmall ? '0.5rem' : '1rem',
            height: 'fit-content',
            minWidth: 'unset',
          }}
        >
          {renderIcon()}
        </ListItemIcon>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            ...(aboveSmall
              ? {
                  flexDirection: 'column',
                  alignItems: 'start',
                  overflow: 'hidden',
                }
              : {
                  flexDirection: 'row',
                  alignItems: 'center',
                }),
          }}
        >
          <Box
            sx={{
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'start',
              ...(aboveSmall
                ? { marginBottom: '0.5rem', display: 'flex' }
                : { marginRight: '1rem', display: 'grid' }),
            }}
          >
            <Typography
              noWrap
              sx={{
                fontWeight: 600,
                textAlign: 'start',
                fontSize: aboveSmall ? '1rem' : '0.85rem',
              }}
            >
              {display_name}
            </Typography>
            <Typography variant='caption' noWrap sx={{ textAlign: 'start' }}>
              {email_address}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant='caption'
              sx={{
                borderRadius: '1rem',
                backgroundColor: LIGHTER_GRAY_HEX,
                padding: '0.15rem 0.75rem',
                whiteSpace: 'nowrap',
              }}
            >
              {t('xEmails', { count: message_count })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: isExtraSmall ? '0.5rem' : '1rem',
          ...(aboveSmall
            ? {
                alignSelf: 'center',
              }
            : {
                justifyContent: 'space-between',
              }),
          alignItems: 'center',
        }}
      >
        <ExampleEmailListActionButton
          currentState={currentState}
          actionType={'keep'}
          landingPageFlavor={props.landingPageFlavor}
        />
        <ExampleEmailListActionButton
          currentState={currentState}
          actionType={'unsubscribe'}
          landingPageFlavor={props.landingPageFlavor}
        />
      </Box>
    </Box>
  );
}
