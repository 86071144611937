export const UTM_SOURCE_KEY = 'trimbox_utm_source';

export const TRIMBOX_REDIRECT_KEY = 'trimbox_redirect';

export const UNINSTALL_SURVEY_QUESTION_1 = 'trimbox_uninstall_survey_q1';

export const UNINSTALL_SURVEY_ACTIVE_STEP = 'trimbox_uninstall_survey_step';

export const CHURN_SURVEY_QUESTION_1 = 'trimbox_churn_survey_q1';

export const CHURN_SURVEY_ACTIVE_STEP = 'trimbox_churn_survey_step';

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/trimbox-email-inbox-cleaner/id6466923521';

export const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.trimbox.app';
