import React, { useCallback, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { performBundleDelete, selectBundleDeleteStatus } from './emailBundlesSlice';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { HIDE_EMAIL_BUNDLE_BUTTON_ICONS } from '../../featureFlags';
import { getCurrentMailbox } from '../../model/mailbox';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { useTranslation } from 'react-i18next';
import { usePaywall } from '../../providers/PaywallProvider';

export type EmailBundleDeleteButtonProps = {
  bundleId: number;
  bundleDisplayName: string;
  messageCount?: number;
  mailboxId: string;
};

export function EmailBundleDeleteButton(props: EmailBundleDeleteButtonProps) {
  const deleteStatus = useAppSelector((state) =>
    selectBundleDeleteStatus(state, { bundleId: props.bundleId, mailboxId: props.mailboxId })
  );

  const { enqueueSnackbar } = useSnackbar();
  const [recentlyDeleted, setRecentlyDeleted] = useState(false);

  const setRecentlyDeletedTimer = () => {
    setRecentlyDeleted(true);
    setTimeout(() => setRecentlyDeleted(false), 5000);
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const { shouldShowPaywallWhenUnsubscribing } = usePaywall();

  const isLoading = deleteStatus === 'deleting';
  const isComplete = deleteStatus === 'deleted' && (!props.messageCount || recentlyDeleted);
  const noDeleteOption = !deleteStatus && !props.messageCount;
  const color = isComplete ? 'primary' : 'red';

  let buttonText = t('deleteAll');
  if (isLoading) {
    buttonText = t('deleting');
  } else if (isComplete) {
    buttonText = t('deleted');
  }

  const handleClick = useCallback(async () => {
    const showErrorToast = (errorMessage: string) => {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        preventDuplicate: true,
        persist: false,
      });
    };

    if (isLoading || recentlyDeleted) {
      return;
    }

    AnalyticsService.track(EventName.CLICK_DELETE_BUNDLE);
    const mailbox = await getCurrentMailbox();
    dispatch(
      performBundleDelete(
        mailbox,
        {
          bundleId: props.bundleId,
          bundleName: props.bundleDisplayName,
        },
        showErrorToast,
        setRecentlyDeletedTimer,
        shouldShowPaywallWhenUnsubscribing
      )
    );
  }, [
    dispatch,
    enqueueSnackbar,
    isLoading,
    recentlyDeleted,
    props.bundleId,
    props.bundleDisplayName,
    shouldShowPaywallWhenUnsubscribing,
  ]);

  return (
    <LoadingButton
      variant={isComplete || isLoading ? 'text' : 'outlined'}
      color={color}
      size={aboveSmall ? 'medium' : 'small'}
      sx={{
        fontWeight: 600,
        minWidth: 'fit-content',
      }}
      disabled={noDeleteOption}
      startIcon={
        isLoading ? (
          <CheckIcon />
        ) : isComplete ? (
          <CheckIcon />
        ) : (
          !HIDE_EMAIL_BUNDLE_BUTTON_ICONS && <DeleteIcon />
        )
      }
      onClick={handleClick}
      loading={isLoading}
      loadingPosition='start'
    >
      {buttonText}
    </LoadingButton>
  );
}
