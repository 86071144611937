import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { useLiveQuery } from 'dexie-react-hooks';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { openUserOptionsDrawer, selectTab, setTab } from '../../features/ui/uiSlice';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import banner_image from '../../assets/banner_image.svg';
import { PRIMARY_COLOR_HEX } from '../../theme';
import { getCurrentMailboxQuery } from '../../model/mailbox';
import UserOptionsDrawer from './UserOptionsDrawer';
import StreakBadge from './StreakBadge';
import { selectSessionListCount } from '../../features/emailLists/emailListsSlice';
import { useTranslation } from 'react-i18next';
import { SHOW_BULK_DELETE_TAB } from '../../featureFlags';

export const knownAdmins: string[] = [
  'jordangaston1994@gmail.com',
  'dsemelianov@gmail.com',
  'trimbox.test.01@gmail.com',
];

function ResponsiveAppBar() {
  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const selectedTab = useAppSelector(selectTab);

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const sessionListCount = useAppSelector(selectSessionListCount);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  return (
    <AppBar
      elevation={0}
      position='sticky'
      color='ghostWhite'
      sx={{
        padding: '0.5rem 0rem 0rem 0rem',
        borderBottom: '1px solid lightgrey',
        paddingBottom: SHOW_BULK_DELETE_TAB ? 0 : '0.5rem',
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ ...(isExtraSmall ? { minHeight: '48px' } : {}) }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <Box
              component='img'
              src={banner_image}
              sx={{
                height: aboveSmall ? 44 : isExtraSmall ? 24 : 32,
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {sessionListCount && <StreakBadge sessionListCount={sessionListCount}></StreakBadge>}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Avatar
              src={mailbox?.avatar_url}
              alt={mailbox?.first_name}
              sx={{
                backgroundColor: PRIMARY_COLOR_HEX,
                width: aboveSmall ? 44 : 32,
                height: aboveSmall ? 44 : 32,
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: '4px 4px 4px 0px rgba(0,0,0,0.16)',
                },
              }}
              onClick={() => dispatch(openUserOptionsDrawer())}
            >
              {mailbox?.first_name?.[0]}
            </Avatar>
          </Box>
        </Toolbar>
      </Container>
      {SHOW_BULK_DELETE_TAB && (
        <Tabs
          value={selectedTab}
          onChange={(event, value) => {
            dispatch(setTab(value));
          }}
          textColor='primary'
          indicatorColor='primary'
          sx={{ marginLeft: isExtraSmall ? '1rem' : '2rem' }}
        >
          <Tab
            value='lists'
            label={t('unsubscribe')}
            sx={{
              fontWeight: 600,
            }}
          />
          <Tab
            value='bundles'
            label={t('delete')}
            sx={{
              fontWeight: 600,
            }}
          />
        </Tabs>
      )}
      <UserOptionsDrawer></UserOptionsDrawer>
    </AppBar>
  );
}
export default ResponsiveAppBar;
