import React from 'react';

import { Box, ListItemIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LIGHTER_GRAY_HEX, WHITE_HEX } from '../../theme';
import { EmailBundleConfig, SUPPORTED_EMAIL_BUNDLES_IDS } from './emailBundles.configs';
import { EmailBundleDeleteButton } from './EmailBundleDeleteButton';
import { db } from '../../db/db';
import { useLiveQuery } from 'dexie-react-hooks';
import ElderlyIcon from '@mui/icons-material/Elderly';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SaveIcon from '@mui/icons-material/Save';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useTranslation } from 'react-i18next';

export type EmailBundleItemProps = {
  bundleConfig: EmailBundleConfig;
  mailboxId: string;
};

export function EmailBundleItem(props: EmailBundleItemProps) {
  const bundleConfig = props.bundleConfig;

  const bundle = useLiveQuery(() =>
    db.bundles.get({
      mailbox_id: props.mailboxId,
      bundle_id: bundleConfig.bundleId,
    })
  );

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: WHITE_HEX,
        marginBottom: '1rem',
        borderRadius: '0.5rem',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <ListItemIcon
          sx={{
            marginRight: '1rem',
            height: 'fit-content',
            minWidth: 'unset',
          }}
        >
          {bundleConfig.bundleId === SUPPORTED_EMAIL_BUNDLES_IDS.MAILING_LISTS ? (
            <FormatListBulletedIcon width={aboveSmall ? 50 : 30} />
          ) : bundleConfig.bundleId === SUPPORTED_EMAIL_BUNDLES_IDS.OLDER_THAN_1_YEAR ? (
            <ElderlyIcon width={aboveSmall ? 50 : 30} />
          ) : bundleConfig.bundleId === SUPPORTED_EMAIL_BUNDLES_IDS.OLDER_THAN_6_MONTHS ? (
            <AccessTimeFilledIcon width={aboveSmall ? 50 : 30} />
          ) : bundleConfig.bundleId === SUPPORTED_EMAIL_BUNDLES_IDS.LARGER_THAN_1_MB ? (
            <SaveIcon width={aboveSmall ? 50 : 30} />
          ) : bundleConfig.bundleId ===
            SUPPORTED_EMAIL_BUNDLES_IDS.ATTACHMENTS_LARGER_THAN_10_MB ? (
            <AttachEmailIcon width={aboveSmall ? 50 : 30} />
          ) : bundleConfig.bundleId === SUPPORTED_EMAIL_BUNDLES_IDS.NOT_YOU ? (
            <PersonOffIcon width={aboveSmall ? 50 : 30} />
          ) : null}
        </ListItemIcon>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'start',
          }}
        >
          <Box
            sx={{
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'start',
              marginRight: '1rem',
              marginBottom: '0.5rem',
              display: 'flex',
            }}
          >
            <Typography
              variant={aboveSmall ? 'h6' : 'body1'}
              sx={{
                fontWeight: 600,
                textAlign: 'start',
                fontSize: aboveSmall ? '1rem' : '0.85rem',
              }}
            >
              {bundleConfig.displayName}
            </Typography>
          </Box>
          <Typography
            variant='caption'
            sx={{
              borderRadius: '1rem',
              backgroundColor: LIGHTER_GRAY_HEX,
              padding: '0rem 0.75rem',
              whiteSpace: 'nowrap',
            }}
          >
            {t('xEmails', { count: bundle?.message_count || 0 })}
          </Typography>
        </Box>
      </Box>
      <EmailBundleDeleteButton
        bundleId={bundleConfig.bundleId}
        bundleDisplayName={bundleConfig.displayName}
        messageCount={bundle?.message_count}
        mailboxId={props.mailboxId}
      />
    </Box>
  );
}
