export default class TokenBucket {
  private tokensPerInterval: number;
  private intervalMillis: number;
  private tokenCount: number;
  private lastRefillTime: number;

  constructor(params: { tokensPerInterval: number; intervalMillis: number }) {
    this.tokensPerInterval = params.tokensPerInterval;
    this.intervalMillis = params.intervalMillis;
    this.tokenCount = params.tokensPerInterval;
    this.lastRefillTime = Date.now();
  }

  async take(tokenCount: number) {
    if (tokenCount > this.tokensPerInterval) {
      throw Error(`Token count cannot exceed the max bucket size`);
    }

    this.refillBucket();

    if (this.tokenCount >= tokenCount) {
      this.tokenCount = this.tokenCount - tokenCount;
      return;
    }

    return new Promise((resolve) =>
      setTimeout(resolve, this.millisTillRefill(tokenCount - this.tokenCount))
    );
  }

  private millisTillRefill(tokenCount: number) {
    const millisPerToken = this.intervalMillis / this.tokensPerInterval;
    return Math.ceil(millisPerToken * tokenCount);
  }

  private refillBucket() {
    const now = Date.now();
    const elaspedMillis: number = Math.max(now - this.lastRefillTime, 0);
    this.tokenCount = Math.min(
      this.tokensPerInterval,
      this.tokenCount + Math.ceil(elaspedMillis * (this.tokensPerInterval / this.intervalMillis))
    );
    this.lastRefillTime = now;
  }
}
