import { db } from '../db/db';
import ModelBase from './base';
import BundleMembership from './bundleMembership';
import Bundle from './emailBundle';
import Subscription from './subscription';

export default class Message extends ModelBase {
  public static table = 'messages';

  public received_at: number;
  public subscription_id: number;
  public bundle_ids: string[];
  public external_id: string;
  public external_thread_id: string;
  public mailbox_id: string;

  constructor(params: {
    received_at: number;
    subscription_id: number;
    bundle_ids: string[];
    external_id: string;
    external_thread_id: string;
    mailbox_id: string;
  }) {
    super();
    this.received_at = params.received_at;
    this.subscription_id = params.subscription_id;
    this.bundle_ids = params.bundle_ids;
    this.external_id = params.external_id;
    this.external_thread_id = params.external_thread_id;
    this.mailbox_id = params.mailbox_id;
  }

  static async deleteMessages(messages: Message[]) {
    const messageIds = messages.map((message) => message.external_id);
    await this.deleteMessagesById(messageIds);
  }

  static async deleteMessagesById(messageIds: string[]) {
    await db.transaction('rw', db.messages, db.bundleMemberships, async () => {
      await db.messages.where('external_id').anyOf(messageIds).delete();
      await db.bundleMemberships.where('message_id').anyOf(messageIds).delete();
      // TODO: update bundle counts upon membership deletion
    });
  }

  async addToBundles(bundles: Bundle[]) {
    db.transaction('rw', db.bundles, db.bundleMemberships, async () => {
      await BundleMembership.createAll(
        bundles.map((bundle) => {
          return { bundle_id: bundle.bundle_id, message_id: this.external_id };
        })
      );
      for (const bundle of bundles) {
        await bundle.update({ message_count: (bundle.message_count ?? 0) + 1 });
      }
    });
  }

  async subscription(): Promise<Subscription | undefined> {
    if (!this.subscription_id) {
      return;
    }

    return await Subscription.find(this.subscription_id);
  }
}
