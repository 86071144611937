import React from 'react';

import Typography from '@mui/material/Typography';
import { Stack, useTheme, useMediaQuery, Button, Box } from '@mui/material';
import { GHOST_WHITE_HEX } from '../theme';
import { FallbackProps } from 'react-error-boundary';
import { getErrorStack } from '../utilities/errorHelper';
import banner_image from '../assets/banner_image.svg';
import { useTranslation } from 'react-i18next';

type ErrorParams = {
  emoji: string;
  header: string;
  subHeader?: string;
  reloadButtonText: string;
  showStack: boolean;
};

function ErrorFallback(props: FallbackProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  let errorParams: ErrorParams = {
    emoji: `😣`,
    header: t('somethingWentWrong'),
    reloadButtonText: 'Reload',
    showStack: true,
  };

  if (isExpectedError(props.error)) {
    errorParams = {
      emoji: `👋`,
      header: t('welcomeBack'),
      subHeader: t('readyToKeepCleaning') || undefined,
      reloadButtonText: t('keepCleaning'),
      showStack: false,
    };
  }

  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={{
        backgroundColor: GHOST_WHITE_HEX,
      }}
    >
      <Box
        margin={2}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 44 : 36,
        }}
      />
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{
          minHeight: errorParams.showStack ? '60vh' : '90vh',
        }}
      >
        <Typography
          fontWeight={600}
          sx={{
            fontSize: isExtraSmall ? '3rem' : aboveSmall ? '6rem' : '4rem',
          }}
        >
          {errorParams.emoji}
        </Typography>
        <Typography
          textAlign='center'
          fontWeight={600}
          sx={{
            fontSize: isExtraSmall ? '1.5rem' : aboveSmall ? '3rem' : '1.5rem',
          }}
        >
          {errorParams.header}
        </Typography>
        {errorParams.subHeader && (
          <Typography
            textAlign='center'
            sx={{
              marginTop: '0.5rem',
              fontSize: isExtraSmall ? '1rem' : aboveSmall ? '2rem' : '1rem',
            }}
          >
            {errorParams.subHeader}
          </Typography>
        )}
        <Button
          variant='contained'
          size='medium'
          color='googleBlue'
          sx={{
            margin: isExtraSmall ? '4rem 0rem' : '5rem 0rem',
            fontSize: isExtraSmall ? '1rem' : '1.25rem',
          }}
          onClick={() => {
            window.location.reload();
          }}
        >
          {errorParams.reloadButtonText}
        </Button>
      </Stack>
      {errorParams.showStack && (
        <Stack
          direction='column'
          sx={{ alignItems: 'center', maxWidth: '90%', overflow: 'hidden' }}
        >
          <Typography
            fontWeight={500}
            sx={{
              fontSize: isExtraSmall ? '0.75rem' : '1rem',
              marginBottom: '0.5rem',
            }}
          >
            {t('errorDetails')}
          </Typography>
          <Typography
            sx={{
              fontSize: isExtraSmall ? '0.75rem' : '1rem',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {getErrorStack(props.error)}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

function isExpectedError(error: Error): boolean {
  return error.message.includes("Attempt to iterate a cursor that doesn't exist");
}

export default ErrorFallback;
