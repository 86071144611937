import React from 'react';

import { Container, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch } from '../app/hooks';
import { WHITESMOKE_HEX } from '../theme';
import { useTranslation } from 'react-i18next';
import { NUMBER_OF_FREE_UNSUBSCRIBES } from '../featureFlags';
import { openPaywallDialog } from '../features/ui/uiSlice';
import { useLiveQuery } from 'dexie-react-hooks';
import { getCurrentMailboxQuery } from '../model/mailbox';
import { usePaywall } from '../providers/PaywallProvider';

function FreeUnsubscribesIndicator() {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const mailbox = useLiveQuery(getCurrentMailboxQuery);
  const numberOfUnsubscribes = mailbox?.unsubscribe_count || 0;

  const numberOfUnsubscribesRemaining = NUMBER_OF_FREE_UNSUBSCRIBES - numberOfUnsubscribes;

  const { hasPremium } = usePaywall();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (NUMBER_OF_FREE_UNSUBSCRIBES == -1) {
    return null;
  }

  if (numberOfUnsubscribesRemaining < 0) {
    return null;
  }

  if (hasPremium) {
    return null;
  }

  const proportion = (numberOfUnsubscribes / NUMBER_OF_FREE_UNSUBSCRIBES) * 100;

  const containerSx = {
    position: 'fixed',
    bottom: aboveSmall ? '1rem' : '0.5rem',
    background: WHITESMOKE_HEX,
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    border: '1px solid black',
    boxShadow: '10px 10px 4px -3px rgba(0,0,0,0.17);',
    ...(aboveSmall
      ? {
          width: '20rem',
          right: '2rem',
        }
      : {
          left: '50%',
          transform: 'translateX(-50%)',
          width: '95%',
          maxWidth: '95%',
        }),
  };

  const textSx = {
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'center',
    fontSize: '1rem',
  };

  return (
    <Container
      maxWidth='xl'
      sx={containerSx}
      onClick={() => {
        dispatch(openPaywallDialog());
      }}
    >
      <LinearProgress
        sx={{
          maxWidth: '20rem',
          margin: '0.5rem auto',
          width: '100%',
          borderRadius: '1rem',
          height: '0.5rem',
        }}
        value={proportion}
        variant={'determinate'}
      />
      <Typography noWrap sx={textSx}>
        {t('freeUnsubscribesRemaining', { count: numberOfUnsubscribesRemaining })}
      </Typography>
    </Container>
  );
}

export default FreeUnsubscribesIndicator;
