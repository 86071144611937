import React from 'react';

import {
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { WHITESMOKE_HEX } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export type ManagePopupProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

function ManagePopup(props: ManagePopupProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const { isOpen, onDismiss } = props;

  return (
    <Dialog
      open={!!isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDismiss}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          backgroundColor: WHITESMOKE_HEX,
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: isExtraSmall ? '1.25rem' : '1.5rem',
          fontWeight: 600,
          textAlign: 'center',
          ...(aboveSmall
            ? {}
            : {
                padding: '8px 24px',
              }),
        }}
      >
        {t('manageSubscription')}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: aboveSmall ? 'row' : 'column',
          ...(aboveSmall ? { gap: '1rem', padding: '2rem' } : { paddingBottom: '1rem' }),
        }}
      >
        <Typography
          color='common.black'
          fontSize={isExtraSmall ? '0.85rem' : aboveSmall ? '1.15rem' : '1rem'}
          textAlign='center'
          marginBottom='1.5rem'
        >
          {t('manageSubscriptionAppStore')}
        </Typography>
      </DialogContent>

      <IconButton
        aria-label='close'
        size='small'
        sx={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}
        onClick={onDismiss}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
export default ManagePopup;
