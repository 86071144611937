import {
  PlanInterval,
  PlanType,
  PlanTypePresentable,
  TotalDueAmounts,
  getAmountWithDiscount,
} from './offeringSlice.types';

export function getPercentOff(
  allPlans: PlanTypePresentable[],
  plan: PlanTypePresentable
): number | null {
  if (plan.interval != 'year') {
    return null;
  }
  const monthlyPlan = allPlans.find((plan) => {
    return plan.interval == 'month';
  });
  if (!monthlyPlan) {
    return null;
  }
  const pricePerMonth = plan.amountWithoutDiscount / 12;
  const savingPerMonth = monthlyPlan.amountWithoutDiscount - pricePerMonth;
  const discount = (savingPerMonth / monthlyPlan.amountWithoutDiscount) * 100;
  return Math.round(discount);
}

export function getTotalDueAmounts(
  allPlans: PlanTypePresentable[],
  plan: PlanTypePresentable,
  discountPercentage?: number
): TotalDueAmounts {
  const comparisonPrice = getComparisonPrice(allPlans, plan);

  return {
    totalDue: getAmountWithDiscount(discountPercentage, plan.amountWithoutDiscount),
    ...(comparisonPrice
      ? {
          comparisonPrice,
        }
      : {}),
  };
}

export function getComparisonPrice(
  allPlans: PlanTypePresentable[],
  plan: PlanTypePresentable
): number | null {
  if (plan.interval != 'year') {
    return null;
  }
  const monthlyPlan = allPlans.find((plan) => {
    return plan.interval == 'month';
  });
  if (!monthlyPlan) {
    return null;
  }
  const monthlyPlanAnnualized = monthlyPlan.amountWithoutDiscount * 12;
  return monthlyPlanAnnualized;
}

export function getTodayPrice(plan: PlanType, discountPercentage: number | undefined): number {
  const todayPrice = plan.interval == 'year' ? plan.amount / 12 : plan.amount;
  const todayPriceWithDiscounts = getAmountWithDiscount(discountPercentage, todayPrice);
  return todayPriceWithDiscounts;
}

export function convertPrice(
  plan: PlanType,
  interval: PlanInterval,
  discountPercentage: number | undefined
): number {
  const convertedPrice = convertPriceInterval(plan, interval);
  const todayPriceWithDiscounts = getAmountWithDiscount(discountPercentage, convertedPrice);
  return todayPriceWithDiscounts;
}

function convertPriceInterval(plan: PlanType, interval: PlanInterval): number {
  if (plan.interval === interval) {
    return plan.amount;
  }
  if (plan.interval === 'free') {
    return 0;
  }
  if (plan.interval === 'lifetime' || interval === 'lifetime') {
    if (plan.interval !== interval) {
      throw new Error('Cannot convert non-lifetime plan to lifetime pricing');
    }
    return plan.amount;
  }
  if (plan.interval === 'month' && interval == 'year') {
    return plan.amount * 12;
  }
  if (plan.interval === 'year' && interval == 'month') {
    return plan.amount / 12;
  }
  throw new Error('Unrecognized conversion!');
}

export function comparisonPrice(
  plan: PlanType,
  interval: PlanInterval,
  plans: PlanType[],
  discountPercentage: number | undefined
): number {
  if (plan.interval === 'free') {
    return 0;
  }
  if (discountPercentage) {
    return plan.amount;
  }
  if (plan.interval === interval) {
    return plan.amount;
  }
  const directPurchasePlan = plans.find((p) => p.interval === interval);
  if (directPurchasePlan) {
    return directPurchasePlan.amount;
  }
  return plan.amount;
}
