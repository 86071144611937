import top_right_corner from '../../assets/top_right_corner.svg';
import top_left_corner from '../../assets/top_left_corner.svg';
import bottom_left_corner from '../../assets/bottom_left_corner.svg';
import bottom_right_corner from '../../assets/bottom_right_corner.svg';
import bottom_right_corner_padded from '../../assets/bottom_right_corner_padded.svg';

export enum MoreOptionsCorner {
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  BOTTOM_RIGHT_PADDED,
}

export enum MoreOptionsIcon {
  DOTS_H,
  DOTS_V,
  SHARE,
}

export function getCornerAsset(corner: MoreOptionsCorner) {
  if (corner === MoreOptionsCorner.TOP_LEFT) {
    return top_left_corner;
  } else if (corner === MoreOptionsCorner.TOP_RIGHT) {
    return top_right_corner;
  } else if (corner === MoreOptionsCorner.BOTTOM_LEFT) {
    return bottom_left_corner;
  } else if (corner === MoreOptionsCorner.BOTTOM_RIGHT_PADDED) {
    return bottom_right_corner_padded;
  } else {
    return bottom_right_corner;
  }
}

export type DeviceOS = 'ios' | 'android';
