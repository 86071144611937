import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import AnalyticsService from '../services/analytics/analyticsService';
import { UTM_SOURCE_KEY } from '../constants';

export function useGetURLParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userEmailFromUrl, setUserEmail] = useState<string>();

  const sessionId = searchParams.get('session_id');
  useEffect(() => {
    if (!sessionId) {
      return;
    }
    searchParams.delete('session_id');
    setSearchParams(searchParams);
  }, [sessionId]);

  const loginLinkId = searchParams.get('login_link_id');
  useEffect(() => {
    if (!loginLinkId) {
      return;
    }
    setUserEmail(loginLinkId);
    searchParams.delete('login_link_id');
    setSearchParams(searchParams);
  }, [loginLinkId]);

  const utm_source = searchParams.get('utm_source');

  useEffect(() => {
    if (!utm_source) {
      return;
    }

    localStorage.setItem(UTM_SOURCE_KEY, utm_source);

    if (utm_source === 'Facebook') {
      AnalyticsService.setFacebookUser();
    }
  }, [utm_source]);

  const email = searchParams.get('email');
  useEffect(() => {
    if (!email) {
      return;
    }

    setUserEmail(email);
  }, [email]);

  return { userEmail: userEmailFromUrl };
}
