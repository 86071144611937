import React from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectTab } from '../../features/ui/uiSlice';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import MailboxSyncIndicator from '../MailboxSyncIndicator';
import { SUPPORT_LIST_SORTING } from '../../featureFlags';
import { selectSort, setDrawerOpen } from '../../features/emailLists/emailListsSlice';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useTranslation } from 'react-i18next';

function TabHeader() {
  const selectedTab = useAppSelector(selectTab);
  const sortOrder = useAppSelector(selectSort);

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const showListSorting = selectedTab === 'lists' && SUPPORT_LIST_SORTING;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        margin: aboveSmall ? '1rem 0rem 0rem 0rem' : '0.5rem 0rem 0rem 0rem',
      }}
    >
      <Typography
        color='common.black'
        fontSize={isExtraSmall ? '0.9rem' : aboveSmall ? '1.4rem' : '1rem'}
        sx={{
          padding: '0.5rem 0rem',
          borderRadius: '0.25rem',
          fontWeight: 600,
          width: 'fit-content',
          textAlign: 'start',
        }}
      >
        {t(selectedTab === 'lists' ? 'unsubscribeFromLists' : 'deleteEmailsInBulk')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <MailboxSyncIndicator mode='compact'></MailboxSyncIndicator>
        </Box>
        {showListSorting && (
          <Button
            size='small'
            variant='text'
            color='gray'
            sx={{
              width: 'fit-content',
              alignSelf: 'center',
              whiteSpace: 'nowrap',
            }}
            startIcon={<ImportExportIcon />}
            onClick={() => {
              dispatch(setDrawerOpen(true));
            }}
          >
            {t(sortOrder)}
          </Button>
        )}
      </Box>
    </Stack>
  );
}
export default TabHeader;
