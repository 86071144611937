import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import { useLiveQuery } from 'dexie-react-hooks';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  closeUserOptionsDrawer,
  openPlansDialog,
  selectUserOptionsDrawer,
  showProgressDialog,
} from '../../features/ui/uiSlice';
import Drawer from '@mui/material/Drawer';
import { List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { PRIMARY_COLOR_HEX, WHITESMOKE_HEX } from '../../theme';
import { db } from '../../db/db';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { useSnackbar } from 'notistack';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { logoutUser } from '../../features/user/userSlice';
import { getCurrentMailboxQuery, getOtherMailboxesQuery } from '../../model/mailbox';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userHasActiveLicenses, userHasIAPSubscription } from '../../utilities/paywallHelper';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const knownAdmins: string[] = [
  'jordangaston1994@gmail.com',
  'dsemelianov@gmail.com',
  'trimbox.test.01@gmail.com',
];

function UserOptionsDrawer() {
  const mailbox = useLiveQuery(getCurrentMailboxQuery);
  const otherMailboxes = useLiveQuery(getOtherMailboxesQuery);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const showDeleteDbOption =
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === 'development' ||
    (!!mailbox?.email_address && knownAdmins.includes(mailbox.email_address));

  const drawerOpen = useAppSelector(selectUserOptionsDrawer);

  const navigate = useNavigate();

  return (
    <Drawer
      anchor='right'
      open={drawerOpen}
      onClose={() => dispatch(closeUserOptionsDrawer())}
      sx={{ backgroundColor: 'primary' }}
    >
      <List>
        {mailbox?.email_address && (
          <Stack
            style={{
              flexDirection: 'column',
              backgroundColor: WHITESMOKE_HEX,
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            <ListItemButton
              onClick={() => {
                dispatch(closeUserOptionsDrawer());
              }}
            >
              <ListItemIcon>
                <Avatar
                  src={mailbox?.avatar_url}
                  alt={mailbox?.first_name}
                  sx={{
                    backgroundColor: PRIMARY_COLOR_HEX,
                    width: 32,
                    height: 32,
                  }}
                >
                  {mailbox.first_name?.[0]}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={mailbox.email_address}
                primaryTypographyProps={{ sx: { fontWeight: 600 } }}
              />
            </ListItemButton>
            {otherMailboxes?.map((otherMailbox) => {
              return (
                <ListItemButton
                  onClick={async () => {
                    await otherMailbox.setCurrent();
                    dispatch(closeUserOptionsDrawer());
                  }}
                  key={otherMailbox.email_address}
                >
                  <ListItemIcon>
                    <Avatar
                      src={otherMailbox?.avatar_url}
                      alt={otherMailbox?.first_name}
                      sx={{
                        backgroundColor: PRIMARY_COLOR_HEX,
                        width: 28,
                        height: 28,
                      }}
                    >
                      {otherMailbox?.first_name?.[0]}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={otherMailbox.email_address} />
                </ListItemButton>
              );
            })}
            <ListItemButton
              onClick={() => {
                mailbox.setNotCurrent();
                dispatch(closeUserOptionsDrawer());
              }}
            >
              <ListItemIcon>
                <AddCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('addAccount')} />
            </ListItemButton>
          </Stack>
        )}
        <ListItemButton
          onClick={async () => {
            try {
              if (!mailbox) {
                throw new Error('Mailbox not found!');
              }

              const user = await mailbox.getUser$();

              if (userHasActiveLicenses(user)) {
                navigate('/unlimited');
              } else {
                const hasRCEntitlement = await userHasIAPSubscription(mailbox.email_address);

                if (hasRCEntitlement) {
                  enqueueSnackbar(t('visitAppStore'), {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: { horizontal: 'center', vertical: 'top' },
                    persist: false,
                  });
                } else {
                  dispatch(closeUserOptionsDrawer());
                  dispatch(openPlansDialog());
                }
              }
            } catch (e) {
              enqueueSnackbar(t('somethingWentWrong'), {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                persist: false,
              });
              AnalyticsService.trackError(EventName.MANAGE_PREMIUM_FAILED, e);
            }
          }}
        >
          <ListItemIcon>
            <WorkspacePremiumIcon />
          </ListItemIcon>
          <ListItemText primary={t('manageSubscription')} />
        </ListItemButton>
        {mailbox && (
          <ListItemButton
            onClick={async () => {
              const showErrorToast = (errorMessage: string) => {
                enqueueSnackbar(errorMessage, {
                  variant: 'error',
                  preventDuplicate: true,
                  anchorOrigin: { horizontal: 'center', vertical: 'top' },
                  persist: false,
                });
              };
              dispatch(showProgressDialog('progressOnly', mailbox, showErrorToast));
              dispatch(closeUserOptionsDrawer());
            }}
          >
            <ListItemIcon>
              <StackedLineChartIcon />
            </ListItemIcon>
            <ListItemText primary={t('viewProgress')} />
          </ListItemButton>
        )}
        <ListItemButton
          onClick={() => {
            window.open('https://trimbox.io/support');
          }}
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={t('getSupport')} />
        </ListItemButton>
        <ListItemButton
          onClick={async () => {
            dispatch(logoutUser());
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('logOut')} />
        </ListItemButton>
        {showDeleteDbOption ? (
          <ListItemButton
            onClick={async () => {
              dispatch(logoutUser());
              await db.delete();
              window.location.reload();
            }}
          >
            <ListItemIcon>
              <DangerousIcon />
            </ListItemIcon>
            <ListItemText primary='Clear db' />
          </ListItemButton>
        ) : (
          <></>
        )}
      </List>
    </Drawer>
  );
}
export default UserOptionsDrawer;
