import React from 'react';

import { useTheme, useMediaQuery, Box, Typography } from '@mui/material';
import google_wordmark from '../../../../assets/google_wordmark_small.png';
import { useTranslation } from 'react-i18next';

function CertificationBadge() {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: isExtraSmall ? '0rem' : aboveSmall ? '1rem' : '0.5rem',
        marginBottom: '0rem',
        alignItems: 'center',
        ...(aboveSmall ? { maxWidth: '30rem', padding: '0rem 2rem' } : {}),
        background: 'rgba(256, 256, 256, 0.5)',
        padding: aboveSmall ? '0.5rem 1rem' : '0.25rem 0.75rem',
        borderRadius: '4rem',
      }}
    >
      {/* <LockIcon
          sx={{ marginRight: '0.5rem' }}
          fontSize={isExtraSmall ? 'small' : aboveSmall ? 'large' : 'medium'}
          color='black'
        /> */}
      <Typography
        fontWeight={400}
        whiteSpace='nowrap'
        fontSize={isExtraSmall ? '0.7rem' : aboveSmall ? '0.9rem' : '0.8rem'}
      >
        {t('certifiedBy')}
      </Typography>
      <Box
        height={isExtraSmall ? '0.9rem' : aboveSmall ? '1.25rem' : '1rem'}
        marginLeft={aboveSmall ? '0.5rem' : '0.35rem'}
        component='img'
        src={google_wordmark}
      ></Box>
    </Box>
  );
}

export default CertificationBadge;
