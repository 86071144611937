import React from 'react';

import { List, Stack, useMediaQuery, useTheme } from '@mui/material';
import MailboxSyncIndicator from '../../components/MailboxSyncIndicator';
import { EmailBundleItem } from './EmailBundleItem';
import { SUPPORTED_EMAIL_BUNDLES } from './emailBundles.configs';

export type DeleteTabProps = {
  mailboxId: string;
};

export function DeleteTab(props: DeleteTabProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <List
        sx={{
          margin: aboveSmall ? '1rem 0rem' : '0.5rem 0rem',
          padding: 0,
        }}
      >
        {SUPPORTED_EMAIL_BUNDLES.map((bundleConfig) => (
          <EmailBundleItem
            key={bundleConfig.bundleId}
            bundleConfig={bundleConfig}
            mailboxId={props.mailboxId}
          ></EmailBundleItem>
        ))}
        <MailboxSyncIndicator></MailboxSyncIndicator>
      </List>
    </>
  );
}
