import React, { useEffect, useState } from 'react';

import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Stack,
  TextField,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  closeChurnDialog,
  openPlansDialog,
  selectIsChurnDialogOpen,
  setHasGivenFeedback,
} from '../../features/ui/uiSlice';
import { grey } from '@mui/material/colors';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { PRIMARY_COLOR_HEX, PURPLE_HEX, WHITESMOKE_HEX, WHITE_HEX } from '../../theme';
import { useTranslation } from 'react-i18next';
import {
  QUESTION_1,
  getFeedbackDiscountCode,
  question1ToDiscountTitleMapping,
  question1ToQuestion2Mapping,
  radioOptions,
} from '../../app/useGetChurnFeedback';
import ConfettiFirework from '../ConfettiFirework';
import DiscountIcon from '@mui/icons-material/Discount';
import { getCurrentMailbox } from '../../model/mailbox';
import { CHURN_SURVEY_QUESTION_1, CHURN_SURVEY_ACTIVE_STEP } from '../../constants';
import StyledStepper from '../../screens/UninstallPage/StyledStepper';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function ChurnPopup() {
  const isChurnDialogOpen = useAppSelector(selectIsChurnDialogOpen);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDismiss = () => {
    AnalyticsService.track(EventName.CHURN_DENY);
    dispatch(closeChurnDialog());
  };

  useEffect(() => {
    if (isChurnDialogOpen) {
      AnalyticsService.track(EventName.CHURN_SHOW);
    }
  }, [isChurnDialogOpen]);

  const [activeStep, setActiveStep] = React.useState(0);

  const [question1, setQuestion1] = useState<QUESTION_1>();
  const [question2, setQuestion2] = useState<string>();

  useEffect(() => {
    if (!question1) {
      return;
    }

    localStorage.setItem(CHURN_SURVEY_QUESTION_1, question1);
  }, [question1]);

  useEffect(() => {
    if (!activeStep) {
      return;
    }

    localStorage.setItem(CHURN_SURVEY_ACTIVE_STEP, activeStep.toString());
  }, [activeStep]);

  useEffect(() => {
    const stored_question_1 = localStorage.getItem(CHURN_SURVEY_QUESTION_1) as QUESTION_1;
    if (stored_question_1) {
      setQuestion1(stored_question_1);
    }

    const stored_step_string = localStorage.getItem(CHURN_SURVEY_ACTIVE_STEP);
    if (stored_step_string) {
      const stored_step_num: number = parseInt(stored_step_string);
      setActiveStep(stored_step_num);
    }
  }, []);

  const handleRadioChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined = (event) => {
    setQuestion1(event.target.value as QUESTION_1);
  };

  const handleMultiLineTextChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined = (event) => {
    setQuestion2(event.target.value);
  };

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  const promoCoupon = getFeedbackDiscountCode(question1);
  const offerLifetime = promoCoupon.withLifetime;

  const claimDiscount = () => {
    AnalyticsService.track(EventName.CHURN_CLAIM_DISCOUNT);

    dispatch(closeChurnDialog());
    dispatch(openPlansDialog());
  };

  return (
    <Dialog
      open={!!isChurnDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDismiss}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          backgroundColor: WHITESMOKE_HEX,
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
                maxWidth: '800px',
              }
            : {
                maxWidth: 'none',
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
        }}
      >
        {t('churnHeader')}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 0,
        }}
      >
        <Typography
          color='common.black'
          fontSize={isExtraSmall ? '0.85rem' : aboveSmall ? '1.2rem' : '1rem'}
        >
          {t('churnSubHeader')}
        </Typography>
        <Box sx={{ mt: '2rem', mx: '-1rem' }}>
          <StyledStepper activeStep={activeStep} />
        </Box>
        <Stack
          sx={{
            display: 'flex',
            gap: aboveMedium ? 6 : 2,
            justifyContent: 'center',
            pt: aboveSmall ? 4 : 2,
            pb: aboveSmall ? 4 : 0,
            mt: 0,
            alignItems: 'center',
            px: aboveSmall ? 2 : 0,
          }}
        >
          {activeStep == 0 && (
            <>
              <FormControl component='fieldset'>
                <Typography
                  variant='h5'
                  color='text.primary'
                  fontWeight={700}
                  component='p'
                  fontSize={aboveMedium ? '1.2rem' : '1.0rem'}
                  mb={'1rem'}
                >
                  {t('churn_q1')}
                </Typography>
                <RadioGroup
                  aria-label='options'
                  name='row-radio-buttons-group'
                  value={question1}
                  onChange={handleRadioChange}
                >
                  {radioOptions.map((radioOption, i) => (
                    <FormControlLabel
                      key={i}
                      value={radioOption.value}
                      label={t(radioOption.labelResource)}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                onClick={() => {
                  setActiveStep(1);
                }}
                size='large'
                variant='contained'
                color='primary'
                disabled={!question1}
                sx={{
                  fontSize: aboveSmall ? '1.25rem' : '1rem',
                }}
              >
                {t('next')}
              </Button>
            </>
          )}
          {activeStep == 1 && (
            <>
              <FormControl component='fieldset'>
                <Typography
                  variant='h5'
                  align='center'
                  color='text.primary'
                  fontWeight={700}
                  component='p'
                  fontSize={aboveMedium ? '1.2rem' : '1.0rem'}
                  mb={'1rem'}
                >
                  {question1 && t(question1ToQuestion2Mapping[question1])}
                </Typography>
                <TextField
                  label={t('typeYourAnswerHere')}
                  multiline
                  rows={4}
                  value={question2}
                  onChange={handleMultiLineTextChange}
                  variant='outlined'
                  fullWidth
                  margin='normal'
                />
              </FormControl>
              <Button
                onClick={async () => {
                  setActiveStep(2);
                  AnalyticsService.track(EventName.CHURN_CONFIRM, {
                    question1: question1,
                    question2: question2,
                  });

                  dispatch(setHasGivenFeedback());

                  const mailbox = await getCurrentMailbox();
                  await mailbox?.setHasGivenFeedback();
                }}
                size='large'
                variant='contained'
                color='primary'
                disabled={!question2}
                sx={{
                  fontSize: aboveSmall ? '1.25rem' : '1rem',
                }}
              >
                {t('next')}
              </Button>
            </>
          )}
          {activeStep == 2 && (
            <>
              <Typography
                variant='body2'
                align='center'
                color='text.primary'
                component='p'
                fontSize={aboveMedium ? '1.2rem' : '1.0rem'}
              >
                {question1 && t(question1ToDiscountTitleMapping[question1])}
              </Typography>
              <Chip
                icon={<DiscountIcon color='white' fontSize='small' />}
                onClick={() => {
                  claimDiscount();
                }}
                sx={{
                  background: `linear-gradient(120deg,${PRIMARY_COLOR_HEX} 13.4%,${PURPLE_HEX} 86.6%)`,
                  color: WHITE_HEX,
                  fontWeight: 600,
                  borderRadius: 8,
                  mt: 2,
                  mb: 4,
                  padding: aboveSmall || !offerLifetime ? 4 : 3,
                  fontSize: aboveSmall ? '1.6rem' : offerLifetime ? '0.9rem' : '1.4rem',
                }}
                label={t(offerLifetime ? 'xOffLifetime' : 'xOffAllPlans', {
                  percentage: getFeedbackDiscountCode(question1).percentOff * 100,
                })}
                className={'animate__animated animate__tada'}
              />
              <ConfettiFirework shouldFire={true}></ConfettiFirework>
              <Button
                onClick={() => {
                  claimDiscount();
                }}
                size='large'
                variant='contained'
                color='primary'
                sx={{
                  fontSize: aboveSmall ? '1.25rem' : '1rem',
                }}
              >
                {t('claimYourOffer')}
              </Button>
            </>
          )}
        </Stack>
      </DialogContent>
      {!aboveSmall && (
        <Button
          variant='text'
          size='small'
          onClick={() => {
            handleDismiss();
          }}
          sx={{
            margin: isExtraSmall ? '0.5rem auto' : '1rem auto',
            fontSize: isExtraSmall ? '0.75rem' : '0.85rem',
            color: grey[700],
            width: 'fit-content',
          }}
        >
          {t('notNow')}
        </Button>
      )}
    </Dialog>
  );
}
export default ChurnPopup;
