import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Stack, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import theme, { GRAY_HEX, LIGHT_GRAY_HEX, WHITE_HEX } from '../../theme';
import TopBar from './TopBar';
import SignInButton from './SignInButton';
import LoadingPage from '../../components/Loading/LoadingPage';
import { useLoadMailboxAndLicenses } from './useLoadMailboxAndLicenses';
import {
  HeaderAndSubheaderInfo,
  useGetHeaderAndSubheaderInfo,
} from './useGetHeaderAndSubheaderInfo';
import LicenseManager from './LicenseManager';
import { useGetSourceFromURL } from './useGetSourceFromURL';
import ConfettiFirework from '../../components/ConfettiFirework';
import LicensesPageButtons from './LicensePageButtons';
import top_right_corner from '../../assets/top_right_corner.svg';
import { usePurchaseTracking } from './usePurchaseTracking';

export default function LicensesPage() {
  /*
    - Show a loading state while fetching user subscription info
    - If the user is not signed in, prompt them to sign in
    - If the user is signed in and has Stripe Unlimited that they own, show them a page where they can manage (link to Stripe) or add accounts
    - If the user is signed in and has Stripe Unlimited that they DO NOT own, show them a page where they can unlink their account
    - If the user is signed in and has App/Play Unlimited, show a message that they can manage on the App Store and add accounts in the app
    - If the user is signed in and doesn't have Unlimited, redirect to the pricing page to upsell Unlimited
*/

  usePurchaseTracking();
  const { isChromeExtension, gmailLink } = useGetSourceFromURL();

  const { licenseStatus, hasAccessToken, mailbox, isMailboxQueryPending } =
    useLoadMailboxAndLicenses();
  const headerInfo: HeaderAndSubheaderInfo | undefined = useGetHeaderAndSubheaderInfo(
    hasAccessToken,
    licenseStatus,
    isChromeExtension
  );

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const navigate = useNavigate();

  const renderWebAppButtons = useCallback(() => {
    return (
      <LicensesPageButtons
        primaryButtonText='keepCleaning'
        primaryButtonClick={() => {
          navigate('/');
        }}
        secondaryButtonText='needHelp'
        secondaryButtonClick={() => {
          window.open('https://trimbox.io/support');
        }}
      />
    );
  }, []);

  const renderChromeExtensionButtons = useCallback(() => {
    return (
      <LicensesPageButtons
        primaryButtonText='backToGmail'
        primaryButtonClick={() => {
          if (gmailLink) {
            window.location.href = gmailLink;
          } else {
            window.location.href = 'https://mail.google.com/';
          }
        }}
        secondaryButtonText='useTheApp'
        secondaryButtonClick={() => {
          navigate('/');
        }}
      />
    );
  }, []);

  const renderMainContent = useCallback(() => {
    if (!hasAccessToken) {
      return <SignInButton buttonText={t('signInWithGoogle')}></SignInButton>;
    }

    if (!licenseStatus) {
      return;
    }

    const { hasActiveStripeLicense, hasActiveIAPLicense } = licenseStatus;

    if (hasActiveStripeLicense) {
      return (
        <Stack gap={4} alignItems={'center'}>
          <Stack gap={4} direction={'row'}>
            {isChromeExtension ? renderChromeExtensionButtons() : renderWebAppButtons()}
          </Stack>
          <Stack style={{ backgroundColor: LIGHT_GRAY_HEX, height: '1px', width: '100%' }} />
          <LicenseManager licenseStatus={licenseStatus} mailbox={mailbox!} />
        </Stack>
      );
    } else if (!hasActiveIAPLicense) {
      return (
        <Stack maxWidth={'600px'}>
          <Stack gap={4} alignSelf={'center'}>
            <Button
              variant={'contained'}
              color='primary'
              size='large'
              sx={{
                fontWeight: 700,
              }}
              onClick={async () => {
                if (!hasActiveStripeLicense && !hasActiveIAPLicense) {
                  navigate('/pricing');
                }
              }}
            >
              {t('upgradeYourInbox')}
            </Button>
            <Button
              variant={'outlined'}
              color='darkGray'
              size='large'
              sx={{
                fontWeight: 700,
              }}
              onClick={async () => {
                mailbox?.logout();
              }}
            >
              {t('useDifferentAccount')}
            </Button>
          </Stack>
          <Box
            margin={2}
            component='img'
            src={top_right_corner}
            sx={{
              height: '100px',
            }}
          />
          <Typography textAlign={'center'} color={GRAY_HEX}>
            {t('alreadyPaidDifferentAccount')}
          </Typography>
        </Stack>
      );
    }
  }, [hasAccessToken, licenseStatus]);

  if (isMailboxQueryPending || (hasAccessToken && !licenseStatus)) {
    return <LoadingPage />;
  }

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: WHITE_HEX,
      }}
    >
      <TopBar hasAccessToken={hasAccessToken} licenseStatus={licenseStatus} mailbox={mailbox} />

      <Container
        disableGutters
        maxWidth='md'
        component='main'
        sx={{
          pt: aboveSmall ? 8 : 2,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {headerInfo?.headerText && (
          <Typography
            component='h1'
            variant='h2'
            fontWeight={800}
            align='center'
            color='text.primary'
            gutterBottom
            mb={aboveSmall ? '2rem' : '1rem'}
            maxWidth={aboveMedium ? 'unset' : '80%'}
            fontSize={aboveMedium ? '3rem' : aboveSmall ? '2.5rem' : '2rem'}
          >
            {t(headerInfo.headerText)}
          </Typography>
        )}
        {headerInfo?.subheaderText && (
          <Typography
            variant='h5'
            align='center'
            color='text.primary'
            component='p'
            gutterBottom
            fontSize={aboveMedium ? '1.2rem' : '1rem'}
          >
            {t(headerInfo.subheaderText)}
          </Typography>
        )}
      </Container>
      <Box
        sx={{
          display: 'flex',
          gap: aboveMedium ? 6 : 2,
          flexWrap: 'wrap',
          justifyContent: 'center',
          pt: aboveSmall ? 4 : 2,
          pb: aboveSmall ? 6 : 4,
          px: 2,
          mb: aboveSmall ? 4 : 0,
        }}
      >
        {renderMainContent()}
      </Box>
      <ConfettiFirework
        shouldFire={licenseStatus?.hasActiveStripeLicense || licenseStatus?.hasActiveIAPLicense}
      ></ConfettiFirework>
    </Stack>
  );
}
