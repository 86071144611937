import React from 'react';

import './LoadingPage.scss';
import { CircularProgress, Stack } from '@mui/material';
import { GHOST_WHITE_HEX } from '../../theme';

function LoadingPage() {
  const vh = window.innerHeight * 0.01;

  // Set the CSS variable to the root element
  // Which is equal to 1vh
  document.documentElement.style.setProperty('--vh', vh + 'px');

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      className='full-height-stack'
      sx={{
        backgroundColor: GHOST_WHITE_HEX,
      }}
    >
      <CircularProgress />
    </Stack>
  );
}
export default LoadingPage;
