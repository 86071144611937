import ModelBase from './base';

export default class BundleMembership extends ModelBase {
  public static table = 'bundleMemberships';

  public bundle_id: string;
  public message_id: string;

  constructor(bundle_id: string, message_id: string) {
    super();
    this.bundle_id = bundle_id;
    this.message_id = message_id;
  }
}
