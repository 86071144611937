import ModelBase from './base';

export default class ABTest extends ModelBase {
  public static table = 'abTests';

  public test_id: string;
  public test_value: string;

  constructor(params: { test_id: string; test_value: string }) {
    super();
    this.test_id = params.test_id;
    this.test_value = params.test_value;
  }
}
