import React from 'react';

import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BLACK_HEX } from '../../theme';

export type PlanBulletProps = {
  text: string;
};

function PlanBullet(props: PlanBulletProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...(aboveSmall ? { marginTop: '1rem' } : { marginBottom: '0.5rem' }),
      }}
    >
      <CheckCircleIcon sx={{ fontSize: '1rem', color: BLACK_HEX, marginRight: '0.5rem' }} />
      <Typography fontSize={'1rem'} color={BLACK_HEX} fontWeight='500'>
        {props.text}
      </Typography>
    </Box>
  );
}
export default PlanBullet;
