import { UnsubscribeInfo } from '../services/gmail/unsubscribeInfo/unsubscribeInfoAdapter';

export type SubscriptionParams = {
  email_address: string;
  display_name: string;
  message_count?: number;
  newest_message_received_at?: number;
  oldest_message_received_at?: number;
  unsubscribe_status?: UnsubscribeStatus;
  mailbox_id: string;
  list_image_url?: string;
  unsubscribe_info: UnsubscribeInfo;
  last_nonce?: string;
};

export enum UnsubscribeStatus {
  Subscribed,
  Unsubscribed,
  Kept,
}
