import { Offering, PromoCode } from '../features/offering/offeringSlice.types';
import Credential from '../model/credential';

type OfflineConversionType = 'ext_offline_install' | 'ext_offline_purchase';

type TrackOfflineConversionRequest = {
  type: OfflineConversionType;
  gclid: string;
  occuredAt: number;
  value?: number;
  currency?: string;
};

type UpdateUserRequest = {
  id: string;
  incrementUnsubscribeCountBy?: number;
  incrementTrashedThreadCountBy?: number;
};

type GetOfferingRequest = {
  id: string;
};

type GetPromoCode = {
  id: string;
};

export type PriceParams = {
  amount: number;
  currency: string;
};

type CreatePaymentIntentRequest = {
  price: PriceParams;
};

export type CheckoutPriceParams = PriceParams & {
  interval: string;
};

type CreateCheckoutSessionRequest = {
  userId?: string;
  email?: string;
  returnUrl: string;
  cancelUrl?: string;
  source: 'web' | 'extension' | 'mobile';
  code?: string;
  price: CheckoutPriceParams;

  trialPeriodDays?: number;
};

type CreateBillingSessionRequest = {
  userId: string;
  lang: string;
  returnUrl?: string;
};

type SendLicenseAccessRequestRequest = {
  requester: string;
  owner: string;
};

type SendEmailLoginLinkRequest = {
  email: string;
  urlParams: string;
};

type ActiveLicenses = { [id: string]: boolean };

export type UserResponse = {
  email: string;
  id: string;
  trashedThreadCount?: number;
  unsubscribeCount?: number;
  activeLicenses?: ActiveLicenses;
};

export type License = {
  authorizedUsers: AuthorizedUsers;
  id: string;
  stripeSubscriptionId?: string;
  type?: LicenseTerm;
  owner: string;
};

export type AuthorizedUsersWithEmails = {
  [userId: string]: { active: boolean | null; email?: string };
};

export type AuthorizedUsers = { [x: string]: boolean | null };

export type LicenseTerm = 'yearly' | 'lifetime' | 'monthly';

export default class TrimboxServerClient {
  private origin: string;

  constructor() {
    const origin: string | undefined = process.env.REACT_APP_SERVER_URI;
    if (!origin) throw new Error('[TrimboxServerClient] server URI is missing!');

    this.origin = origin;
  }


  async trackOfflineConversion(request: TrackOfflineConversionRequest) {
    const req = new Request(`${this.origin}/v1/offline_conversions`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    });
    const res = await fetch(req);
    if (res.ok) return

    throw new Error(
      `[TrimboxServerClient] Failed while attempting to track offline conversion with status ${res.status}`
    );
  }

  async getCheckoutSession(sessionId: string, credential: Credential) {
    const req = new Request(`${this.origin}/v1/stripe/checkouts/${sessionId}`, {
      headers: { Authorization: `Bearer ${credential.openIDToken}` },
      method: 'GET',
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else if (res.status === 404) {
      return null;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch checkout session ${sessionId} with status ${res.status}`
      );
    }
  }

  async findUser(params: { email: string; credential?: Credential; adminToken?: string }) {
    const headers: Record<string, string> = {};
    if (params.credential) {
      headers['Authorization'] = `Bearer ${params.credential.openIDToken}`;
    } else if (params.adminToken) {
      headers['X-TRIMBOX-API-KEY'] = params.adminToken;
    } else {
      throw new Error('credential or admin token must be provided');
    }

    const req = new Request(`${this.origin}/v1/users?email=${params.email}`, {
      method: 'GET',
      headers,
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else if (res.status === 404) {
      return null;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch user with status ${res.status}`
      );
    }
  }

  async getUser(id: string, credential: Credential): Promise<UserResponse | null> {
    const req = new Request(`${this.origin}/v1/users/${id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${credential.openIDToken}` },
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else if (res.status === 404) {
      return null;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch user with status ${res.status}`
      );
    }
  }

  async createUser(params: { email: string; credential?: Credential; adminToken?: string }) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
    if (params.credential) {
      headers['Authorization'] = `Bearer ${params.credential.openIDToken}`;
    } else if (params.adminToken) {
      headers['X-TRIMBOX-API-KEY'] = params.adminToken;
    } else {
      throw new Error('credential or admin token must be provided');
    }

    const req = new Request(`${this.origin}/v1/users`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email: params.email, lastSeenInWebAt: Date.now() }),
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to create user with status ${res.status}`
      );
    }
  }

  async updateUser(request: UpdateUserRequest, credential: Credential) {
    const req = new Request(`${this.origin}/v1/users/${request.id}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        incrementUnsubscribeCountBy: request.incrementUnsubscribeCountBy,
        incrementTrashedThreadCountBy: request.incrementTrashedThreadCountBy,
        lastSeenInWebAt: Date.now(),
      }),
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to increment user trashed thread count ${res.status}`
      );
    }
  }

  async fetchOffering(request: GetOfferingRequest, credential: Credential): Promise<Offering> {
    const req = new Request(`${this.origin}/v1/offering/${request.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
      },
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).offering as Offering;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch offering ${res.status}`
      );
    }
  }

  async fetchAnonymousOffering(): Promise<Offering> {
    const req = new Request(`${this.origin}/v1/offering`, {
      method: 'GET',
      headers: {},
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).offering as Offering;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch offering ${res.status}`
      );
    }
  }

  // redirectToCheckoutSession(request: CreateCheckoutSessionRequest): string {
  //   let checkoutSessionUrl = `${this.origin}/v1/promotions?email=${request.userId}&license=${request.license}&source=web`;

  //   if (request.promoCode) {
  //     checkoutSessionUrl += `&code=${request.promoCode}`;
  //   }

  //   checkoutSessionUrl += `&returnUrl=${request.returnUrl}`;

  //   return checkoutSessionUrl;
  // }

  async createCheckoutSession(
    request: CreateCheckoutSessionRequest
  ): Promise<{ location: string; sessionId: string }> {
    const req = new Request(`${this.origin}/v1/stripe/checkouts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to create checkout session with status ${res.status}`
      );
    }
  }

  async createBillingSettingsSession(request: CreateBillingSessionRequest, credential: Credential) {
    const req = new Request(`${this.origin}/v1/stripe/settings/billing`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to create billing settings session with status ${res.status}`
      );
    }
  }

  async listLicenses(userId: string, credential: Credential): Promise<License[]> {
    const req = new Request(`${this.origin}/v1/users/${userId}/licenses`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).licenses as License[];
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch listLicenses with status ${res.status}`
      );
    }
  }

  async getActiveLicenseInfo(
    userId: string,
    credential: Credential
  ): Promise<AuthorizedUsersWithEmails> {
    const req = new Request(`${this.origin}/v1/users/${userId}/licenses/activeLicenseInfo`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).authorizedUsers as AuthorizedUsersWithEmails;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch getActiveLicenseInfo with status ${res.status}`
      );
    }
  }

  async addUserToLicense(
    request: {
      currentUserId: string;
      userToAddEmail: string;
    },
    credential: Credential
  ): Promise<License> {
    const { currentUserId, userToAddEmail } = request;
    const req = new Request(`${this.origin}/v1/users/${currentUserId}/licenses/addUser`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userToAddEmail,
      }),
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).license as License;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to addUserToLicense with status ${res.status}`
      );
    }
  }

  async removeUserFromLicense(
    request: {
      currentUserId: string;
      userToRemoveEmail: string;
    },
    credential: Credential
  ): Promise<License> {
    const { currentUserId, userToRemoveEmail } = request;
    const req = new Request(`${this.origin}/v1/users/${currentUserId}/licenses/removeUser`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userToRemoveEmail,
      }),
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).license as License;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to removeUserFromLicense with status ${res.status}`
      );
    }
  }

  async unlinkUserFromLicenses(userId: string, credential: Credential) {
    const req = new Request(`${this.origin}/v1/users/${userId}/licenses/unlinkLicenses`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
    });
    const res = await fetch(req);
    if (res.ok) {
      return;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to removeUserFromLicense with status ${res.status}`
      );
    }
  }

  async sendLicenseAccessRequest(request: SendLicenseAccessRequestRequest, credential: Credential) {
    const req = new Request(`${this.origin}/v1/access_requests`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const res = await fetch(req);
    if (res.ok) {
      return;
    } else if (res.status === 404) {
      throw new Error(`Customer not found!`);
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to send license access request with status ${res.status}`
      );
    }
  }

  async sendEmailLoginLink(email: string) {
    let queryString = window.location.search;
    if (queryString.startsWith('?')) {
      queryString = queryString.slice(1);
    }

    const params: SendEmailLoginLinkRequest = {
      email,
      urlParams: queryString,
    };

    const req = new Request(`${this.origin}/v1/login_link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const res = await fetch(req);
    if (res.ok) {
      return;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while sending login link email with status ${res.status}`
      );
    }
  }

  async createPaymentIntent(
    request: CreatePaymentIntentRequest,
    credential: Credential
  ): Promise<{ clientSecret: string }> {
    const req = new Request(`${this.origin}/v1/stripe/payment_intent`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${credential.openIDToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const res = await fetch(req);
    if (res.ok) {
      return await res.json();
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to create payment intent with status ${res.status}`
      );
    }
  }

  async fetchPromoCode(request: GetPromoCode): Promise<PromoCode> {
    const req = new Request(`${this.origin}/v1/stripe/promo/${request.id}`, {
      method: 'GET',
    });
    const res = await fetch(req);
    if (res.ok) {
      return ((await res.json()) as any).promoCode as PromoCode;
    } else {
      throw new Error(
        `[TrimboxServerClient] Failed while attempting to fetch promo code ${res.status}`
      );
    }
  }
}
