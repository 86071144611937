export type SenderInfo = {
  senderName?: string;
  senderEmail: string;
};

export class SenderInfoAdapter {
  async adapt(headers: gapi.client.gmail.MessagePartHeader[]): Promise<SenderInfo> {
    const fromHeader = headers.find((header: gapi.client.gmail.MessagePartHeader) => {
      return header.name?.toLowerCase() === 'from';
    });
    if (!fromHeader?.value) {
      throw new Error('[Gmail] from header not found');
    }

    try {
      // Taken from this SO answer: https://stackoverflow.com/a/46181
      const emailMatcher =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailMatcher.test(fromHeader.value)) {
        return { senderEmail: fromHeader.value };
      }
      const tokens = fromHeader.value.includes('<')
        ? fromHeader.value.split('<')
        : fromHeader.value.split('(');
      const name = tokens[0].trim().replace(/"/g, '');
      const email = tokens[1].split(fromHeader.value.includes('<') ? '>' : ')')[0];
      return { senderName: name.trim(), senderEmail: email };
    } catch (e) {
      throw new Error('Unable to parse header!');
    }
  }
}
