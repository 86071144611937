import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { ErrorDict, EventName } from './analyticsProvider';

export default class FacebookAnalyticsProvider implements AnalyticsProvider {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public identify(params: IdentifyParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    fbq('track', 'CompleteRegistration');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public alias(id: string) {
    // Not needed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public track(name: EventName, props?: Dict) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    fbq('trackCustom', name);
  }

  public trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    fbq('track', 'Purchase', checkoutParams);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public trackError(name: EventName, errorProps: ErrorDict) {
    // Do not log errors to Facebook
  }
}
