import { db } from '../db/db';
import ModelBase from './base';
import Credential, { isExpired, refresh } from './credential';
import Subscription from './subscription';
import { Collection } from 'dexie';
import Bundle from './emailBundle';
import { SubscriptionParams } from './subscription.types';
import { BundleParams } from './emailBundle.types';
import Message from './message';
import {
  EmailProviderListMessage,
  EmailProviderMessage,
  isEmailProviderListMessage,
} from './emailProviderMessage';
import EmailProvider from '../services/emailProvider';
import GmailEmailProvider from '../services/gmail/gmailEmailProvider';
import TrimboxServerClient, { UserResponse } from '../services/serverClient';
import { MailboxStatus } from '../services/mailboxSynchronizer/mailboxSynchronizer.types';
import { UserStats } from '../features/user/userSlice.types';

export default class Mailbox extends ModelBase {
  public static table = 'mailboxes';

  public email_address: string;
  public user_id: string;
  public first_name: string;
  public last_name: string;
  public avatar_url: string;
  public credential?: Credential;
  public last_sync_started_at?: number | undefined;
  public last_sync_finished_at?: number | undefined;
  public has_given_feedback?: boolean;
  // This has to be a number, because Dexie can't index on booleans
  public is_current?: number;
  public unsubscribe_trash?: boolean;
  public unsubscribe_do_not_ask?: boolean;
  public keep_trash?: boolean;
  public keep_do_not_ask?: boolean;

  public sync_status?: MailboxStatus;

  public unsubscribe_count?: number;
  public delete_count?: number;

  constructor(params: {
    emailAddress: string;
    userId: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    credential?: Credential;
    lastSyncStartedAt?: number;
    lastSyncFinishedAt?: number;
  }) {
    super();
    this.email_address = params.emailAddress;
    this.user_id = params.userId;
    this.first_name = params.firstName;
    this.last_name = params.lastName;
    this.avatar_url = params.avatarUrl;
    this.credential = params.credential;
    this.last_sync_started_at = params.lastSyncStartedAt;
    this.last_sync_finished_at = params.lastSyncFinishedAt;
  }

  static async createZapMailbox(): Promise<Mailbox | undefined> {
    if (process.env.REACT_APP_TRIMBOX_ENV !== 'pentest') {
      return;
    }

    const existingMailbox = await this.find('trimbox.test.01@gmail.com');
    if (existingMailbox) {
      return;
    }

    const server = new TrimboxServerClient();
    let user = await server.findUser({
      email: 'trimbox.test.01@gmail.com',
      adminToken: process.env.REACT_APP_ADMIN_API_KEY,
    });
    if (!user) {
      user = await server.createUser({
        email: 'trimbox.test.01@gmail.com',
        adminToken: process.env.REACT_APP_ADMIN_API_KEY,
      });
    }
    const refreshToken: string | undefined = process.env.REACT_APP_REFRESH_TOKEN;
    if (!refreshToken) {
      throw Error('[Mailbox] refresh token is not set');
    }

    const mailbox: Mailbox = await this.create<Mailbox>({
      is_current: 1,
      email_address: 'trimbox.test.01@gmail.com',
      user_id: user.userId,
      first_name: 'Johnny',
      last_name: 'Doe',
      avatar_url: 'https://avatars.dicebear.com/api/avataaars/trimbox.svg',
      credential: {
        openIDToken: 'invalidToken',
        refreshToken,
        expiresAt: Date.now(),
        scopes: [
          'openid',
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/gmail.modify',
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/gmail.settings.basic',
        ],
      },
    });
    const success = await mailbox.refreshLogin();
    if (!success) {
      throw new Error('[Mailbox] failed to refresh login for pentest user');
    }

    return mailbox;
  }

  emailProvider(): EmailProvider {
    return GmailEmailProvider.create();
  }

  async createSubscription(message: EmailProviderListMessage): Promise<Subscription> {
    return await this.addSubscription({
      email_address: message.senderEmail,
      display_name: message.senderName || message.senderEmail,
      list_image_url: message.senderImageUrl,
      message_count: 1,
      newest_message_received_at: message.receivedAt,
      oldest_message_received_at: message.receivedAt,
      unsubscribe_info: message.unsubscribeInfo,
    });
  }

  async addMessage(message: EmailProviderMessage): Promise<Message> {
    if (isEmailProviderListMessage(message)) {
      let subscription: Subscription | undefined = await this.findSubscription(message.senderEmail);
      if (!subscription) {
        subscription = await this.createSubscription(message);
      }
      return await subscription.addMessage(message);
    }

    return await Message.create<Message>({
      external_id: message.id,
      external_thread_id: message.threadId,
      received_at: message.receivedAt,
      mailbox_id: this.email_address,
    });
  }

  async subscriptionCount(): Promise<number> {
    return db.subscriptions.where({ mailbox_id: this.email_address }).count();
  }

  async subscriptions(): Promise<Collection<Subscription, string>> {
    return db.subscriptions.where({ mailbox_id: this.email_address });
  }

  async findSubscription(senderEmail: string): Promise<Subscription | undefined> {
    return await db.subscriptions.get({
      mailbox_id: this.email_address,
      email_address: senderEmail,
    });
  }

  async addSubscription(params: Omit<SubscriptionParams, 'mailbox_id'>): Promise<Subscription> {
    return await Subscription.create<Subscription>({
      ...params,
      mailbox_id: this.email_address,
    });
  }

  async findBundle(bundle_id: number): Promise<Bundle | undefined> {
    return await db.bundles.get({
      mailbox_id: this.email_address,
      bundle_id: bundle_id,
    });
  }

  async addBundle(params: Omit<BundleParams, 'mailbox_id'>): Promise<Bundle> {
    return await Bundle.create<Bundle>({
      ...params,
      mailbox_id: this.email_address,
    });
  }

  async updateUser(request: { unsubscribeCount?: number; deleteCount?: number }) {
    const client = new TrimboxServerClient();
    return await client.updateUser(
      {
        id: this.user_id,
        incrementTrashedThreadCountBy: request.deleteCount,
        incrementUnsubscribeCountBy: request.unsubscribeCount,
      },
      await this.getCredential$()
    );
  }

  async getUser$(): Promise<UserResponse> {
    const user: UserResponse | null = await this.getUser();
    if (!user) {
      throw new Error('User not found');
    }

    return user;
  }

  async getUser(): Promise<UserResponse | null> {
    const client = new TrimboxServerClient();
    const userResponse = await client.getUser(this.user_id, await this.getCredential$());
    if (userResponse) {
      this.setUserCounts(userResponse);
    }
    return userResponse;
  }

  async getCredential$(): Promise<Credential> {
    const credential: Credential | undefined = await this.getCredential();
    if (!credential) {
      throw new Error('User is not logged in');
    }

    return credential;
  }

  async getCredential(): Promise<Credential | undefined> {
    if (!this.credential) return undefined;

    const isLoggedIn = await this.isLoggedIn();
    if (isLoggedIn) {
      return this.credential;
    }

    await this.logout();
    throw new Error('Something went wrong. We are logging you out.');
  }

  async refreshLogin(): Promise<boolean> {
    if (!this.credential) return false;

    const success = await refresh(this.credential);
    if (!success) {
      return false;
    }

    await this.update({ credential: this.credential });

    return success;
  }

  async isLoggedIn(): Promise<boolean> {
    if (!this.credential) return false;

    if (isExpired(this.credential)) {
      if (await refresh(this.credential)) {
        await this.update({ credential: this.credential });
        return true;
      }
      return false;
    }

    return true;
  }

  async logout() {
    if (!this.credential) {
      return;
    }

    // TODO - cancel any pending syncs here

    this.credential = undefined;
    this.is_current = 0;

    await this.update({ credential: this.credential, is_current: this.is_current });
  }

  async oldestMessage(): Promise<Message | undefined> {
    const messages: Message[] = await db.messages
      .where({ mailbox_id: this.email_address })
      .limit(1)
      .sortBy('received_at');
    if (messages.length > 0) return messages[0];

    return undefined;
  }

  async newestMessage(): Promise<Message | undefined> {
    const messages: Message[] = await db.messages
      .where({ mailbox_id: this.email_address })
      .reverse()
      .limit(1)
      .sortBy('received_at');
    if (messages.length > 0) return messages[0];

    return undefined;
  }

  async setCurrent() {
    const emailAddress = this.email_address;
    db.transaction('rw', db.mailboxes, async () => {
      await this.update({ is_current: 1 });
      await db.mailboxes.where({ is_current: 1 }).modify((mailboxToMakeNotCurrent) => {
        if (mailboxToMakeNotCurrent.email_address === emailAddress) {
          return;
        }
        mailboxToMakeNotCurrent.is_current = 0;
      });
    });
  }

  async setNotCurrent() {
    await this.update({ is_current: 0 });
  }

  async setHasGivenFeedback() {
    await this.update({ has_given_feedback: true });
  }

  async setSyncStatus(syncStatus: MailboxStatus) {
    await this.update({ sync_status: syncStatus });
  }

  async setUserCounts(userStats: UserStats) {
    await this.update({
      unsubscribe_count: userStats.unsubscribeCount || 0,
      delete_count: userStats.trashedThreadCount || 0,
    });
  }
}

export async function getCurrentMailbox(): Promise<Mailbox | undefined | null> {
  const mailbox = await getCurrentMailboxQuery();
  return mailbox;
}

export const getCurrentMailboxQuery = () =>
  db.mailboxes
    .where({ is_current: 1 })
    .toArray()
    .then((mailboxes) => {
      if (!mailboxes || mailboxes.length === 0) {
        return null;
      }
      return mailboxes[0];
    });

export const getOtherMailboxesQuery = () => db.mailboxes.where({ is_current: 0 }).toArray();
