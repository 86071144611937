import Subscription from '../model/subscription';
import AnalyticsService from './analytics/analyticsService';
import { EventName } from './analytics/providers/analyticsProvider';
import EmailProvider from './emailProvider';
import { nanoid } from 'nanoid';

export type FilterOperationCallbacks = {
  onSuccess: () => Promise<void>;
  onError: (e: unknown) => Promise<void>;
};

export type FilterOperation = {
  subscription: Subscription;
  callbacks: FilterOperationCallbacks;
};

export default class FilterOperationQueue {
  private static instance: FilterOperationQueue;

  emailProvider: EmailProvider;
  filterOperationsQueue: FilterOperation[] = [];
  isRunning = false;

  static create(emailProvider: EmailProvider): FilterOperationQueue {
    if (!this.instance) {
      this.instance = new FilterOperationQueue(emailProvider);
    }

    return this.instance;
  }

  private constructor(emailProvider: EmailProvider) {
    this.emailProvider = emailProvider;
  }

  queue(subscription: Subscription, callbacks: FilterOperationCallbacks) {
    this.filterOperationsQueue.push({ subscription, callbacks });

    // AnalyticsService.track(EventName.QUEUE_FILTER_OPERATION);

    this.tryExecute();
  }

  async tryExecute(ignoreLock = false) {
    if (!ignoreLock && this.isRunning) {
      return;
    }

    this.isRunning = true;

    const operationId: string = nanoid();

    // AnalyticsService.track(EventName.START_FILTER_OPERATION, { filterOperationId });

    const filterOperations = this.filterOperationsQueue;
    this.filterOperationsQueue = [];

    try {
      await this.emailProvider.filterSubscriptions(filterOperations, operationId);

      // AnalyticsService.track(EventName.END_FILTER_OPERATION, { filterOperationId });

      if (this.filterOperationsQueue.length) {
        // AnalyticsService.track(EventName.RERUN_FILTER_OPERATION, { filterOperationId });

        // Before unlocking the queue, try rerunning to process any additional filters.
        // Since we are holding the lock, we pass in a special param here to ignore locks checks.
        this.tryExecute(true);
      }
    } catch (e) {
      AnalyticsService.trackError(EventName.FILTER_QUEUE_FAILED, e, {
        filterOperationId: operationId,
      });
      for (const operation of filterOperations) {
        await operation.callbacks.onError(e);
      }
    }

    if (!ignoreLock) {
      this.isRunning = false;
    }
  }
}
