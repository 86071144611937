import React from 'react';

import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DARK_GRAY_HEX } from '../../theme';

export type PaywallBulletProps = {
  text: string;
};

function PaywallBullet(props: PaywallBulletProps) {
  const theme = useTheme();
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  const fontSize = aboveMedium ? '1.2rem' : '0.8rem';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <CheckCircleIcon sx={{ fontSize, color: DARK_GRAY_HEX, marginRight: '0.5rem' }} />
      <Typography fontSize={fontSize} color={DARK_GRAY_HEX} fontWeight='500'>
        {props.text}
      </Typography>
    </Box>
  );
}
export default PaywallBullet;
