import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LockIcon from '@mui/icons-material/Lock';
import { useMediaQuery, useTheme } from '@mui/material';

export enum ConnectGmailPromptBadgeIcon {
  VERIFIED,
  TRANSPARENT,
}

export type ConnectGmailPromptBadgeProps = {
  icon: ConnectGmailPromptBadgeIcon;
  title: string;
  description: string;
};

function ConnectGmailPromptBadge(props: ConnectGmailPromptBadgeProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'start',
        margin: aboveSmall ? '4rem 0rem' : '2rem 0rem',
        ...(aboveSmall ? { maxWidth: '30rem', padding: '0rem 2rem' } : {}),
      }}
    >
      {props.icon === ConnectGmailPromptBadgeIcon.TRANSPARENT ? (
        <LockIcon
          fontSize={aboveSmall ? 'large' : 'medium'}
          color='black'
          sx={{ marginRight: '0.5rem', width: 60 }}
        />
      ) : (
        <VerifiedUserIcon
          fontSize={aboveSmall ? 'large' : 'medium'}
          color='black'
          sx={{ marginRight: '0.5rem', width: 60 }}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant='body1'
          fontSize={aboveSmall ? '1.5rem' : '1rem'}
          sx={{ fontWeight: 600 }}
        >
          {props.title}
        </Typography>
        <Typography variant='body2'>{props.description}</Typography>
      </Box>
    </Box>
  );
}

export default ConnectGmailPromptBadge;
