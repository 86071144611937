import React from 'react';

import { useTheme, useMediaQuery, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TrimboxSwitch } from '../TrimboxSwitch';
import { LIGHT_GRAY_HEX } from '../../theme';

export type SettingsCheckboxProps = {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  labelString: string;
};

function SettingsCheckbox(props: SettingsCheckboxProps) {
  const { isChecked, onChange: onChangeSwitch, labelString } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '0rem',
        padding: '0.5rem 0.5rem 0.5rem 1rem',
        marginBottom: '1rem',
        border: 'solid 1px',
        borderColor: LIGHT_GRAY_HEX,
        borderRadius: '0.5rem',
      }}
    >
      <Typography
        color='common.black'
        fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
        sx={{ flexGrow: 1 }}
      >
        {t(labelString)}
      </Typography>
      <TrimboxSwitch
        checked={!!isChecked}
        onChange={(event, checked) => {
          onChangeSwitch(checked);
        }}
      />
    </Box>
  );
}
export default SettingsCheckbox;
