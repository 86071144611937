import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useGetAvailablePromo } from './useGetAvailablePromo';
import Mailbox from '../model/mailbox';
import AnalyticsService from '../services/analytics/analyticsService';
import { EventName } from '../services/analytics/providers/analyticsProvider';

export function useLoadPricingPage(mailbox: Mailbox | null | undefined) {
  const [searchParams, setSearchParams] = useSearchParams();

  const promoIdFromURL = searchParams.get('promo');

  const { discountPercentage, promoCode } = useGetAvailablePromo(mailbox);

  const includeLifetime = !!searchParams.get('lifetime');

  const encodedReturnUrl = searchParams.get('return_url');
  const [returnUrl, setReturnUrl] = useState<string>(`${window.location.origin}/unlimited`);

  const product = searchParams.get('product');
  const [source, setSource] = useState<'web' | 'extension' | 'mobile'>('web');

  useEffect(() => {
    let decodedUri = null;
    if (encodedReturnUrl) {
      decodedUri = decodeURIComponent(encodedReturnUrl);
    }

    // If we have a return URI explicitly provided in our URL params, then use that
    if (decodedUri) {
      setReturnUrl(decodedUri);
    }
  }, [encodedReturnUrl]);

  useEffect(() => {
    if (product) {
      const isExtension = product === 'extension';
      if (isExtension) {
        setSource('extension');
      }

      const isMobile = product === 'mobile';
      if (isMobile) {
        setSource('mobile');
      }
    }
  }, [product]);

  useEffect(() => {
    AnalyticsService.track(EventName.PRICING_PAGE_VIEW, {
      promoIdFromURL,
      includeLifetime,
    });
  }, []);

  return { includeLifetime, returnUrl, source, discountPercentage, promoCode };
}
