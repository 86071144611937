import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { db } from '../../db/db';
import Mailbox from '../../model/mailbox';
import MailboxSynchronizer from '../../services/mailboxSynchronizer/mailboxSynchronizer';
import { UserState } from './userSlice.types';

export const initialState: UserState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearUser: (state) => {
      state = initialState;
    },
  },
});

export const { clearUser } = userSlice.actions;

export default userSlice.reducer;

let synchronizer: MailboxSynchronizer | null = null;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const syncMailbox =
  (mailbox: Mailbox): AppThunk =>
  async (dispatch, getState) => {
    // Cancel any pending mailbox syncs
    if (synchronizer) {
      synchronizer.cancel();
    }

    synchronizer = new MailboxSynchronizer(mailbox);
    await synchronizer.sync();
  };

export const logoutUser = (): AppThunk => async (dispatch, getState) => {
  const mailboxes = await db.mailboxes.where({ is_current: 1 }).toArray();

  mailboxes.forEach(async (mailbox) => {
    await mailbox?.logout();
  });

  // TODO - stop any pending mailbox syncs

  dispatch(clearUser());
};
