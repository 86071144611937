import { UnsubscribeInfo } from '../services/gmail/unsubscribeInfo/unsubscribeInfoAdapter';

export type EmailProviderMessage = {
  id: string;
  threadId: string;
  senderEmail: string;
  senderName?: string;
  receivedAt: number;
  senderImageUrl?: string;
  unsubscribeInfo?: UnsubscribeInfo;
  sizeInMB: number;
  hasAttachments: boolean;
};

export type EmailProviderListMessage = EmailProviderMessage & {
  unsubscribeInfo: NonNullable<EmailProviderMessage['unsubscribeInfo']>;
};

export function isEmailProviderListMessage(
  emailMessage: EmailProviderMessage
): emailMessage is EmailProviderListMessage {
  return !!emailMessage.unsubscribeInfo;
}
