import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { useEffect } from 'react';
import {
  checkIfUserHasPremiumOnAnyAccount,
  openCheckoutPage,
  OpenCheckoutPageParams,
} from '../utilities/paywallHelper';
import { fetchOffering } from '../features/offering/offeringSlice';
import { useCheckoutConversionLogging } from '../app/useCheckoutConversionLogging';
import { useAppDispatch } from '../app/hooks';
import { NUMBER_OF_FREE_UNSUBSCRIBES, NUMBER_OF_FREE_DELETES } from '../featureFlags';
import { getCurrentMailbox } from '../model/mailbox';
import {
  FORCE_PAYWALL,
  isPaywallDisabled,
  ALLOW_UNLIMITED_UNSUBSCRIBES,
  ALLOW_UNLIMITED_DELETES,
} from '../paywallFlags';

function usPremiumStatus(): PaywallContextValues {
  const [hasPremium, setHasPremium] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useCheckoutConversionLogging();

  const fetchPremiumStatus = useCallback(async () => {
    const userHasPremium = await checkIfUserHasPremiumOnAnyAccount();
    setHasPremium(userHasPremium);
  }, []);

  const configureIAP = useCallback(async () => {
    dispatch(fetchOffering());
  }, []);

  const purchasePremium = useCallback(async (params: OpenCheckoutPageParams) => {
    openCheckoutPage(params);
  }, []);

  useEffect(() => {
    configureIAP()
      .then(() => {
        fetchPremiumStatus();
      })
      .catch(console.log);
  }, []);

  // CAUTION: This function is called every single time the user performs an email action. Be careful about what you put in here.
  const shouldShowPaywallWhenUnsubscribing = useCallback(async () => {
    if (FORCE_PAYWALL) {
      return false;
    }

    if (isPaywallDisabled()) {
      return true;
    }

    if (hasPremium) {
      return false;
    }

    const mailbox = await getCurrentMailbox();
    if (!mailbox) {
      throw new Error('Mailbox not found!');
    }

    const user = await mailbox.getUser();
    if (!user) {
      throw new Error('User not found!');
    }

    if (
      !ALLOW_UNLIMITED_UNSUBSCRIBES &&
      (user?.unsubscribeCount || 0) >= NUMBER_OF_FREE_UNSUBSCRIBES
    ) {
      return true;
    }

    if (!ALLOW_UNLIMITED_DELETES && (user?.trashedThreadCount || 0) >= NUMBER_OF_FREE_DELETES) {
      return true;
    }

    return false;
  }, [hasPremium]);

  return { hasPremium, purchasePremium, shouldShowPaywallWhenUnsubscribing };
}

export type PaywallContextValues = {
  hasPremium: boolean;
  purchasePremium: (checkoutParams: OpenCheckoutPageParams) => Promise<void>;
  shouldShowPaywallWhenUnsubscribing: () => Promise<boolean>;
};

const PaywallContext = createContext<PaywallContextValues>(null as any);

type PaywallProviderProps = {
  children: ReactNode;
};

export const PaywallProvider: React.FC<PaywallProviderProps> = ({ children }) => {
  const { hasPremium, purchasePremium, shouldShowPaywallWhenUnsubscribing } = usPremiumStatus();

  const value: PaywallContextValues = {
    hasPremium,
    purchasePremium,
    shouldShowPaywallWhenUnsubscribing,
  };

  return <PaywallContext.Provider value={value}>{children}</PaywallContext.Provider>;
};

// Custom hook to use the Paywall context
export const usePaywall = () => {
  const context = useContext(PaywallContext);
  if (context === undefined) {
    throw new Error('usePaywall must be used within a PaywallProvider');
  }
  return context;
};
