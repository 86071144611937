import mailbox from '../../model/mailbox';
import Message from '../../model/message';
import SyncQueryBuilder from '../syncQueryBuilder';

export default class GmailSyncQueryBuilder implements SyncQueryBuilder {
  async build(mailbox: mailbox): Promise<string> {
    let query = '';
    const oldestMessage: Message | undefined = await mailbox.oldestMessage();
    const newestMessage: Message | undefined = await mailbox.newestMessage();
    if (oldestMessage?.received_at && newestMessage?.received_at) {
      const newestTimestamp = newestMessage.received_at / 1000;
      const oldestTimestamp = oldestMessage.received_at / 1000;
      query = `after:${newestTimestamp} OR before:${oldestTimestamp}`;
    }
    return query;
  }
}
