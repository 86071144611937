/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
import snakeCase from 'lodash/snakeCase';
import { db } from '../db/db';

export default abstract class ModelBase {
  public static find<T>(pk: string | number): T | undefined {
    return db[this.tableName()].get(pk);
  }

  public static async delete(): Promise<void> {
    await db[this.tableName()].toCollection().delete();
  }

  public static async createAll<T>(params: Partial<T>[]): Promise<void> {
    const records: Partial<T>[] = [];
    params.forEach((param) => {
      const record = {};
      Object.keys(param).forEach((key) => (record[snakeCase(key)] = param[key]));
      records.push(record);
    });
    await db[this.tableName()].bulkPut(records);
  }

  public static async create<T>(params: Partial<T>): Promise<T> {
    const record: Partial<T> = {};
    Object.keys(params).forEach((key) => (record[snakeCase(key)] = params[key]));

    params[db[this.tableName()].schema.primKey.name] = await db[this.tableName()].put(record);
    const instance: T = Object.create(this.prototype);

    Object.keys(params).forEach((prop) => (instance[snakeCase(prop)] = params[prop]));
    return instance;
  }

  public async update<T>(params: Partial<T>) {
    const updates: Partial<T> = {};

    Object.keys(params).forEach((prop) => (updates[snakeCase(prop)] = params[prop]));
    const pk = db[this.klass().tableName()].schema.primKey.name;

    await db[this.klass().tableName()].update(this[pk], updates);

    Object.keys(updates).forEach((prop) => (this[prop] = updates[prop]));
  }

  public async save<T>() {
    const updates: Partial<T> = {};
    const pkName = db[this.klass().tableName()].schema.primKey.name;

    Object.getOwnPropertyNames(this).forEach((prop) => {
      if (prop === 'id') {
        return;
      }

      updates[snakeCase(prop)] = this[prop];
    });

    const pk = await db[this.klass().tableName()].put(updates);
    this[pkName] = pk;
  }

  public static tableName(): string {
    const tableName = this['table'];
    if (!tableName) throw new Error('Table name must be set by subclass');

    return tableName;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public klass(): Function {
    return this.constructor;
  }
}
