import { useEffect } from 'react';

import Mailbox from '../model/mailbox';
import AnalyticsService from '../services/analytics/analyticsService';

export function useIdentification(
  mailbox: Mailbox | undefined | null,
  userEmail: string | undefined
) {
  useEffect(() => {
    if (mailbox?.email_address) {
      AnalyticsService.identify(mailbox);
    } else if (userEmail) {
      AnalyticsService.identify({
        email_address: userEmail,
      });
    }
  }, [mailbox?.email_address, userEmail]);
}
