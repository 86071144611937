import React, { memo } from 'react';

import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectDrawerOpen, selectSort, setDrawerOpen, setSortOrder } from './emailListsSlice';
import { UnsubscribeListSort } from './emailLists.types';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { SHOW_EMPTY_LIST } from '../../featureFlags';
import { EmailListItem, EmailListItemProps } from './EmailListItem';
import { getSubscriptionProperties } from '../../model/subscription';
import MailboxSyncIndicator from '../../components/MailboxSyncIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { t } from 'i18next';
import { EmailListEmptyState } from './EmailListEmptyState';
import { useGetLists } from '../../app/useGetLists';
import FreeUnsubscribesIndicator from '../../components/FreeUnsubscribesIndicator';
import { useLiveQuery } from 'dexie-react-hooks';
import { getCurrentMailboxQuery } from '../../model/mailbox';

export type UnsubscribeTabProps = {
  mailboxId: string;
};

const Row = memo((p: EmailListItemProps) => {
  return <EmailListItem key={p.email_address} {...p}></EmailListItem>;
});

Row.displayName = 'row';

export function UnsubscribeTab(props: UnsubscribeTabProps) {
  const sortOrder = useAppSelector(selectSort);
  const drawerOpen = useAppSelector(selectDrawerOpen);

  const dispatch = useAppDispatch();

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { subscriptions, fetchMoreData } = useGetLists(props.mailboxId);

  const mailbox = useLiveQuery(getCurrentMailboxQuery);
  const mailboxSyncStatus = mailbox?.sync_status;

  return (
    <>
      {SHOW_EMPTY_LIST || !subscriptions?.length ? (
        EmailListEmptyState(mailboxSyncStatus, aboveSmall, isExtraSmall)
      ) : (
        <InfiniteScroll
          dataLength={subscriptions.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<></>}
        >
          <List
            sx={{
              margin: aboveSmall ? '1rem 0rem 0rem 0rem' : '0.5rem 0rem 0rem 0rem',
              textAlign: 'end',
              padding: 0,
            }}
          >
            <TransitionGroup>
              {subscriptions?.map((subscription) => {
                const subscriptionProperties = getSubscriptionProperties(subscription);
                return (
                  <Collapse key={subscription.email_address}>
                    <Row
                      key={subscription.email_address}
                      {...subscriptionProperties}
                      mailboxId={props.mailboxId}
                    />
                  </Collapse>
                );
              })}
            </TransitionGroup>
            <MailboxSyncIndicator></MailboxSyncIndicator>
          </List>
        </InfiniteScroll>
      )}
      {false && <FreeUnsubscribesIndicator></FreeUnsubscribesIndicator>}
      <Drawer
        anchor='bottom'
        open={drawerOpen}
        onClose={() => {
          dispatch(setDrawerOpen(false));
        }}
        sx={{ backgroundColor: 'primary' }}
      >
        <List>
          <ListItemButton
            selected={sortOrder === UnsubscribeListSort.NEWEST}
            onClick={() => updateSortOrder(UnsubscribeListSort.NEWEST)}
          >
            <ListItemIcon>
              <AccessTimeFilledIcon />
            </ListItemIcon>
            <ListItemText primary={t('newest')} />
          </ListItemButton>
          <ListItemButton
            selected={sortOrder === UnsubscribeListSort.NUMBER_OF_MESSAGES}
            onClick={() => updateSortOrder(UnsubscribeListSort.NUMBER_OF_MESSAGES)}
          >
            <ListItemIcon>
              <DynamicFeedIcon />
            </ListItemIcon>
            <ListItemText primary={t('mostMessages')} />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );

  function updateSortOrder(sortOrder: UnsubscribeListSort) {
    dispatch(setSortOrder({ sortOrder }));
    dispatch(setDrawerOpen(false));
  }
}
