import Credential from '../model/credential';

export enum SCOPE_TYPE {
  USER_INFO,
  GMAIL_ACCESS,
  ALL_SCOPES,
}

const signInScopes: string[] = [
  'openid',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
];

const gmailScopes: string[] = [
  'https://www.googleapis.com/auth/gmail.modify',
  'https://www.googleapis.com/auth/gmail.settings.basic',
];

// private static fullGmailScopes: string[] = ['https://mail.google.com/'];

const allScopes: string[] = [
  'openid',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/gmail.modify',
  'https://www.googleapis.com/auth/gmail.settings.basic',
];

function getScopesFromScopeType(scopeType: SCOPE_TYPE) {
  if (scopeType === SCOPE_TYPE.ALL_SCOPES) {
    return allScopes;
  } else if (scopeType === SCOPE_TYPE.USER_INFO) {
    return signInScopes;
  }
  return gmailScopes;
}

export default class CredentialFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private google: any;
  private clientId: string;
  // TODO: this is insecure.  We should find a better way to exchange the secret key.
  private clientSecret: string;
  private redirectUri: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(google: any) {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    if (!clientId) throw new Error('[CredentialFactory] missing client id');
    if (!clientSecret) throw new Error('[CredentialFactory] missing client secret');
    if (!redirectUri) throw new Error('[CredentialFactory] missing redirect uri');

    this.google = google;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.redirectUri = this.getRedirectUri();
  }

  public getAuthCode(scopeType: SCOPE_TYPE, emailHint?: string) {
    const scopes = getScopesFromScopeType(scopeType);

    const scopeString = scopes.join(' ');
    const config = {
      client_id: this.clientId,
      scope: scopeString,
      ux_mode: 'redirect',
      redirect_uri: this.redirectUri,
      select_account: false,
      login_hint: emailHint,
    };
    // https://developers.google.com/identity/oauth2/web/reference/js-reference#google.accounts.oauth2.initCodeClient
    const client = this.google.accounts.oauth2.initCodeClient(config);

    client.requestCode();
  }

  public async getCredentials(code: string): Promise<Credential> {
    const redirectUri = this.redirectUri;

    const request = new Request('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `code=${code}&client_id=${this.clientId}&client_secret=${this.clientSecret}&redirect_uri=${redirectUri}&grant_type=authorization_code`,
    });
    const response = await fetch(request);
    const credential = await response.json();

    if (!response.ok) {
      throw new Error(
        `[CredentialFactory] failed to get credential with error ${await response.text()}`
      );
    }

    return new Credential({
      accessToken: credential.access_token,
      refreshToken: credential.refresh_token,
      expiresInSeconds: credential.expires_in,
      openIDToken: credential.id_token,
      scopes: credential.scope.split(' '),
    });
  }

  public static hasFullScopes(credential: Credential, scopeType: SCOPE_TYPE) {
    const missingScopes = this.getMissingScopes(credential, scopeType);
    return !missingScopes || missingScopes.length == 0;
  }

  public static getMissingScopes(credential: Credential, scopeType: SCOPE_TYPE): string[] {
    const scopesGranted = credential.scopes;

    const scopesToCheck = getScopesFromScopeType(scopeType);

    const missingSignInScopes: string[] = scopesToCheck.filter((requiredScope) => {
      return !scopesGranted.includes(requiredScope);
    });

    return missingSignInScopes;
  }

  private getRedirectUri(): string {
    const url = window.location.origin;
    return url;
    // const currentUrl = window.location.href;
    // const trimboxUrl = 'trimbox.io';
    // const index = currentUrl.indexOf(trimboxUrl);
    // if (index === -1) {
    //   if (currentUrl.endsWith('/')) {
    //     return currentUrl.slice(0, -1);
    //   }
    //   return currentUrl;
    // }
    // const redirectUri = currentUrl.slice(0, index + trimboxUrl.length);
    // return redirectUri;
  }
}
