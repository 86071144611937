export type PromoCoupon = {
  id: string;
  percentOff: number;
  withLifetime?: boolean;
};

export const UNINSTALL_PROMO_DEV_30: PromoCoupon = {
  id: 'promo_1OH6wgGFm0BhZDkW7i3MLpOa',
  percentOff: 0.3,
};
export const UNINSTALL_PROMO_DEV_30_LIFETIME: PromoCoupon = {
  id: 'promo_1OH6wgGFm0BhZDkW7i3MLpOa',
  percentOff: 0.3,
  withLifetime: true,
};

export const UNINSTALL_PROMO_PROD_50: PromoCoupon = {
  id: 'promo_1MN1NXGFm0BhZDkWWBsfGsXV',
  percentOff: 0.5,
};

export const UNINSTALL_PROMO_PROD_70: PromoCoupon = {
  id: 'promo_1OOwyiGFm0BhZDkWDJuPWVje',
  percentOff: 0.7,
};
export const UNINSTALL_PROMO_PROD_70_LIFETIME: PromoCoupon = {
  id: 'promo_1OOwyiGFm0BhZDkWDJuPWVje',
  percentOff: 0.7,
  withLifetime: true,
};

export const FEEDBACK_PROMO_DEV_30: PromoCoupon = {
  id: 'promo_1OH6wgGFm0BhZDkW7i3MLpOa',
  percentOff: 0.3,
};
export const FEEDBACK_PROMO_DEV_30_LIFETIME: PromoCoupon = {
  id: 'promo_1OH6wgGFm0BhZDkW7i3MLpOa',
  percentOff: 0.3,
  withLifetime: true,
};

export const FEEDBACK_PROMO_PROD_30: PromoCoupon = {
  id: 'promo_1OH6wDGFm0BhZDkWHSzDBTcK',
  percentOff: 0.3,
};
export const FEEDBACK_PROMO_PROD_30_LIFETIME: PromoCoupon = {
  id: 'promo_1OH6wDGFm0BhZDkWHSzDBTcK',
  percentOff: 0.3,
  withLifetime: true,
};

export const FEEDBACK_PROMO_PROD_50: PromoCoupon = {
  id: 'promo_1MhJNVGFm0BhZDkW73ZMJnkZ',
  percentOff: 0.5,
};
export const FEEDBACK_PROMO_PROD_50_LIFETIME: PromoCoupon = {
  id: 'promo_1MhJNVGFm0BhZDkW73ZMJnkZ',
  percentOff: 0.5,
  withLifetime: true,
};
