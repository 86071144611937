import React, { useEffect, useState } from 'react';

import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  TextField,
  CircularProgress,
  Stack,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  closeRequestLicenseDialog,
  selectIsRequestLicenseDialogOpen,
} from '../../features/ui/uiSlice';
import { RED_COLOR_HEX, WHITESMOKE_HEX } from '../../theme';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../utilities/errorHelper';
import TrimboxServerClient from '../../services/serverClient';
import { getCurrentMailboxQuery } from '../../model/mailbox';
import { useLiveQuery } from 'dexie-react-hooks';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

type Step = 'email' | 'loading' | 'sent';

function RequestLicensePopup() {
  const isOpen = useAppSelector(selectIsRequestLicenseDialogOpen);

  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string | undefined>();
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const [step, setStep] = useState<Step>('email');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (!email) {
      setInvalidEmail(false);
      return;
    }

    const validateEmail = (email: string) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    setInvalidEmail(!validateEmail(email));
  }, [email]);

  const handleDismiss = () => {
    dispatch(closeRequestLicenseDialog());
  };

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={!!isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDismiss}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          backgroundColor: WHITESMOKE_HEX,
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
                maxWidth: '800px',
              }
            : {
                maxWidth: 'none',
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
              }),
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 600,
        }}
      >
        {step === 'sent' ? t('checkYourInbox') : t('alreadyPaidTitle')}
      </DialogTitle>
      <DialogContent
        sx={{
          alignContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack>
          {step === 'loading' ? (
            <CircularProgress sx={{ alignSelf: 'center' }} />
          ) : step === 'sent' ? (
            <>
              <Typography
                color='common.black'
                fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
              >
                {t('requestLicenseEmailSent')}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                color='common.black'
                fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '1rem' : '0.85rem'}
              >
                {t('requestLicenseContent')}
              </Typography>
              <TextField
                error={invalidEmail}
                type={'email'}
                helperText={invalidEmail && t('enterValidEmail')}
                sx={{
                  margin: isExtraSmall ? '2rem 0rem' : '1.5rem 0rem',
                }}
                placeholder={t('enterYourEmail') || undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                variant='outlined'
                color='primary'
                focused
              />
              <Button
                variant='contained'
                size='large'
                onClick={async () => {
                  try {
                    setStep('loading');

                    if (!email) {
                      throw new Error('Email address is missing!');
                    }

                    if (!mailbox) {
                      throw new Error('Mailbox not found!');
                    }

                    await new TrimboxServerClient().sendLicenseAccessRequest(
                      { owner: email, requester: mailbox.email_address },
                      await mailbox.getCredential$()
                    );

                    setStep('sent');
                  } catch (e) {
                    const errorMessage = getErrorMessage(e);
                    setError(errorMessage);
                    setStep('email');
                  }
                }}
                sx={{
                  fontSize: isExtraSmall ? '0.85rem' : '1rem',
                  fontWeight: 600,
                  borderRadius: '1rem',
                  ...(aboveSmall ? { padding: '0.5rem 4rem' } : {}),
                  width: 'fit-content',
                  margin: 'auto',
                }}
              >
                {t('next')}
              </Button>
              {error && (
                <Typography
                  sx={{
                    marginTop: '1rem',
                    color: RED_COLOR_HEX,
                    fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.25rem' : '1rem',
                  }}
                >
                  {error}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default RequestLicensePopup;
