import DeleteBundleCommand from '../command/deleteBundleCommand';
import { db } from '../db/db';
import {
  EmailBundleConfig,
  SUPPORTED_EMAIL_BUNDLES,
} from '../features/emailBundles/emailBundles.configs';
import { BulkDeleteOperationCallbacks } from '../services/bulkDeleteOperationQueue';
import ModelBase from './base';
import BundleMembership from './bundleMembership';
import { BundleParams } from './emailBundle.types';
import Mailbox from './mailbox';
import Message from './message';

export default class Bundle extends ModelBase {
  public static table = 'bundles';

  public display_name: string;
  public bundle_id: number;
  public mailbox_id: string;
  public message_count?: number;

  constructor(params: BundleParams) {
    super();
    this.display_name = params.display_name;
    this.bundle_id = params.bundle_id;
    this.mailbox_id = params.mailbox_id;
    this.message_count = params?.message_count;
  }

  async mailbox(): Promise<Mailbox> {
    const mailbox = await db.mailboxes.get(this.mailbox_id);
    if (!mailbox) {
      throw new Error(`[Sender] mailbox not found ${this.mailbox_id}`);
    }

    return mailbox;
  }

  async delete(callbacks: BulkDeleteOperationCallbacks) {
    const command = DeleteBundleCommand.create(this);

    const onSuccess = async (number_of_messages_deleted: number) => {
      const mailbox: Mailbox = await this.mailbox();
      await mailbox.updateUser({ deleteCount: number_of_messages_deleted });

      await callbacks.onSuccess(number_of_messages_deleted);
    };

    command.run({ onSuccess, onError: callbacks.onError });
  }

  async messageIds(): Promise<string[]> {
    const bundleMemberships: BundleMembership[] = await db.bundleMemberships
      .where('bundle_id')
      .equals(this.bundle_id)
      .toArray();
    return bundleMemberships.map((membership) => membership.message_id);
  }

  async messages(): Promise<Message[]> {
    const bundleId = this.bundle_id;
    return db.transaction('r', db.bundleMemberships, db.messages, async function () {
      const bundleMemberships: BundleMembership[] = await db.bundleMemberships
        .where('bundle_id')
        .equals(bundleId)
        .toArray();

      return await db.messages
        .where({
          external_id: bundleMemberships.map((membership) => membership.message_id),
        })
        .toArray();
    });
  }

  bundleConfig(): EmailBundleConfig {
    const config: EmailBundleConfig | undefined = SUPPORTED_EMAIL_BUNDLES.find(
      (bundle) => bundle.bundleId === this.bundle_id
    );
    if (!config) {
      throw new Error(`Config for bundle ${this.bundle_id} does not exist`);
    }

    return config;
  }
}
