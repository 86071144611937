import { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { ErrorDict, EventName } from './analyticsProvider';

export default class LinkedInAnalyticsProvider implements AnalyticsProvider {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public identify(params: IdentifyParams) {
    // Not needed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public alias(id: string) {
    // Not needed
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public track(name: EventName, props?: Dict) {
    // Not needed
  }

  public trackCheckout(checkoutParams: CheckoutParams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.lintrk('track', { conversion_id: 14796428 });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public trackError(name: EventName, errorProps: ErrorDict) {
    // Do not log errors to Facebook
  }
}
