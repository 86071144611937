import React, { useEffect, useState } from 'react';

import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../db/db';
import { UnsubscribeStatus } from '../model/subscription.types';
import { UnsubscribeListSort } from '../features/emailLists/emailLists.types';
import { selectSort } from '../features/emailLists/emailListsSlice';
import AnalyticsService from '../services/analytics/analyticsService';
import { EventName } from '../services/analytics/providers/analyticsProvider';
import { useAppSelector } from './hooks';

type UnsubscribeTabLoadData = {
  loadEndTime: number;
  numberOfLists: number;
};

const LIST_PAGE_SIZE = 20;

export function useGetLists(mailboxId: string) {
  const sortOrder = useAppSelector(selectSort);

  const [loadStartTime, setLoadStartTime] = useState(new Date().getTime());
  const [loadData, setLoadData] = useState<UnsubscribeTabLoadData | undefined>(undefined);
  const [hasMeasuredLoadTime, setHasMeasuredLoadTime] = useState(false);

  const [listLimit, setListLimit] = React.useState<number>(LIST_PAGE_SIZE);

  const subscriptions = useLiveQuery(
    () =>
      db.subscriptions
        .where('mailbox_id')
        .equals(mailboxId)
        .and(
          (subscription) =>
            subscription.unsubscribe_status !== UnsubscribeStatus.Kept &&
            subscription.unsubscribe_status !== UnsubscribeStatus.Unsubscribed
        )
        .reverse()
        .sortBy(
          sortOrder === UnsubscribeListSort.NEWEST ? 'newest_message_received_at' : 'message_count'
        )
        .then((subscriptionsResult) => {
          const subscriptionsToShow = subscriptionsResult.slice(0, listLimit);
          if (!hasMeasuredLoadTime) {
            if (subscriptionsToShow?.length) {
              setLoadData({
                loadEndTime: new Date().getTime(),
                numberOfLists: subscriptionsToShow.length,
              });
              setHasMeasuredLoadTime(true);
            }
          }
          return subscriptionsToShow;
        }),
    [mailboxId, sortOrder, listLimit]
  );

  useEffect(() => {
    if (!hasMeasuredLoadTime || !loadData) {
      return;
    }
    const loadDuration = loadData.loadEndTime - loadStartTime;
    AnalyticsService.track(EventName.LOADED_EMAIL_LISTS, {
      listLength: loadData.numberOfLists,
      loadDuration,
    });
  }, [hasMeasuredLoadTime]);

  const fetchMoreData = () => {
    setListLimit(listLimit + LIST_PAGE_SIZE);
  };

  return { subscriptions, fetchMoreData };
}
