import Base64Url from '../../../utilities/base64';
import { SenderInfo } from '../senderInfoAdapter';
import { forwardedMessageHeaders } from './forwardedHeaderRepository';
import { unsubscribeKeywords } from './unsubscribeKeywordsRepository';

export type UnsubscribeInfo = {
  https?: HttpUnsubscribeInfo;
  smtp?: SmtpUnsubscribeInfo;
};

export type HttpUnsubscribeInfo = {
  httpsEndpoint?: string;
  oneClickSecret?: string;
  keyword?: string;
};

export type HttpOneClickUnsubscribeInfo = Required<Omit<HttpUnsubscribeInfo, 'keyword'>>;

export function isOneClickUnsubscribe(
  httpUnsubscribeInfo: HttpUnsubscribeInfo
): httpUnsubscribeInfo is HttpOneClickUnsubscribeInfo {
  return !!httpUnsubscribeInfo.oneClickSecret && !!httpUnsubscribeInfo.httpsEndpoint;
}

export type HttpPayloadUnsubscribeInfo = Omit<HttpUnsubscribeInfo, 'oneClickSecret'>;

export type SmtpUnsubscribeInfo = {
  recipient: string;
  subject?: string;
};

export class UnsubscribeInfoAdapter {
  adapt(
    message: gapi.client.gmail.Message,
    senderInfo: SenderInfo,
    userEmailAddress: string
  ): UnsubscribeInfo | undefined {
    // Skip common personal emails
    if (
      senderInfo.senderEmail.endsWith('@gmail.com') ||
      senderInfo.senderEmail.endsWith('@yahoo.com')
    ) {
      return undefined;
    }

    // Skip emails from yourself
    if (senderInfo.senderEmail === userEmailAddress) {
      return undefined;
    }

    let httpsInfo: HttpUnsubscribeInfo | undefined = undefined;
    let smtpInfo: SmtpUnsubscribeInfo | undefined = undefined;
    const payload: gapi.client.gmail.MessagePart | undefined = message.payload;
    if (!payload) {
      throw new Error('[UnsubscribeInfoAdapter] did not expect payload to be undefined');
    }

    const headers: gapi.client.gmail.MessagePartHeader[] | undefined = payload.headers;

    const subjectHeader = headers?.find((header) => header.name?.toLowerCase() === 'subject');
    const subject = subjectHeader?.value;
    if (subject) {
      // Skip forwards and replies
      if (subject.startsWith('RE:') || subject.startsWith('FW:')) {
        return;
      }
    }

    httpsInfo = this.getHttpInfoFromMessageParts(payload);

    const listUnsubscribeHeader = headers?.find(
      (header) => header.name?.toLowerCase() === 'list-unsubscribe'
    );

    if (headers && listUnsubscribeHeader && listUnsubscribeHeader.value) {
      smtpInfo = this.getSmtpInfoFromHeader(listUnsubscribeHeader.value);

      const httpsInfoFromHeaders = this.getHttpInfoFromHeader(listUnsubscribeHeader.value, headers);
      httpsInfo = {
        ...httpsInfo,
        ...httpsInfoFromHeaders,
      };
    }

    if (smtpInfo === undefined && httpsInfo === undefined) return undefined;

    return {
      smtp: smtpInfo,
      https: httpsInfo,
    };
  }

  private getHttpInfoFromHeader(
    headerValue: string,
    headers: gapi.client.gmail.MessagePartHeader[]
  ): HttpUnsubscribeInfo | undefined {
    const httpsMatcher = /<(https?:[^\s]*)>/;
    const httpsMatches = httpsMatcher.exec(headerValue);
    const httpsEndpoint =
      httpsMatches != null && httpsMatches.length === 2 ? httpsMatches[1] : null;
    if (httpsEndpoint == null) return undefined;

    for (const header of headers) {
      if (header.name?.toLowerCase() === 'list-unsubscribe-post' && header.value) {
        return {
          httpsEndpoint,
          oneClickSecret: header.value,
        };
      }
    }

    return {
      httpsEndpoint,
    };
  }

  getHttpInfoFromMessageParts(
    payload: gapi.client.gmail.MessagePart
  ): HttpPayloadUnsubscribeInfo | undefined {
    for (const part of this.listMessageParts(payload)) {
      if (part === undefined || part === null) {
        continue;
      }

      const httpContext = this.parseDOMForHttpInfo(part);

      if (httpContext) {
        return httpContext;
      }
    }

    return undefined;
  }

  private parseDOMForHttpInfo(part: string): HttpPayloadUnsubscribeInfo | undefined {
    const decodedPart = Base64Url.decode(part);
    const htmlDocument = new DOMParser().parseFromString(decodedPart, 'text/html');
    const links = htmlDocument.links;

    const lowercaseMessage = decodedPart.toLowerCase();

    const isForwarded = forwardedMessageHeaders.some((forwardHeader) =>
      lowercaseMessage.includes(forwardHeader)
    );

    if (isForwarded) {
      return;
    }

    for (let i = 0; i < links.length; i++) {
      const link = links[i];

      let linkText = link.innerText;
      if (!linkText) {
        continue;
      }

      linkText = linkText.toLowerCase();

      const keywordMatch = unsubscribeKeywords.find((keyword) => {
        return linkText.includes(keyword);
      });

      if (keywordMatch) {
        const url = link.href;
        return { httpsEndpoint: url, keyword: keywordMatch };
      }
    }

    const keywordMatch = unsubscribeKeywords.find((keyword) => {
      return lowercaseMessage.includes(keyword);
    });

    if (keywordMatch) {
      return { keyword: keywordMatch };
    }
  }

  getSmtpInfoFromHeader(headerValue: string): SmtpUnsubscribeInfo | undefined {
    const smtpMatcher = /<mailto:([^\s,]*)>/;
    const smtpMatches = smtpMatcher.exec(headerValue);
    const recipient = smtpMatches != null && smtpMatches.length === 2 ? smtpMatches[1] : undefined;
    if (!recipient) return undefined;

    const smtpContext: SmtpUnsubscribeInfo = { recipient, subject: undefined };
    if (recipient.includes('?')) {
      const queryString = recipient.split('?')[1];
      const queryParameters = new URLSearchParams(queryString);
      if (queryParameters.has('subject')) {
        smtpContext.subject = queryParameters.get('subject') ?? undefined;
      }
      smtpContext.recipient = recipient.split('?')[0];
    } else {
      smtpContext.recipient = recipient;
    }
    return smtpContext;
  }

  listMessageParts(payload: gapi.client.gmail.MessagePart) {
    const r = [];
    const q: gapi.client.gmail.MessagePart[] = [payload];
    while (q.length > 0) {
      const part = q.pop();
      if (part?.mimeType === 'text/html') r.push(part?.body?.data);
      const parts = part?.parts || [];
      parts.forEach((p) => q.push(p));
    }
    return r;
  }
}
