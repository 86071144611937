import TokenBucket from '../../utilities/tokenBucket';

export default class GmailThrottle {
  private perSecondThrottle: TokenBucket;
  private perMinuteThrottle: TokenBucket;

  constructor() {
    // Gmail restricts each user to 250 qouta units per second
    this.perSecondThrottle = new TokenBucket({
      tokensPerInterval: 250,
      intervalMillis: 1000,
    });
    // Gmail restricts each user to 15000 qouta units per minute
    this.perMinuteThrottle = new TokenBucket({
      tokensPerInterval: 15000,
      intervalMillis: 60000,
    });
  }

  async take(quotaUnits: number) {
    await this.perSecondThrottle.take(quotaUnits);
    await this.perMinuteThrottle.take(quotaUnits);
  }
}
