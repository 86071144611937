import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import empty_state from '../../assets/mailbox.svg';
import { MailboxStatus } from '../../services/mailboxSynchronizer/mailboxSynchronizer.types';

export function EmailListEmptyState(
  mailboxSyncStatus: MailboxStatus | undefined,
  aboveSmall: boolean,
  isExtraSmall: boolean
) {
  return (
    <Stack sx={{ marginTop: isExtraSmall ? '2rem' : '5rem', alignItems: 'center' }}>
      <Box
        component='img'
        src={empty_state}
        sx={{
          maxWidth: aboveSmall ? '20rem' : '50%',
        }}
      />
      <Typography
        sx={{
          marginTop: '2rem',
          ...(aboveSmall ? { fontSize: '1.25rem' } : {}),
        }}
      >
        {mailboxSyncStatus !== MailboxStatus.SYNCING
          ? 'No email lists found.'
          : "One sec. We're scanning your inbox..."}
      </Typography>
    </Stack>
  );
}
