import React, { useEffect, useState } from 'react';

import {
  useMediaQuery,
  Stack,
  Typography,
  useTheme,
  Box,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import banner_image from '../../../../assets/banner_image.svg';
import { RED_COLOR_HEX } from '../../../../theme';
import { getErrorMessage } from '../../../../utilities/errorHelper';
import TrimboxServerClient from '../../../../services/serverClient';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import { useTranslation } from 'react-i18next';

type Step = 'email' | 'loading' | 'sent';

function WebViewInvite() {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [email, setEmail] = useState<string | undefined>();
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const { t } = useTranslation();

  const [step, setStep] = useState<Step>('email');
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (!email) {
      setInvalidEmail(false);
      return;
    }

    const validateEmail = (email: string) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    setInvalidEmail(!validateEmail(email));
  }, [email]);

  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box
        margin={2}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 44 : isExtraSmall ? 28 : 36,
          marginBottom: isExtraSmall ? 0 : '-1rem',
        }}
      />
      <Stack
        flexGrow={1}
        justifyContent='center'
        sx={{
          margin: aboveSmall ? '0rem' : '1rem',
        }}
      >
        {step === 'loading' ? (
          <CircularProgress />
        ) : step === 'sent' ? (
          <>
            <Typography
              variant='h4'
              component='h4'
              fontWeight={600}
              sx={{
                fontSize: isExtraSmall ? '1.75rem' : aboveSmall ? '3rem' : '2rem',
              }}
            >
              {t('checkYourInbox')}
            </Typography>
            <Typography
              sx={{
                margin: isExtraSmall ? '2rem 0rem' : '1.5rem 0rem',
                fontSize: aboveSmall ? '1.25rem' : isExtraSmall ? '0.75rem' : '1.25rem',
              }}
            >
              {t('sentEmailToVerify')}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant='h4'
              component='h4'
              fontWeight={600}
              sx={{
                fontSize: isExtraSmall ? '1.75rem' : aboveSmall ? '3rem' : '2rem',
              }}
            >
              {t('whatsYourEmail')}
            </Typography>
            <TextField
              error={invalidEmail}
              type={'email'}
              helperText={invalidEmail && t('enterValidEmail')}
              sx={{
                margin: isExtraSmall ? '2rem 0rem' : '1.5rem 0rem',
              }}
              placeholder={t('enterYourEmail') || undefined}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              variant='outlined'
              color='primary'
              focused
            />
            <Box>
              <Button
                variant='contained'
                size='large'
                color='primary'
                disabled={!email || invalidEmail}
                onClick={async () => {
                  try {
                    setStep('loading');

                    if (!email) {
                      throw new Error('Email address is missing!');
                    }

                    AnalyticsService.track(EventName.SEND_WEBVIEW_LOGIN);

                    AnalyticsService.identify({
                      email_address: email,
                    });

                    await new TrimboxServerClient().sendEmailLoginLink(email);

                    setStep('sent');
                  } catch (e) {
                    AnalyticsService.trackError(EventName.SEND_WEBVIEW_LOGIN_ERROR, e);

                    const errorMessage = getErrorMessage(e);
                    setError(errorMessage);
                    setStep('email');
                  }
                }}
              >
                {t('getStarted')}
              </Button>
            </Box>
            <Typography
              sx={{
                marginTop: '1rem',
                color: RED_COLOR_HEX,
                fontSize: isExtraSmall ? '1rem' : aboveSmall ? '1.25rem' : '1rem',
              }}
            >
              {error}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
}
export default WebViewInvite;
