import { LicenseStatus } from './useLoadMailboxAndLicenses';

export type HeaderAndSubheaderInfo = {
  headerText: string;
  subheaderText: string;
};

export function useGetHeaderAndSubheaderInfo(
  hasAccessToken: boolean,
  licenseStatus: LicenseStatus | undefined | null,
  isChromeExtension: boolean
): HeaderAndSubheaderInfo | undefined {
  if (!hasAccessToken) {
    return {
      headerText: 'manageYourSubscription',
      subheaderText: 'manageSubscriptionSubheaderSignIn',
    };
  }

  if (!licenseStatus) {
    return;
  }

  const { hasActiveIAPLicense, hasActiveStripeLicense, ownsActiveStripeLicense } = licenseStatus;

  const headerText: string =
    hasActiveIAPLicense || hasActiveStripeLicense ? 'youveGotUnlimited' : 'dontHaveUnlimited';

  const subheaderText: string | null = hasActiveStripeLicense
    ? isChromeExtension
      ? 'manageSubscriptionSubheaderStripeChrome'
      : 'manageSubscriptionSubheaderStripeWeb'
    : hasActiveIAPLicense
    ? 'manageSubscriptionSubheaderAppStore'
    : 'manageSubscriptionSubheaderUpgrade';

  return { headerText, subheaderText };
}
