import mixpanel, { Dict } from 'mixpanel-browser';
import { CheckoutParams, IdentifyParams } from '../analyticsService.types';
import AnalyticsProvider, { ErrorDict, EventName } from './analyticsProvider';

mixpanel.init('4dfcd787ebc192503d2fcc210c389eaf');

export default class MixpanelAnalyticsProvider implements AnalyticsProvider {
  public identify(params: IdentifyParams) {
    mixpanel.identify(params.email_address);
    this.setPeople({
      ...(params.first_name ? { $first_name: params.first_name } : {}),
      ...(params.last_name ? { $last_name: params.last_name } : {}),
      $email: params.email_address,
    });
  }

  public alias(id: string) {
    mixpanel.alias(id);
  }

  public track(name: EventName, props?: Dict) {
    mixpanel.track(name, { trimbox_version: process.env.REACT_APP_VERSION, ...props });
  }

  public trackCheckout(checkoutParams: CheckoutParams) {
    this.track(EventName.CHECKOUT_COMPLETE, checkoutParams);
  }

  public trackError(name: EventName, errorProps: ErrorDict) {
    mixpanel.track(name, { trimbox_version: process.env.REACT_APP_VERSION, ...errorProps });
  }

  public setPeople(props: Dict) {
    mixpanel.people.set(props);
  }

  public async setTestGroup(testName: string, testGroup: string) {
    mixpanel.register_once({
      [testName]: testGroup,
    });

    this.setPeople({
      [testName]: testGroup,
    });
  }

  public setFacebookUser() {
    mixpanel.register_once({
      isFromFacebook: true,
    });

    this.setPeople({
      isFromFacebook: true,
    });
  }
}
