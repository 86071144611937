import React from 'react';

import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAppDispatch } from '../app/hooks';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { syncMailbox } from '../features/user/userSlice';
import { getCurrentMailbox, getCurrentMailboxQuery } from '../model/mailbox';
import { GHOST_WHITE_HEX } from '../theme';
import { MailboxStatus } from '../services/mailboxSynchronizer/mailboxSynchronizer.types';
import { useTranslation } from 'react-i18next';
import { useLiveQuery } from 'dexie-react-hooks';

export type MailboxSyncIndicatorProps = {
  mode?: 'compact' | 'floating';
};

function MailboxSyncIndicator(props: MailboxSyncIndicatorProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const mailbox = useLiveQuery(getCurrentMailboxQuery);
  const mailboxSyncStatus = mailbox?.sync_status;

  if (mailboxSyncStatus === undefined || mailboxSyncStatus === MailboxStatus.SYNCED) {
    return <></>;
  }

  const isSyncing = mailboxSyncStatus === MailboxStatus.SYNCING;

  const onRetryClick = async () => {
    const mailbox = await getCurrentMailbox();

    if (!mailbox) {
      return;
    }
    if (await mailbox.isLoggedIn()) {
      // If logged in, start syncing
      dispatch(syncMailbox(mailbox));
    }
  };

  if (props.mode === 'compact') {
    const boxSx = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      margin: aboveSmall ? '1rem 0rem' : '0.5rem 0rem',
    };

    const textSx = {
      fontSize: aboveSmall ? '1rem' : '0.75rem',
      color: 'inherit',
      textDecoration: 'none',
      fontWeight: 400,
      textAlign: 'center',
      ...(isSyncing ? {} : { cursor: 'pointer' }),
    };

    if (isSyncing) {
      return (
        <Box sx={boxSx} maxWidth='xl'>
          <CircularProgress size='1rem' sx={{ marginRight: '0.5rem' }} />
          <Typography noWrap sx={textSx}>
            {t('scanningYourInbox')}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={boxSx} maxWidth='xl'>
          <ErrorOutlineIcon sx={{ marginRight: '0.5rem', fontSize: '1rem', color: 'red' }} />
          <Typography noWrap onClick={onRetryClick} sx={textSx}>
            {t('failedToScan')}
          </Typography>
        </Box>
      );
    }
  }

  const containerSx = {
    ...(props.mode === 'floating'
      ? {
          position: 'fixed',
          bottom: aboveSmall ? '1rem' : '0.5rem',
          background: GHOST_WHITE_HEX,
          padding: '0.5rem 1rem',
          borderRadius: '1rem',
          border: '1px solid black',
          boxShadow: '10px 10px 4px -3px rgba(0,0,0,0.17);',
          maxWidth: '90%',
          width: aboveSmall ? '50rem' : '90%',
          left: '50%',
          transform: 'translateX(-50%)',
        }
      : {
          margin: aboveSmall ? '2rem auto' : '1rem 0',
          display: 'flex',
          flexDirection: 'column',
        }),
  };
  const textSx = {
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'center',
  };

  if (isSyncing) {
    return (
      <Container maxWidth='xl' sx={containerSx}>
        <LinearProgress
          sx={{
            maxWidth: '20rem',
            margin: '0.5rem auto 1rem auto',
            width: '100%',
          }}
        />
        <Typography noWrap sx={textSx}>
          {t('scanningYourInbox')}
        </Typography>
      </Container>
    );
  } else {
    return (
      <Container maxWidth='xl' sx={containerSx}>
        <ErrorOutlineIcon sx={{ margin: '0rem auto 1rem auto' }} />
        <Typography noWrap sx={textSx} onClick={onRetryClick}>
          {t('failedToScan')}
        </Typography>
      </Container>
    );
  }
}

export default MailboxSyncIndicator;
