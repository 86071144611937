import React from 'react';
import { useTranslation } from 'react-i18next';
import { LandingPageFlavorEnum } from '../Welcome/welcomeFlavors.types';
import { useMediaQuery, Typography, useTheme } from '@mui/material';

export type WelcomeHeaderProps = {
  flavor: LandingPageFlavorEnum;
};

// Mapping of each flavor to its header and subheader translation keys
const landingPageHeaders: Record<
  LandingPageFlavorEnum,
  { headerTextKey: string; subHeaderTextKey: string }
> = {
  [LandingPageFlavorEnum.CLEAN_EMAIL]: {
    headerTextKey: 'CLEAN_EMAIL_HEADER',
    subHeaderTextKey: 'CLEAN_EMAIL_SUBHEADER',
  },
  [LandingPageFlavorEnum.STOP_SPAM]: {
    headerTextKey: 'STOP_SPAM_HEADER',
    subHeaderTextKey: 'STOP_SPAM_SUBHEADER',
  },
  [LandingPageFlavorEnum.UNSUBSCRIBE]: {
    headerTextKey: 'UNSUBSCRIBE_HEADER',
    subHeaderTextKey: 'UNSUBSCRIBE_SUBHEADER',
  },
  [LandingPageFlavorEnum.DELETE]: {
    headerTextKey: 'DELETE_HEADER',
    subHeaderTextKey: 'DELETE_SUBHEADER',
  },
  [LandingPageFlavorEnum.MARK_AS_READ]: {
    headerTextKey: 'MARK_AS_READ_HEADER',
    subHeaderTextKey: 'MARK_AS_READ_SUBHEADER',
  },
  [LandingPageFlavorEnum.GMAIL_CLEANER]: {
    headerTextKey: 'CLEAN_GMAIL_HEADER',
    subHeaderTextKey: 'CLEAN_GMAIL_SUBHEADER',
  },
  [LandingPageFlavorEnum.HOW_TO_UNSUBSCRIBE]: {
    headerTextKey: 'HOW_TO_UNSUBSCRIBE_HEADER',
    subHeaderTextKey: 'HOW_TO_UNSUBSCRIBE_SUBHEADER',
  },
  [LandingPageFlavorEnum.FREE_CLEANER]: {
    headerTextKey: 'FREE_CLEANER_HEADER',
    subHeaderTextKey: 'FREE_CLEANER_SUBHEADER',
  },
  [LandingPageFlavorEnum.BLOCK_SPAM]: {
    headerTextKey: 'BLOCK_HEADER',
    subHeaderTextKey: 'BLOCK_SUBHEADER',
  },
  [LandingPageFlavorEnum.HOW_TO_GMAIL_DELETE]: {
    headerTextKey: 'HOW_TO_GMAIL_DELETE_HEADER',
    subHeaderTextKey: 'HOW_TO_GMAIL_DELETE_SUBHEADER',
  },
  [LandingPageFlavorEnum.GMAIL_DELETE]: {
    headerTextKey: 'GMAIL_DELETE_HEADER',
    subHeaderTextKey: 'GMAIL_DELETE_SUBHEADER',
  },
  [LandingPageFlavorEnum.UNSUBSCRIBE_GMAIL]: {
    headerTextKey: 'UNSUBSCRIBE_GMAIL_HEADER',
    subHeaderTextKey: 'UNSUBSCRIBE_GMAIL_SUBHEADER',
  },
  [LandingPageFlavorEnum.UNSUBSCRIBE_MAILING_LIST]: {
    headerTextKey: 'UNSUBSCRIBE_MAILING_LIST_HEADER',
    subHeaderTextKey: 'UNSUBSCRIBE_MAILING_LIST_SUBHEADER',
  },
  [LandingPageFlavorEnum.UNSUBSCRIBE_APP]: {
    headerTextKey: 'UNSUBSCRIBE_APP_HEADER',
    subHeaderTextKey: 'UNSUBSCRIBE_APP_SUBHEADER',
  },
};

const getFontSize = (
  isExtraSmall: boolean,
  isMediumSmall: boolean,
  isSmall: boolean,
  isMedium: boolean
): string => {
  if (isExtraSmall) return '1.5rem';
  if (isMediumSmall) return '2rem'; // New size for medium small
  if (isSmall) return '2.25rem';
  if (isMedium) return '2.5rem';
  return '3.25rem';
};

const getSubHeaderFontSize = (
  isExtraSmall: boolean,
  isMediumSmall: boolean,
  isSmall: boolean,
  isMedium: boolean
): string => {
  if (isExtraSmall) return '0.85rem';
  if (isMediumSmall) return '0.95rem'; // New size for medium small
  if (isSmall) return '1rem';
  if (isMedium) return '1.25rem';
  return '1.5rem';
};

const getMargin = (
  isExtraSmall: boolean,
  isMediumSmall: boolean,
  isSmall: boolean,
  isMedium: boolean
): string => {
  if (isExtraSmall) return '1rem 1rem 1rem 1rem';
  if (isMediumSmall) return '1rem 1rem 1rem 1rem'; // New margin for medium small
  if (isSmall) return '1rem 1rem 1rem 1rem';
  if (isMedium) return '1rem 1rem 1rem 1rem';
  return '2rem 1rem 2rem 1rem';
};

const getMarginTop = (
  isExtraSmall: boolean,
  isMediumSmall: boolean,
  isSmall: boolean,
  isMedium: boolean
): string => {
  if (isExtraSmall) return '1rem';
  if (isMediumSmall) return '1.25rem'; // New margin for medium small
  if (isSmall) return '1.5rem';
  if (isMedium) return '1.75rem';
  return '2rem';
};

function WelcomeHeader(props: WelcomeHeaderProps) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMediumSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm')); // New breakpoint for medium small
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const { t } = useTranslation();

  const headerText = t(landingPageHeaders[props.flavor].headerTextKey);
  const subHeaderText = t(landingPageHeaders[props.flavor].subHeaderTextKey);

  return (
    <>
      <Typography
        variant='h1'
        component='h1'
        fontWeight={600}
        margin={getMargin(isExtraSmall, isMediumSmall, isSmall, isMedium)}
        marginTop={getMarginTop(isExtraSmall, isMediumSmall, isSmall, isMedium)}
        textAlign='center'
        fontSize={getFontSize(isExtraSmall, isMediumSmall, isSmall, isMedium)}
        {...(isLarge || isMedium || isSmall ? { maxWidth: 'none' } : { maxWidth: '40rem' })} // No maxWidth for screens above medium
      >
        {headerText}
      </Typography>
      {subHeaderText && (
        <Typography
          margin='0rem 1rem 1rem 1rem'
          textAlign='center'
          fontSize={getSubHeaderFontSize(isExtraSmall, isMediumSmall, isSmall, isMedium)}
          {...(isLarge || isMedium || isSmall ? { maxWidth: 'none' } : { maxWidth: '40rem' })} // No maxWidth for screens above medium
        >
          {subHeaderText}
        </Typography>
      )}
    </>
  );
}

export default WelcomeHeader;
