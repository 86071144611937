import { db } from '../../db/db';
import { UnsubscribeStatus } from '../../model/subscription.types';
import { BulkDeleteOperationCallbacks } from '../../services/bulkDeleteOperationQueue';
import { FilterOperationCallbacks } from '../../services/filterOperationQueue';

export type EmailListsAPIParams = {
  listSender: string;
  mailboxId: string;
  nonce: string;
};

export async function updateSubscriptionStatus(
  params: EmailListsAPIParams,
  subscriptionStatus: UnsubscribeStatus
) {
  const subscription = await db.subscriptions.get({
    mailbox_id: params.mailboxId,
    email_address: params.listSender,
  });
  if (!subscription) {
    throw new Error('Subscription not found!');
  }
  await subscription.updateSubscriptionStatus(subscriptionStatus, params.nonce);
}

export async function unsubscribeFromList(
  params: EmailListsAPIParams,
  callbacks: FilterOperationCallbacks
) {
  const subscription = await db.subscriptions.get({
    mailbox_id: params.mailboxId,
    email_address: params.listSender,
  });
  if (!subscription) {
    throw new Error('Subscription not found!');
  }
  await subscription.unsubscribe(callbacks);
}

export async function deleteListEmails(
  params: EmailListsAPIParams,
  callbacks: BulkDeleteOperationCallbacks
) {
  // await new Promise((r) => setTimeout(r, 3000));
  const subscription = await db.subscriptions.get({
    mailbox_id: params.mailboxId,
    email_address: params.listSender,
  });
  if (!subscription) {
    throw new Error('Subscription not found!');
  }
  return await subscription.delete(params.nonce, callbacks);
}
