export class MissingScopesError extends Error {
  missingScopes?: string[];

  constructor(params?: { message?: string; missingScopes?: string[] }) {
    super(params?.message ?? 'User did not provide all scopes');
    this.missingScopes = params?.missingScopes;
  }
}
export class WrongUserSelectedError extends Error {
  expectedUser?: string;

  constructor(params?: { message?: string; expectedUser?: string }) {
    super(params?.message);
    this.expectedUser = params?.expectedUser;
  }
}
