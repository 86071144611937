import React, { useEffect, useState } from 'react';

import { useMediaQuery, Stack, Typography, useTheme, Box, Button } from '@mui/material';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import './EscapeWebView.scss';
import {
  DeviceOS,
  getCornerAsset,
  MoreOptionsCorner,
  MoreOptionsIcon,
} from '../../../../services/webViewDetector/webViewDetector.types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IosShareIcon from '@mui/icons-material/IosShare';
import { WebViewLayoutConfig } from '../../../../services/webViewDetector/webViewLayoutConfig';
import { useTranslation } from 'react-i18next';

export type EscapeWebViewProps = {
  layoutConfig: WebViewLayoutConfig;
  deviceOS: DeviceOS;
};

function EscapeWebView(props: EscapeWebViewProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    AnalyticsService.track(EventName.SHOW_ESCAPE_WEBVIEW_PAGE);
  }, []);

  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  const browserData = props.layoutConfig;
  const layoutData =
    props.deviceOS === 'android' ? browserData.androidLayout : browserData.iosLayout;

  const isArrowDown =
    layoutData.moreOptionsCorner === MoreOptionsCorner.BOTTOM_RIGHT ||
    layoutData.moreOptionsCorner === MoreOptionsCorner.BOTTOM_LEFT ||
    layoutData.moreOptionsCorner === MoreOptionsCorner.BOTTOM_RIGHT_PADDED;

  const isArrowRight =
    layoutData.moreOptionsCorner === MoreOptionsCorner.BOTTOM_RIGHT ||
    layoutData.moreOptionsCorner === MoreOptionsCorner.TOP_RIGHT ||
    layoutData.moreOptionsCorner === MoreOptionsCorner.BOTTOM_RIGHT_PADDED;

  const titleFontSize = isExtraSmall ? '1.25rem' : aboveSmall ? '2rem' : '1.75rem';
  const contentFontSize = isExtraSmall ? '1rem' : aboveSmall ? '2rem' : '1.25rem';
  const secondaryFontSize = isExtraSmall ? '0.85rem' : aboveSmall ? '1.5rem' : '1rem';
  const secondaryCaptionFontSize = isExtraSmall ? '0.65rem' : aboveSmall ? '1rem' : '0.75rem';

  return (
    <>
      <Box
        margin={2}
        component='img'
        src={getCornerAsset(layoutData.moreOptionsCorner)}
        sx={{
          position: 'absolute',
          height: isArrowDown ? 'calc(60vh - 15px)' : '33vh',
          margin: 0,
          ...(isArrowDown ? { bottom: '10px' } : { top: '10px' }),
          ...(isArrowRight ? { right: '20px' } : { left: '20px' }),
        }}
      />
      <Stack flexGrow={1}>
        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
          className={isArrowDown ? 'one-quarter-height' : 'one-third-height'}
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {browserData.icon ? (
            <Box
              component='img'
              src={browserData.icon}
              sx={{
                height: aboveSmall ? '50px' : '35px',
                margin: '0 auto',
              }}
            />
          ) : (
            <></>
          )}
          <Typography
            variant='h4'
            component='h4'
            fontWeight={600}
            margin='1rem 2rem 0rem 2rem'
            fontSize={titleFontSize}
            maxWidth='18rem'
          >
            {t('stillInXApp', { appName: browserData.name })}
          </Typography>
        </Box>
        <Stack
          sx={{
            flexGrow: 1,
            ...(isArrowDown ? { justifyContent: 'end' } : {}),
          }}
          className={isArrowDown ? 'fifteen-height' : 'one-third-height'}
        >
          <Box
            sx={{ margin: '0 2rem', marginTop: '1rem', display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              sx={{
                fontSize: contentFontSize,
                marginRight: '0.5rem',
              }}
            >
              {t('toUsePress')}
            </Typography>
            {layoutData.moreOptionsIcon === MoreOptionsIcon.SHARE ? (
              <IosShareIcon sx={{ alignSelf: 'center' }} />
            ) : layoutData.moreOptionsIcon === MoreOptionsIcon.DOTS_H ? (
              <MoreHorizIcon sx={{ alignSelf: 'center' }} />
            ) : (
              <MoreVertIcon sx={{ alignSelf: 'center' }} />
            )}
          </Box>
          <Typography
            sx={{
              fontSize: contentFontSize,
              margin: '0 2rem',
            }}
          >
            {t('andChoose', { actionName: layoutData.buttonText })}
          </Typography>
        </Stack>
        <Stack flexGrow={1} className={isArrowDown ? 'big-height' : 'one-third-height'}>
          <Typography
            sx={{
              fontSize: secondaryFontSize,
              margin: '0 2rem 0.5rem 2rem',
            }}
          >
            or
          </Typography>{' '}
          <Stack
            sx={{
              width: 'fit-content',
              margin: '0 auto',
              background: 'rgba(256, 256, 256, 0.4)',
              padding: ' 1rem 0rem',
              borderRadius: '0.5rem',
            }}
          >
            <Typography
              sx={{
                fontSize: secondaryFontSize,
                margin: '0 1rem',
              }}
            >
              Copy this link into your browser
            </Typography>
            <Typography
              sx={{
                fontSize: secondaryCaptionFontSize,
                margin: '0.5rem 2rem',
              }}
            >
              (like Chrome or Safari)
            </Typography>
            <Button
              variant='contained'
              size={aboveSmall ? 'large' : 'small'}
              color='googleBlue'
              sx={{
                margin: '0 auto',
                marginTop: isExtraSmall ? '0.5rem' : '1rem',
              }}
              startIcon={copied ? <CheckIcon /> : <ContentCopyIcon />}
              onClick={async () => {
                const tempInput = document.createElement('input');
                const currentUrl = window.location.href;
                tempInput.value = currentUrl;
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand('copy');
                document.body.removeChild(tempInput);

                setCopied(true);
              }}
            >
              {copied ? 'Copied' : 'Copy link'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
export default EscapeWebView;
