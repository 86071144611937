import { useEffect, useState } from 'react';
import TrimboxServerClient, { AuthorizedUsersWithEmails, License } from '../services/serverClient';
import Mailbox from '../model/mailbox';

export function useGetLicenses(mailbox: Mailbox | undefined | null) {
  const [licenses, setLicenses] = useState<License[]>();

  useEffect(() => {
    const fetchLicenses = async () => {
      if (!mailbox) {
        return;
      }

      const fetchedLicenses =
        (await new TrimboxServerClient().listLicenses(
          mailbox.user_id,
          await mailbox.getCredential$()
        )) || [];

      setLicenses(fetchedLicenses);
    };

    fetchLicenses();
  }, [mailbox]);

  return {
    licenses,
  };
}

export function useGetActiveLicenseInfo(mailbox: Mailbox | undefined | null) {
  const [licenseInfo, setLicenseInfo] = useState<AuthorizedUsersWithEmails>();

  useEffect(() => {
    const fetchActiveLicenseInfo = async () => {
      if (!mailbox) {
        return;
      }

      const activeLicenseInfo =
        (await new TrimboxServerClient().getActiveLicenseInfo(
          mailbox.user_id,
          await mailbox.getCredential$()
        )) || [];

      setLicenseInfo(activeLicenseInfo);
    };

    fetchActiveLicenseInfo();
  }, [mailbox]);
  return {
    licenseInfo,
  };
}
