import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Avatar, useMediaQuery } from '@mui/material';
import banner_image from '../../assets/banner_image.svg';
import theme, { PRIMARY_COLOR_HEX } from '../../theme';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

export type TopBarProps = {
  userEmail?: string | undefined;
  hideSignIn?: boolean;
};

export default function TopBar(props: TopBarProps) {
  const { userEmail, hideSignIn } = props;

  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const navigate = useNavigate();

  return (
    <AppBar
      elevation={0}
      position='sticky'
      color='white'
      sx={{
        padding: '0.5rem 0rem',
      }}
    >
      <Container maxWidth={'lg'}>
        <Toolbar disableGutters sx={{ ...(isExtraSmall ? { minHeight: '48px' } : {}) }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <Box
              component='img'
              src={banner_image}
              sx={{
                height: aboveSmall ? 36 : isExtraSmall ? 24 : 28,
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/');
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          ></Box>
          <Box sx={{ flexGrow: 0 }}>
            {userEmail ? (
              <Avatar
                alt={userEmail}
                sx={{
                  backgroundColor: PRIMARY_COLOR_HEX,
                  width: aboveSmall ? 44 : 32,
                  height: aboveSmall ? 44 : 32,
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '4px 4px 4px 0px rgba(0,0,0,0.16)',
                  },
                }}
              >
                {userEmail?.[0]?.toUpperCase()}
              </Avatar>
            ) : !hideSignIn ? (
              <Button
                color={'primary'}
                size={aboveSmall ? 'large' : 'medium'}
                href='/'
                variant='contained'
                sx={{ fontSize: aboveSmall ? '1.2rem' : '1rem' }}
              >
                {t('signUpForFree')}
              </Button>
            ) : null}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
