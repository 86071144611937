import retry from 'retry';
import AnalyticsService from '../services/analytics/analyticsService';
import { EventName } from '../services/analytics/providers/analyticsProvider';

export default class Credential {
  public accessToken?: string;
  public refreshToken: string;
  public openIDToken: string;
  public expiresAt: number;
  public scopes: string[];

  constructor(credential: {
    accessToken: string;
    refreshToken: string;
    openIDToken: string;
    expiresInSeconds: number;
    scopes: string[];
  }) {
    this.accessToken = credential.accessToken;
    this.refreshToken = credential.refreshToken;
    this.openIDToken = credential.openIDToken;
    this.expiresAt = _createExpirationTime(credential.expiresInSeconds);
    this.scopes = credential.scopes;
  }
}

export async function refresh(credential: Credential): Promise<boolean> {
  AnalyticsService.track(EventName.REFRESH_CREDENTIAL);

  return new Promise((resolve, reject) => {
    const operation = retry.operation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    operation.attempt(async (currentAttempt) => {
      try {
        resolve(await _refresh(credential));
      } catch (err) {
        if (operation.retry(err as Error)) {
          return;
        }

        reject(operation.mainError());
      }
    });
  });
}

async function _refresh(credential: Credential): Promise<boolean> {
  if (!credential.refreshToken) {
    return false;
  }

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

  const request = new Request('https://oauth2.googleapis.com/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `refresh_token=${credential.refreshToken}&client_id=${clientId}&client_secret=${clientSecret}&grant_type=refresh_token`,
  });
  const response = await fetch(request);
  const payload = await response.json();
  if (response.ok) {
    _updateCredential(credential, payload);
    return true;
  } else if (response.status === 400) {
    return false;
  } else {
    throw new Error(`Encountered error while attempting to perform auth: ${response.status}`);
  }
}

function _createExpirationTime(expiresInSeconds: number) {
  const currentTimeUTC = new Date().getTime();
  return new Date(currentTimeUTC + 1000 * expiresInSeconds).getTime();
}

function _updateCredential(
  credential: Credential,
  params: {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    id_token: string;
    scopes: string;
  }
) {
  credential.accessToken = params.access_token;
  credential.openIDToken = params.id_token;
  credential.expiresAt = _createExpirationTime(params.expires_in);
}

export function isExpired(credential: Credential): boolean {
  if (!credential.accessToken) {
    return true;
  }

  const expired = credential.expiresAt <= Date.now();
  return expired;
}
