import React, { useEffect } from 'react';

import './App.css';
import { Stack, useMediaQuery } from '@mui/material';
import theme, { GHOST_WHITE_HEX } from './theme';
import { useAppSelector } from './app/hooks';
import {
  selectKeepSettingsDialog,
  selectTab,
  selectUnsubscribeSettingsDialog,
} from './features/ui/uiSlice';
import ResponsiveAppBar from './components/AppBar/ResponsiveAppBar';
import { UnsubscribeTab } from './features/emailLists/UnsubscribeTab';
import { useLiveQuery } from 'dexie-react-hooks';
import { DeleteTab } from './features/emailBundles/DeleteTab';
import ProgressPopup from './components/ProgressPopup';
import PaywallPopup from './components/PaywallPopup';
import Mailbox, { getCurrentMailboxQuery } from './model/mailbox';
import PermissionsInfoPopup from './components/PermissionsInfoPopup';
import UnsubscribeSettingsPopup from './components/SettingsPopup/UnsubscribeSettingsPopup';
import KeepSettingsPopup from './components/SettingsPopup/KeepSettingsPopup';
import ChurnPopup from './components/ChurnPopup/ChurnPopup';
import StudentPromoPopup from './components/StudentPromoPopup';
import PlansPopup from './components/PlansPopup/PlansPopup';
import { useMailboxSync } from './app/useMailboxSync';
import TabHeader from './components/AppBar/TabHeader';
import { useGetMailboxData } from './app/useGetMailboxData';
import { useHasGivenFeedback } from './app/useGetChurnFeedback';
import { TRIMBOX_REDIRECT_KEY } from './constants';
import RequestLicensePopup from './components/RequestLicensePopup/RequestLicensePopup';
import AlreadyPaidPopup from './components/AlreadyPaidPopup';

function MainComponent() {
  const mailbox: Mailbox | undefined | null = useLiveQuery(getCurrentMailboxQuery);
  const { mailboxId, isMailboxQueryPending, hasTokenAndFullScopes, hasAccessToken, hasMailbox } =
    useGetMailboxData(mailbox);

  useMailboxSync(mailbox, hasTokenAndFullScopes, hasAccessToken);

  const hasGivenFeedback = useHasGivenFeedback(mailbox);

  const selectedTab = useAppSelector(selectTab);

  const unsubscribeSettingsDialog = useAppSelector(selectUnsubscribeSettingsDialog);
  const keepSettingsDialog = useAppSelector(selectKeepSettingsDialog);

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const pricingRedirectKey = localStorage.getItem(TRIMBOX_REDIRECT_KEY);
    if (pricingRedirectKey) {
      localStorage.removeItem(TRIMBOX_REDIRECT_KEY);
      window.location.href = pricingRedirectKey;
    }
  }, []);

  if (isMailboxQueryPending) {
    return null;
  }

  return (
    <>
      <div
        className='App'
        style={{
          backgroundColor: GHOST_WHITE_HEX,
        }}
      >
        <Stack sx={{ display: 'flex' }}>
          <ResponsiveAppBar></ResponsiveAppBar>
          <Stack sx={!aboveMedium ? {} : { maxWidth: '80%', width: '1000px', margin: 'auto' }}>
            <Stack
              sx={{
                margin: aboveSmall ? '0rem 2rem' : '0rem 1rem',
                textAlign: 'end',
              }}
            >
              <TabHeader></TabHeader>
              {selectedTab === 'lists' && <UnsubscribeTab mailboxId={mailboxId!} />}
              {selectedTab === 'bundles' && <DeleteTab mailboxId={mailboxId!} />}
            </Stack>
          </Stack>
        </Stack>
        {/* <MailboxSyncIndicator mode={'floating'} /> */}
      </div>
      {hasMailbox ? (
        <>
          <StudentPromoPopup></StudentPromoPopup>
          <ProgressPopup></ProgressPopup>
          <PaywallPopup></PaywallPopup>
          <PlansPopup mailbox={mailbox} hasGivenFeedback={!!hasGivenFeedback}></PlansPopup>
          <ChurnPopup></ChurnPopup>
          <RequestLicensePopup></RequestLicensePopup>
          <AlreadyPaidPopup></AlreadyPaidPopup>
          <PermissionsInfoPopup></PermissionsInfoPopup>
          {unsubscribeSettingsDialog ? (
            <UnsubscribeSettingsPopup></UnsubscribeSettingsPopup>
          ) : (
            <></>
          )}
          {keepSettingsDialog ? <KeepSettingsPopup></KeepSettingsPopup> : <></>}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default MainComponent;
