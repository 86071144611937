import { CurrencyConfig } from './offeringSlice.types';

export function toMinorUnitAmount(amount: number, currency: CurrencyConfig): number {
  return Math.ceil(amount * 10 ** currency.minorUnitPrecision);
}

export function roundMajorUnitAmount(amount: number, currency: CurrencyConfig): string {
  return amount.toFixed(currency.minorUnitPrecision);
}

export function formatPrice(amount: number, currency: CurrencyConfig): string {
  return currency.symbol + roundMajorUnitAmount(amount, currency);
}
